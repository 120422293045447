function gigpassAPI(){
	const gFn = this;
	let eticket = {pass:{}}; // there can be only one pass per eticket here
	let evt_style = {'background':'url(/cmn/img/band.jpg)', 'background-repeat':'no-repeat', 'background-size':'cover'};

	function updateIMG(){
		let evt = eticket.evt;
		evt_style.background = 'url('+ imgMGRapi.useimg(evt.img, {width:300, height:100}, '/cmn/img/band.jpg') +')';
		$('#qrbox').css(evt_style);
	}
	
	this.init = function(){
		let url = fwAPP.api.get('url');
		let opt = {};
		if (isValid(url, 'param.id')) opt.id = url.param.id;
		else return navigateURL('/'); // return to gigzantic.com
					
//			$('#gigpass_hdr').html(fwAPP.gigpass.html.hdr());
//			$('#gigpass_ftr').html(fwAPP.gigpass.html.ftr());
		
		fwAPP.gigpass.pass(opt, function(obj){
			let html = fwAPP.gigpass.query(obj).passHTML();
			$('#gigpass').html(html);
			fwAPP.gigpass.setQRCODE();
		});
  };
	
  this.query = function(data){
		eticket = data;
		eticket.pass = data.evt.pas[0]; // each eticket has ONLY one pass
		let evt = eticket.evt;
		fwAPP.api.set('var_page', {advfree:evt.advfree, myadv:evt.myadv});
		return gFn;
  };
	
  this.error = function(opt, result){
		fwLogger.error(opt, result);
		
		let html = fwHTML.elem({tag:'b'},'GIGPASS / '+ 'ERROR:'+ result.text);
		html = fwHTML.elem({tag:'div', attr:{class:'row'}}, html);

		let cols = [];
		cols.push(fwHTML.elem({tag:'b'},'GIGPASS / '+ 'ERROR:'+ result.text));
		cols.push(fwHTML.elem({tag:'img',attr:{style:'width:75px;height:25px;',src:'/cmn/img/comodo_ssl_w840_h281.png'}}));
		html = fwHTML.row(cols, myclass);
		cols = [];
		
		cols.push(fwHTML.elem({tag:'a', attr:{class:'link-offset-3', onclick:"navigateURL('/')"}}, 'https://gigzantic.com'));
		cols.push(fwHTML.elem({tag:'code'}, opt.id));
		html += fwHTML.row(cols, myclass);
		$('#gigpass').html(html);
		return gFn;
  };
	
	this.pass = function(opt, callback){
		fwAPP.api.srvREQ({fn:'GIGPASSget', cmd:'QRCODE', opt:opt, callback:function(data){
			if (typeof callback === 'function') callback(data);
		}, err_callback:function(result){
			fwAPP.gigpass.error(opt, result);
		}});
	};
	
	this.passHTML = function(){
		html = fwHTML.elem({tag:'div', attr:{id:'hdrmsg', class:'w-100'}}, gFn.html.pass_box());
		html += gFn.html.qrbox(); 
 		html = fwHTML.elem({tag:'div', attr:{id:'hdrmsgwrap', class:'navbar sticky-top bg-white m-0 p-0 w-100'}}, html);
 		html += gFn.html.content();
		return html;
	};

	this.setQRCODE = function(id){
		let img = '/api/phpqrcode/temp/'+ eticket.qrcode +'.png';
		if (!isEmpty(id)){
			img += '_'+id;
			$('#qrbox').addClass('bg-success');
			$('#qrbox').css({background:''});
		} else {
			$('#qrbox').removeClass('bg-success');
			$('#qrbox').css(evt_style);
		}
		$('#qrcode').attr('src',img);
		updateIMG();
	};
  
	function acceptITM(itmid){
		let html = '1';
		let itm = eticket.pass.itm;
		
		fwLogger.log(itmid, itm);
		
		$.each(itm,function(i,v){
			if (itmid == v.id) html += fwHTML.elem({tag:'span', attr:{class:'badge text-bg-dark-gz m-2'}}, v.type_name);
		});
		html = fwHTML.elem({tag:'span', attr:{class:'btn text-bg-primary rounded-1'}}, html +'successfully redeemed. Enjoy!');
		html = fwHTML.icon({name:'handshake', text:'dark-gz' }) + html;
		
		let footer = '<button type="button" class="btn btn-primary" data-dismiss="modal">Ok</button>';
		
		fwAPP.api.myDLG({title:'Redeem', html:html, footer:footer, callback:function(){
			navigateURL();
		}});
	}
	
	this.redeemITM = function(id){
		let duration = 15;
		
		function pad(w,s,p){return (w <= String(s).length) ? s : pad(w,p+String(s),p);}
		
		let redeemCODE = '';
		function poll(){
			/* jshint -W018 */			
			let bg = (!(duration%2)) ? 'btn-success' : 'btn-outline-success';
			/* jshint +W018 */

			function pollWRITE(code){
				if (!isEmpty(code)) redeemCODE = code; 
				let html = isEmpty(redeemCODE) ? fwHTML.icon({name:'barcode'}) +' ME' : 'CODE: '+ redeemCODE;
				html = fwHTML.elem({tag:'span',attr:{class:'badge text-bg-dark'}},pad(2,duration,'&nbsp;')) + ' SCAN ' + html;
				
				html = fwHTML.elem({tag:'button',attr:{id:'countdown',type:'button',class:'btn '+ bg +' btn-sm disabled my-0 py-3 w-100'}},html);
				$('#hdrmsg').html(html);
			}
			pollWRITE();
			
			let opt = isEmpty(id) ? {validate:true} : {itmid:id};
			opt.id = fwAPP.api.get('param_id');
			let cmd = isEmpty(id) ? 'PAS_VLD' : 'ITM_RDM';
			
			setTimeout(function(){
				let msgonce = true;
				/* jshint -W018 */
				if (!(duration%3)){ // poll every 3 seconds
				/* jshint +W018 */
					fwAPP.api.srvREQ({fn:'GIGPASSset', cmd:cmd, opt:opt, callback:function(data){
						if (isValid(data, 'code') && !isEmpty(data.code)){
							switch(data.code){
								case 'CODEACK':
									duration = 1;
									pollWRITE('ACCEPTING');
									acceptITM(id);
									setTimeout(function(){ navigateURL(); }, 10000);
									return;
							}
							pollWRITE(data.code);
						}
					}, err_callback:function(result){
						duration = 1;
						if (result.text == 'REJECTED_QTY'){
							setTimeout(function(){ navigateURL(); }, 3000);
						}
						fwHTML.msg('danger','ITMRDM', result.text, 30);
					}});
				}
				
				if (--duration > 0) return poll();
				
				// cleanup
				redeemCODE = '';
				html = fwHTML.elem({tag:'div',attr:{id:'hdrmsg',class:'w-100'}}, gFn.html.pass_box());
				$('#hdrmsg').html(html);
				$('.card-body .btn').removeClass('btn-success').removeClass('disabled').addClass('btn-outline-primary').html(useBtn(id));
				gFn.setQRCODE();
				$('.nav-link').removeClass('disabled');
			}, 1000);
		}
		
		$('.nav-link').addClass('disabled');
		gFn.setQRCODE(id);

		html = fwHTML.elem({tag:'span',attr:{role:'status','aria-hidden':'true',class:'spinner-grow spinner-grow-sm'}}) +' scan QR code NOW!';
		
		$('.card-body .btn').removeClass('btn-outline-primary').addClass('btn-success').addClass('disabled').html(html);
		
		poll();
  };
	
	this.selectITM = function(i){
		if (!isValid(eticket.pass,'itm') || isEmpty(eticket.pass.itm)) return fwLogger.warn('no items found');
		let target = $('#card-info');
		target.html(eticket.pass.itm[i].html);
		$('.nav-link').removeClass('active');
		$('#nav-'+ i).addClass('active');
  };
	
	function myclass(k, v){
		return (k%2) ? 'col text-truncate text-end' : 'col text-truncate text-start';
	}
	
	function quickTAG(){
		let html = eticket.pass.qckid;
		html = html.slice(0, 2) + "-" + html.slice(2);
		return fwHTML.elem({tag:'span ', attr:{class:'badge text-bg-dark-gz'}}, html);
	}
	
	function useBtn(v, tordm){
		let validated = !isEmpty(eticket.validated);
		let html = fwHTML.icon({name:'handshake', text:'dark-gz' }) + 'validate&nbsp;' + quickTAG();
		if (!validated) return fwHTML.elem({tag:'a',attr:{onclick:'navigateURL()',class:'btn btn-lg btn-primary text-uppercase fw-bold w-100'}}, html);
		if (!isEmpty(tordm) && tordm>0) return fwHTML.elem({tag:'a',attr:{onclick:'fwAPP.gigpass.redeemITM('+ v.id +')',class:'btn btn-lg btn-outline-primary text-uppercase fw-bold w-100'}}, 'redeem');
		return '';
	}
	
	this.html = {
/*
		hdr:function(){
 			html = fwHTML.elem({tag:'div',attr:{class:'col-2'}}, fwHTML.elem({tag:'img',attr:{style:'width:120px;float:left;',src:'/cmn/img/comodo_ssl_w840_h281.png'}}));
			html += fwHTML.elem({tag:'div',attr:{class:'col'}}, fwHTML.elem({tag:'div',attr:{id:'hdrmsg',class:'pt-1'}}) );
 			return fwHTML.elem({tag:'div',attr:{class:'row'}}, html);
		},
		ftr:function(){
			let elem = {tag:'a',attr:{href:'javascript:;',class:'link-dark'}};
 			let html = fwHTML.elem({tag:'li',attr:{class:'ms-3'}},fwHTML.elem(elem,fwHTML.elem({tag:'i',attr:{class:'fa-brands fa-x-twitter'}})));
 			html += fwHTML.elem({tag:'li',attr:{class:'ms-3'}},fwHTML.elem(elem,fwHTML.elem({tag:'i',attr:{class:'fa-brands fa-instagram'}})));
 			html += fwHTML.elem({tag:'li',attr:{class:'ms-3'}},fwHTML.elem(elem,fwHTML.elem({tag:'i',attr:{class:'fa-brands fa-facebook'}})));
			
			elem = {tag:'ul',attr:{class:'nav col-md-4 justify-content-end list-unstyled d-flex'}};
 			html = fwHTML.elem(elem,html);
			
 			let html_brand = fwHTML.elem({tag:'img',attr:{class:'border border-2 border-success align-content',style:'width:20px;float:left;',src:'/cmn/img/logo.jpg'}});
			
			elem = {tag:'a',attr:{href:'/',class:'mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1'}};
 			html_brand += fwHTML.elem(elem,fwHTML.elem({tag:'span',attr:{class:'mb-3 mb-md-0 text-muted fs-5 fw-light'}},'&nbsp;<i>&copy;&nbsp;2019-2023&nbsp;gigzantic.com</i>'));
			
			elem = {tag:'div',attr:{class:'col-md-4 d-flex align-items-center'}};
 			html_brand = fwHTML.elem(elem,html_brand);
			
			elem = {tag:'footer',newline:1,attr:{class:'d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top'}};
			return fwHTML.elem(elem,html_brand + html);
		},
*/
		item:function(opt){
			if (isEmpty(opt)) opt = {};
			if (!isValid(opt,'itmid')) fwLogger.error(opt);
			if (!isValid(opt,'type')) opt.type = 'TYPE MISSING';
			if (!isValid(opt,'name')) opt.name = 'NAME MISSING';
			if (!isValid(opt,'text')) opt.text = 'TEXT MISSING';
			if (!isValid(opt,'qty')) opt.qty = 0;
			if (!isValid(opt,'tqty')) opt.tqty = 0;
			
			let elem = {tag:'span',attr:{class:'badge text-bg-secondary'}};
 			let html = fwHTML.elem({tag:'small'},fwHTML.elem(elem,opt.qty) + ' / '+ fwHTML.elem(elem,opt.tqty));
			
			elem = {tag:'h5',attr:{class:'mb-1'}};
 			html = fwHTML.elem(elem,opt.type) + html;
			
			elem = {tag:'div',attr:{class:'d-flex w-100 justify-content-between'}};
 			html = fwHTML.elem(elem,html);
			
			elem = {tag:'p',attr:{class:'mb-1'}};
 			html += fwHTML.elem(elem,opt.name);
			
 			html += fwHTML.elem({tag:'small'},opt.text);
			
			const active = isValid(opt,'active') ? 'list-group-item list-group-item-action' : 'list-group-item list-group-item-action active';
//			elem = {tag:'a',newline:1,attr:{itmid:opt.itmid,href:'javascript:;',active}};
			elem = {tag:'a', attr:{newline:1, itmid:opt.itmid, class:active}};
			return fwHTML.elem(elem,html);
		},
		pass_box:function(){
			let pass = eticket.pass;
			let secured = fwHTML.elem({tag:'img', attr:{style:'width:75px; height:25px;', src:'/cmn/img/comodo_ssl_w840_h281.png'}});
			
			let cols = fwHTML.elem({tag:'div', attr:{class:'col-auto ps-0 fw-bold'}}, 'GIGPASS / '+ this.status());
			cols += fwHTML.elem({tag:'div', attr:{class:'col pe-0 text-end'}}, secured);
			
			html = fwHTML.elem({tag:'div', attr:{class:'row'}}, cols);
			
			cols = fwHTML.elem({tag:'code', attr:{class:'fw-bold text-dark text-wrap fs-6 ms-2 '}}, eticket.qrcode);
			cols = fwHTML.elem({tag:'div', attr:{class:'col-auto px-0'}}, quickTAG() + cols);
			html += fwHTML.elem({tag:'div', attr:{class:'row mb-3'}}, cols);
			return html;
		},
		status:function(){
			let evt = eticket.evt; 		
			let evtdt = fwAPP.api.dtDUR2sdtANDedt(evt.start_dt,eticket.evt.duration);
			let status = fwHTML.elem({tag:'i',attr:{class:'text-success'}},'VALID');
			if (!isEmpty(eticket.validated)) status = fwHTML.elem({tag:'i',attr:{class:'text-primary'}},'VALIDATED');
			if (evtdt.s.elapsed) status = fwHTML.elem({tag:'i',attr:{class:'text-danger'}},'EXPIRED');
			return status;
		},
		content:function(){
			let evt = eticket.evt; 		
			let usr = eticket.usr; 		
			let hld = eticket.hld;
			let pass = eticket.pass;
			let evtdt = fwAPP.api.dtDUR2sdtANDedt(evt.start_dt,eticket.evt.duration);
			
			let cols = fwHTML.elem({tag:'th', attr:{scope:'row', class:'table-light text-end'}}, 'Event:');
			cols += fwHTML.elem({tag:'td', attr:{class:'fw-semibold fst-italic text-wrap ps-2'}}, evt.name);
			let row = fwHTML.elem({tag:'tr', attr:{class:'border-bottom'}}, cols);
			
			cols = fwHTML.elem({tag:'th', attr:{scope:'row', class:'table-light text-end'}}, 'Address:');
			cols += fwHTML.elem({tag:'td', attr:{class:'fst-italic ps-2'}}, fwAPP.html.place(evt.place));
			row += fwHTML.elem({tag:'tr', attr:{class:'border-bottom'}}, cols);
			
			cols = fwHTML.elem({tag:'th', attr:{scope:'row', class:'table-light text-end'}}, 'Start:');
			cols += fwHTML.elem({tag:'td', attr:{class:'ps-2'}}, evtdt.s.str);
			row += fwHTML.elem({tag:'tr'}, cols);
			
			cols = fwHTML.elem({tag:'th', attr:{scope:'row', class:'table-light text-end'}}, 'End:');
			cols += fwHTML.elem({tag:'td', attr:{class:'ps-2'}}, evtdt.e.str);
			row += fwHTML.elem({tag:'tr', attr:{class:'border-bottom'}}, cols);
			
			cols = fwHTML.elem({tag:'th', attr:{scope:'row', class:'table-light text-end'}}, 'Type:');
			cols += fwHTML.elem({tag:'td', attr:{class:'fw-semibold ps-2'}}, pass.type_name);
			row += fwHTML.elem({tag:'tr'}, cols);
			
			cols = fwHTML.elem({tag:'th', attr:{scope:'row', class:'table-light text-end'}}, 'Value:');
			cols += fwHTML.elem({tag:'td', attr:{class:'ps-2'}}, currency(pass.amt).format());
			row += fwHTML.elem({tag:'tr'}, cols);
			
			if (!isEmpty(evt.description)){
				cols = fwHTML.elem({tag:'th', attr:{scope:'row', class:'table-light text-end'}}, 'Description:');
				cols += fwHTML.elem({tag:'td', attr:{class:'ps-2 pre-wrap'}}, evt.description);
				row += fwHTML.elem({tag:'tr', attr:{class:'border-bottom'}}, cols);
			}			
			
			cols = fwHTML.elem({tag:'th', attr:{scope:'row', class:'table-light text-end'}}, 'Holder:');
			cols += fwHTML.elem({tag:'td', attr:{class:'fst-italic ps-2'}}, hld.name +'&nbsp;&lt;'+ hld.email +'&gt;');
			row += fwHTML.elem({tag:'tr', attr:{class:'border-bottom'}}, cols);
			
			if (usr.email != hld.email){
				cols = fwHTML.elem({tag:'th', attr:{scope:'row', class:'table-light text-end'}}, 'Purchased By:');
				cols += fwHTML.elem({tag:'td', attr:{class:'fst-italic ps-2'}}, hld.name +'&nbsp;&lt;'+ usr.email +'&gt;');
				row += fwHTML.elem({tag:'tr', attr:{class:'border-bottom'}}, cols);
			}			
			
			row = fwHTML.elem({tag:'table',attr:{class:'table table-sm align-middle table-borderless'}}, row);
			return fwHTML.elem({tag:'div',attr:{class:''}}, row) + fwAPP.html.act(eticket.act);
		},
		qrbox:function(){
//	body {background:url(cmn/img/background.jpg);	background-repeat:no-repeat; background-size:cover;}
			let html = fwHTML.elem({tag:'img',attr:{id:'qrcode',class:'m-0 p-0',style:'width:150px;height:150px;',src:''}});
			return fwHTML.elem({tag:'div', attr:{id:'qrbox', class:'text-center py-3 w-100'}}, html) + gFn.html.pass();
		},
		pass:function(){
			let pass = eticket.pass;

			if (!isValid(pass,'itm') || isEmpty(pass.itm)){
				let evt = eticket.evt; 		
				fwLogger.warn('no items found');
				return useBtn(evt.id);
			}
			
			let li = '';
			$.each(pass.itm, function(i, v){
				let myclass = (i>0) ? 'nav-link m-0 p-0 py-1' : 'nav-link m-0 p-0 py-1 active'; // initial selection
				let a = fwHTML.elem({tag:'a', attr:{id:'nav-'+ i, onclick:'fwAPP.gigpass.selectITM('+ i +')',class:myclass}},v.name);
				li += fwHTML.elem({tag:'li', attr:{class:'nav-item'}},a);
				
				let tordm = (v.qty-v.rdmqty);
				if (tordm<0) tordm=0;  

				v.html = fwHTML.elem({tag:'b',attr:{class:'card-title text-truncate'}},v.type_name +' portion is valued at '+ currency(v.qty).multiply(v.amt).format());
				v.html += fwHTML.elem({tag:'p',attr:{class:'card-text'}},'<b>'+ tordm +' / '+ v.qty +' </b>items can be redeemed');
				v.html += useBtn(v, tordm);
				v.html = fwHTML.elem({tag:'div',attr:{class:'card-body bg-white'}},v.html);
			});
			let ul = fwHTML.elem({tag:'ul',attr:{class:'nav nav-pills nav-justified'}},li);
			
			html = fwHTML.elem({tag:'div',attr:{id:'card-info'}},pass.itm[0].html);

			html = fwHTML.elem({tag:'div',attr:{class:'text-start text-capitalize m-0 p-0 w-100'}},ul + html);
	//		html = fwHTML.elem({tag:'div',attr:{class:'text-start text-capitalize'}},html);
			return html;
		}
	};
	
	return gFn;
}