function storage(type){
	const gFn = this;
	let mystorage = sessionStorage;
	
	function assign(){
		switch (String(type).toUpperCase()){
			case 'LOCAL': mystorage = localStorage; break;
			case 'SESSION': break;  // already initialized
			default:
				fwLogger.warn('storage:invalid storage type('+ type +') using SESSION instead');
				break;
		}
//		fwLogger.warn('storage:assign', type);
	}
	
	function verify(key){
		if (isEmpty(key)){
			fwLogger.error('storage:key is empty');
			return false;
		}
		return true;
	}
	
	this.get = function(key){
		if (!verify(key))	return {};
		let v = mystorage.getItem(key);
		try {
			v = JSON.parse(v);
		} catch (e) {
			fwLogger.error('parce error:cleaning up',v);
			mystorage.clear();
			return {};
		}
		return v;
	};

	this.set = function(key, value){
		if (!verify(key)) return {};
		mystorage.setItem(key, JSON.stringify(value));
	};
	
	this.clear = function(key){
		let result = isEmpty(key) ? mystorage.clear() : gFn.set(key, {});
	};
	
	assign();
}

function myselectize(name,opt){
	const gFn = this;
	let myopt = {};
	let mydata = [];

	function fmt_data(data){
		if (isEmpty(data)) return (mydata = []);
		
		mydata = (typeof data === 'object') ? Object.values(decodeFLDval(data)) : decodeFLDval(data);
		
		if (myopt.valueField == 'uniqid'){
			$.each(mydata,function(k, v){
				mydata[k].uniqid = k;
			});
		}
		return mydata;
	}
	
	this.destroy = function(){
		gFn.api().clearOptions();
		gFn.api().destroy();
		return {};
	};
	
	this.populate = function(data, fld){
		$.each(data,function(k, v){
			gFn.api().addOption(v);
			if (!isEmpty(fld)) gFn.api().addItem(v[fld]);
		});
	};
	
	this.setdata = function(data){
		if (isEmpty(data)){
			mydata = [];
			gFn.api().clearOptions();
		} else {
			fmt_data(data);
		}
		return mydata;
	};
	
	this.data = function(callback){
		if (isEmpty(mydata)) return mydata;
		if (typeof callback !== 'function' && callback) return mydata;

		let selected = gFn.api().getValue();
		if (!Array.isArray(selected)) selected = Object.values(selected);
		let result = [];
		$.each(mydata,function(k, v){
			if (typeof callback === 'function' && callback(k, v)){
				result.push(v);
			} else {
				$.each(selected, function(i, s){
					if (v[myopt.valueField] == s) result.push(v);
				});
			}
		});
		return result;
	};
	
	function init(n, o){
		myopt.valueField = isEmpty(o.valueField) ? 'uniqid' : o.valueField;
		if (isValid(o,'options')) o.options = fmt_data(o.options); 
		return $('#'+ n).selectize(o);
	}
	
	const h = init(name,opt);
	
	this.api = function(){
		return h[0].selectize;
	};
	
	return this;
}

function fwAPI(){
	const gFn = this;
	let apivars = {state:'init', session:{phpsessid:document.cookie.replace(/phpsessid=/gi, '')},
		mode:new storage('LOCAL').get('mode'),				
		consent:{}, trgrevt:{}, trgrtmr:{}, url:{}, var_page:{}, sysval:{}, cart:{}, cache:{}, viewsize:{}, cache_page:[]};

	this.figimode = function(){
		return (apivars.mode == 'figi');
	};
	
	this.evtOP = function(v){
		if (isEmpty(v)) return {};
		let opt = {evtdt:fwAPP.api.dtDUR2sdtANDedt(v.start_dt, v.duration)};
		opt.open = (v.opstat == 'OPEN');
		opt.ended = (v.opstat == 'ENDED' || v.opstat == 'EXPIRED');
		
		opt.init = (!opt.ended && v.status == 'INITIALIZE');
		opt.active = (!opt.ended && v.status == 'ACTIVE');
		opt.removed = (v.status == 'REMOVED');
		opt.onsale = (!opt.ended && v.status == 'ONSALE');
		opt.pause = (!opt.ended && v.status == 'PAUSED');
		return opt;
	};
	
	this.codeType = function(i, str){
		if (isEmpty(str)) str = 'A';
		return String.fromCharCode(str.charCodeAt(0) + Number(i-1));
	};
	
	this.headerMETAtags = function(taglst){
		$.each(taglst, function(k, v){
			if (isEmpty(v.attr)) v.attr = 'name';
			let tag = $('meta['+ v.attr +'="'+ v.name +'"]');
			if (tag.length == 0){
				let attr = {content:v.content};
				attr[v.attr] = v.name;
				$('head').prepend(fwHTML.elem({tag:'meta', attr:attr}));
			} else {
				tag.attr('content', v.content);
			}
		});
	};
	
	this.pin = function(cmd, k, v){
		if (isEmpty(k)) new storage('LOCAL').clear('pin');
		let pin = new storage('LOCAL').get('pin');
		if (isEmpty(pin)) pin = {};
		if (cmd == 'set' && !isEmpty(k)){
			pin[k] = v;
			new storage('LOCAL').set('pin', pin);
		}
		return isEmpty(k) ? {} : pin[k];
	};

	this.onresult = function(obj, callback, err_callback){
		if (!isValid(obj, 'result.status')){
			fwLogger.error('MAJOR', obj);
			return false;
		}
		
		if (obj.result.status == 'SUCCESS'){
			let data = isEmpty(obj.data) ? {} : decodeFLDval(obj.data);
			if (typeof callback === 'function') callback(data, obj.result);
			return true;
		}
		// there is ERROR
		switch(obj.result.text){
			case 'NOT_AUTHORIZED':
				fwHTML.msg('success','Sign-in','We are currently open only to pre-selected users; public use is coming soon, please try us next month. Thank you for your interest.',15);
				return fwAPP.api.loadACTION('support');
		}
		fwLogger.error(obj.result);
		if (typeof err_callback === 'function') err_callback(obj.result);
		return false;
	};
	
	function singularity(callback){
		let	url = fwAPP.api.get('url');
		let page = '', frame = '', running = {};
		signedin = fwAPP.auth.SIGNEDIN();
		
		function useDEFAULTframe(){
			if (!signedin) return false;
			let usr = fwAPP.api.get('usr');
			return isEmpty(usr.name);
		}
		
		function getDEFAULTframe(){
			let out = 'home';
			if (signedin){
				out = useDEFAULTframe() ? 'mysettings' : 'usrdash';
			}
			return out;
		}

		const myTimeout = setTimeout(function(){
			loadERROR();
		}, 10000);

		function validateFRAME(){
			switch(frame){
				// all allowed 
				case 'cart':
				case 'checkout':
				case 'cmn_legal':
				case 'support':
				case 'eventinfo':
				case 'pass':
				case 'sale':
				case 'signauth':
					if (!useDEFAULTframe()) return;
					break;
					
				// signed in 
				case 'act':
				case 'venue':
				case 'event':
				case 'evalidate':
				case 'followup':
				case 'gigpassad':
				case 'gigpassads':
				case 'myacts':
				case 'myevents':
				case 'mypasses':
				case 'mylists':
				case 'mysettings':
				case 'myvenues':
				case 'sendmail':
				case 'usrdash':
				case 'sadash':
					if (signedin && !useDEFAULTframe()) return;
					break;
					
				// NOT signed-in 
				case 'home':
				case 'signin':
					if (!signedin && !useDEFAULTframe()) return;
					break;
			}
			
			frame = getDEFAULTframe();			
			gFn.updateURL('/?f='+ frame);
		}
		
		function resolveURL(){
			switch(url.appdir){
				default: url.appdir = ''; break;
				case 'gigpass': page = '/gigpass'; break;
			}
			
			let param = url.param;
			if (isValid(param,'a')){ // process auth related data
				frame = 'signauth';
				if (fwAPP.auth.SIGNEDIN()) return fwAPP.auth.SIGNOUT('/?a='+ param.a);
			} else if (isValid(param,'p')){ // process pass related data
				frame = 'redirect';
				opt = {lnktok:param.p};
				if (isValid(param, 'id')) opt.etktok = param.id;
				fwAPP.api.srvREQ({fn:'GIGPASSset', cmd:'LNK_VLD', opt:opt, callback:function(data){
					let result = isValid(param, 'id') ? navigateURL('/gigpass/?id='+ param.id) : navigateURL('/');
				}, err_callback:function(result){
					fwHTML.msg('danger', 'Validate Link', result.text +', please contact our support if you believe this to be an error.', 15);
				}});
			} else if (isValid(param, 'id')){ // process pass related data
				frame = 'pass';
			} else if (isValid(param, 'e')){ // process eventinfo or frame related data
				frame = isValid(param, 'f') ? param.f : 'eventinfo';
			} else if (isValid(param, 'f')){ // process frame related data
				frame = param.f;
			}
			
			validateFRAME();	
			fwLogger.log('resolveURL', frame, url);
		}
		
		function loadHTMLframe(){
			fwLogger.log('loadHTMLframe', page, frame);
			
			loadPAGEset('frame', frame);
			reqUPDATE('frame');
			loadPAGE({cache:'static'}, function(){
				if ($('#mymodal').length<=0 && typeof fwHTML !== 'undefined'){
					$(document.body).append(fwHTML.mymodal()); // create modal frame
					fwAPP.mymodal = new bootstrap.Modal('#mymodal',{backdrop:true, focus:true, keyboard:true}); // instance for use
				}
				reqUPDATE('frame');
			});
		}
		
		function loadDATA(){
			fwLogger.log('loadDATA:'+ rtdt.sdiff());
			
			let location = gFn.myLOCATION();
			let opt = {lat:location.lat, lng:location.lng};
			
			function cacheTICKETMASTER(callback){
				gFn.srvREQ({fn:'GIGPASSset', cmd:'EVT_TMR', opt:opt, callback:function(data){
					gFn.cacheREQ('GIGPASSget', 'EVT_TMR', opt, function(evttmr){
						if (typeof callback === 'function') return callback(evttmr);
					});
				}, err_callback:function(result){
				}});
			}
			
			let gigpass = (!isEmpty(page) && String(page).indexOf('gigpass')>=0);
			if (!isEmpty(page) && !gigpass) return;
			if (!gigpass && fwAPP.auth.SIGNEDIN()) return;
			if (gigpass && !isEmpty(apivars.var_page) && apivars.var_page.advfree) return; // no ads
			
			if (gFn.figimode()){
				reqUPDATE('evtsle');
				gFn.cacheREQ('GIGPASSget', 'EVT_SLE', opt, function(evtsle){
					reqUPDATE('evtsle');
				});
				
				reqUPDATE('advlst');
				gFn.cacheREQ('GIGPASSget', 'ADV_LST', opt, function(advlst){
					reqUPDATE('advlst');
				});
			}
			
			reqUPDATE('evttmr');
			cacheTICKETMASTER(function(evttmr){
				reqUPDATE('evttmr');
			});
		}
		
		function reqUPDATE(name, state){
			if (isEmpty(state)) state = isValid(running, name) ? 'SUCCESS' : 'INIT';
			running[name] = state;
			
			let dataISready = true;
			$.each(running, function(k, v){
				if (v != 'SUCCESS') return (dataISready=false);
			});
			if (dataISready) loadHTMLdata();
		}
		
		function loadHTMLdata(){
			clearTimeout(myTimeout);
			if (typeof callback === 'function') callback();
			
			$(window).trigger('loadACTIONdone', [signedin, url.appdir, 'frame', frame]);
		}
		
		function loadERROR(){
			fwLogger.error('loadERROR', running);
			loadHTMLdata();
		}
	
		resolveURL();
		loadHTMLframe();
		loadDATA();
	}

	function setViewSize(){
		let size = $('#viewsizer').find('div:visible').data('size');
		if (size != apivars.viewsize.size){
			let value = $('#viewsizer').find('div:visible').data('value');
			apivars.viewsize = {size:size, value:value};
		}
		return apivars.viewsize;
	}
	
	this.viewVisible = function(size){
//		return $('#viewsizer').find('div:visible').data('size');
		let viewsize = gFn.get('viewsize');
		if (isEmpty(viewsize)) viewsize = setViewSize();
		if (isEmpty(size) || isEmpty(viewsize)){
			fwLogger.error('viewVisible:size', size, viewsize);
			return false;
		}

		let value = 0;
		$('#viewsizer').find('div').each(function(k, v){
			if (size == $(v).data('size')) value = $(v).data('value');
		});
		return (value <= viewsize.value);
	};
	
	const backdrop = {
		pgbackdrop:$("#pgbackdrop"),
		pgshowcase:$("#pgshowcase"),
		pgbackdroplabel:$("#pgbackdroplabel"),
		pgbackdropbtn:$("#pgbackdropbtn"),
		
/*
		const offcanvasElementList = document.querySelectorAll('.offcanvas')
		const offcanvasList = [...offcanvasElementList].map(offcanvasEl => new bootstrap.Offcanvas(offcanvasEl));		
		const pgshowcase = bootstrap.Offcanvas.getOrCreateInstance('#pgshowcase');
		fwLogger.log('showEVTTMR', offcanvasList, pgshowcase, evt);
		pgshowcase.toggle();
		return;
		$("#pgshowcase").hide('fast', function(){
			$(this).show('slow', 'swing', function(){
				fwLogger.log('showEVTTMR', evt);
			});
		});
*/		
		init:function(){
			backdrop.pgshowcase.html(fwHTML.spinner());
			return this;
		},
		
		title:function(html){
			backdrop.pgbackdroplabel.html(fwHTML.title(html));
			return this;
		},
		
		visible:function(){
			return (backdrop.pgbackdrop.hasClass('show') || backdrop.pgshowcase.hasClass('show'));
		},
		
		show:function(html){
			if (!backdrop.visible()) backdrop.pgbackdropbtn.click();
			return this;
		},
		
		hide:function(html){
			fwLogger.log('hide', backdrop.pgshowcase.hasClass('show'));
			if (backdrop.visible()) backdrop.pgbackdropbtn.click();
			return this;
		},
		
		write:function(html){
			backdrop.pgshowcase.html(html);
			return this;
		}
	};
	
	this.support = function(){
//		fwAPP.api.loadACTION('support');
		backdrop.init().title('Support').show();
		gFn.getPAGE('support', backdrop.pgshowcase, function(o){
			let usr = fwAPP.signin.usr();
			if (!isEmpty(usr.email)) setFLDval('contact_email', usr.email); 
			autosize($('textarea'));
		});
	};
	
	this.showEVTTMR = function(tmrid){
		backdrop.init().title('ticketmaster.com').show();
/*
		let frame = fwAPP.api.get('frame');
		if (frame == 'usrdash') return gFn.openURL(evt.url);
		else if (frame != 'home') return fwAPP.api.loadACTION('home', {}, {'evttmr':evt});
*/		
		let evt = {};
		
		let evttmr = fwAPP.api.get('cache').evttmr;
		if (!isEmpty(evttmr)){
			$.each(evttmr, function(k, v){
				if (tmrid == v.id) evt = v;
			});
		}
		
		if (!isEmpty(evt)){
			evt.external = true;
			let html = fwAPP.html.evttmr('evttmr4_3', evt);
			html = fwHTML.elem({tag:'div', attr:{class:'d-flex justify-content-center'}}, html); 
			return backdrop.write(html);
		}
		
		fwAPP.api.srvREQ({fn:'GIGPASSget', cmd:'EVT_TMR', opt:{id:tmrid}, callback:function(data){
			if (!isEmpty(data)){
				// evttmr3_2 evttmr4_3 evttmr16_9
				data[0].external = true;
				let html = fwAPP.html.evttmr('evttmr3_2', data[0]);
				backdrop.write(html);
			}			
		}, err_callback:function(result){
		}});
	};
	
	this.showEVENTinfo = function(page){
		let usr = fwAPP.api.get('usr');
		$('#page_nav').html(fwAPP.html.pagenav(page));

		let encoded = fwAPP.api.get('encoded');
		if (isEmpty(encoded)) fwLogger.error('showEVENTinfo:encoded value is missing');
		
		let evt = fwAPP.api.get('evtlst',{encoded:encoded});
		if (isEmpty(evt)) evt = fwAPP.api.get('slelst',{encoded:encoded});

		fwLogger.log('showEVENTinfo', encoded, evt);
		
		if (isEmpty(evt)){
			fwAPP.api.srvREQ({fn:'GIGPASSget', cmd:'EVT_LST', opt:{encoded:encoded}, callback:function(data){
				if (isEmpty(data)) return fwLogger.error('eventinfo', data);
				evt = data[0];
				if (encoded != evt.encoded) return fwLogger.error('eventinfo', encoded, evt);
				$('#page_root').html(fwAPP.html.evtinfo(evt));
			}, err_callback:function(result){
			}});
			return;
		}
		
		$('#page_root').html(fwAPP.html.evtinfo(evt));
	};
	
	let pgMSGcurr = 0;
	this.pgshowcaseMSG = function(tmrcnt){
		const msg = ['Gigs & Events',
		'Get best deals to new local events',
		'Find and attend local events you like',
		'Event management and marketing made simple',
		'Create event, customize ePasses, invite and track your guest participation',
		'Support local business, promote your product or service'];
		let next = pgMSGcurr;
		do {
			next = Math.floor(Math.random() * msg.length);
		} while (pgMSGcurr == next);
		pgMSGcurr = next;
		
		$("#pgshowcase_msg").fadeOut(150, function(){
		  $(this).text(msg[pgMSGcurr] +'.').fadeIn(500);
		});
	};
	
	function processACTION(event, appdir, frame, page){
		fwLogger.warn('processACTION', appdir, frame, page);
		
		switch(page){
			case 'eventinfo':
				gFn.showEVENTinfo(page);
				break;
				
			case 'signauth':
				fwAPP.signin.validatereset();
				break;
				
			case 'cart':
			case 'checkout': break; // menuUPDATE:cart.resync
			case 'sale': fwAPP.cart.postsale(); break;
				
			case 'home': triggerSET('trgrtmr', 'pgshowcase', 'fwAPP.api.pgshowcaseMSG'); 
			/* falls through */
			default:
				let flds = fwAPP.api.get('var_page');
				if (isValid(flds, 'evttmr.id')) gFn.showEVTTMR(flds.evttmr.id);
				fwAPP.api.payload('page_root');
				break;
		}
		$('#page_nav').html(fwAPP.html.pagenav(page));
	}
	
	this.processACTIONusr = function(){};
	
	triggerGET = function(name, id){
		return (isValid(apivars[name],id)) ? apivars[name][id] : '';
	};
	
	triggerSET = function(name, id, callback){
		if (name == 'trgrtmr') tmrcnt = 0; // reset counter on new timer trigger
		if (isEmpty(id) || typeof callback !== 'string') return fwLogger.error('triggerSET:invalid use of callback');
		return (apivars[name][id] = callback);
	};
	
	triggerRMV = function(name, id){
		delete apivars[name][id];
	};
	
	let tmrcnt = 0;
	setInterval(function (){
		$.each(apivars.trgrtmr, function(id, callback){
			if (isValid(apivars.trgrtmr, id)){
				let fn_callback = callback + '('+ tmrcnt +')';
				/* jshint evil: true */
				eval('('+ fn_callback +')');
			}
		});
		tmrcnt++;
	}, 10000);
	
	function locationUPDATE(reload){
		let location = gFn.myLOCATION();
		fwLogger.warn('locationUPDATE', location);
		
		let myclass = location.user ? 'btn-text-bg-dark-gz' : 'text-bg-secondary';
		let html = fwHTML.icon({name:'location-dot', pos:'center'});
		html += fwHTML.elem({tag:'div', attr:{class:'d-none d-sm-block ms-1'}}, fmtStr.truncate(location.city, 10) +' - '+ location.zip);
		$('#my_location').html( fwHTML.elem({tag:'span', attr:{class:'badge d-flex align-items-center rounded-pill fw-light '+ myclass}}, html));

		if (reload){
			setTimeout(function(){ // need time to close previous modal
				let footer = fwHTML.elem({tag:'button',attr:{'type':'button','class':'btn btn-secondary','data-dismiss':'modal'}},'X');
				fwAPP.api.myMODAL(fwHTML.title(fwHTML.spinner()), fwHTML.subtitle('Loading data from our partners...'), footer, function(){
					fwAPP.api.cacheCLEAR('evtsle');
					fwAPP.api.cacheCLEAR('evttmr');
					singularity(function(){
						fwAPP.mymodal.hide();
					});
				}, function(event){
					fwAPP.mymodal.hide();
				});
			}, 1000);
		}
	}
	
	this.menuUPDATEsignedin = function(){}; // will be overriden by signedin function to update menu.
	
	function menuUPDATE(signedin){
		fwLogger.warn('menuUPDATE');
		if (!gFn.modeUI()) return;
		
		if (signedin){
			fwAPP.cart.status();
			return gFn.menuUPDATEsignedin();
		}
		
		let usr = fwAPP.signin.usr();
		let actions = [];
		actions.push({name:'Sign-up / new user', icon:'users', text:'dark', onclick:"fwAPP.api.loadACTION('signin','new')"});

		let html = fwHTML.elem({tag:'div', attr:{class:'text-bg-dark p-2'}}, 'SIGN-IN');
		html += fwHTML.elem({tag:'label', attr:{class:'fw-bold fst-italic'}}, 'email');
		
		let attr = {id:'signin_menu_email', type:'email', class:'form-control'};
		if (!isEmpty(usr)) attr.value = usr.email;
		html += fwHTML.elem({tag:'input', attr:attr});
		html = fwHTML.elem({tag:'div', attr:{class:'card-text form-group text-start'}}, html);

		html += fwHTML.elem({tag:'label', attr:{class:'fw-bold fst-italic'}}, 'password');
		html += fwHTML.elem({tag:'input', attr:{id:'signin_menu_pwd', type:'password', class:'form-control'}});
		html = fwHTML.elem({tag:'div', attr:{class:'form-group text-start'}}, html);
		
		attr = {id:'signin_menu_cb', type:'checkbox', class:'form-check-input'};
		if (!isEmpty(usr)) attr.checked = 'checked';
		html += fwHTML.elem({tag:'div', attr:{class:'form-check py-2'}}, fwHTML.elem({tag:'label'}, fwHTML.elem({tag:'input', attr:attr}) + ' Remember Me'));
		html += fwHTML.elem({tag:'a',attr:{class:'btn btn-text-bg-dark-gz', onclick:"fwAPP.signin.exec('signin_menu');"}}, 'Sign-in');
		html = fwHTML.elem({tag:'div', attr:{class:'p-3', style:'width:280px;'}}, html);
		
		html += fwHTML.dropdown_menu_li(actions);

		$('#usr_menu').find('ul').attr('class', 'dropdown-menu dropdown-menu-end rounded-1 small text-start').html(html);
		$('#cart_action').hide();
		fwAPP.html.hdrMENU('home');
		gFn.menuUPDATEsignedin = function(){};
		fwAPP.cart.status();
	}
	
	this.modeUI = function(url){
		if (isEmpty(url)) url = fwAPP.api.get('url');
		switch(url.appdir){
			case '': case '/': return true;
		}
		return false;
	};

	let windowcssPREV = {}; 
	
	function windowUPDATE(update){
		if (gigpass) return;
		
		update = (!isEmpty(update) && update);
		let bodyH = $("body").height();
		let navH = $("header").height() +10;
//		let ftrH = $("footer").height();
		let ftrH = 0;
		let frameH = $("#fwapi-frame").height();
		let calcH = bodyH-(navH+ftrH);
		let windowcss = {'margin-top':navH +'px', 'max-height':calcH +'px'}; 
		/* jshint -W069 */
		let result = (frameH < calcH) ? windowcss['overflow'] = 'hidden' : windowcss['overflow-y'] = 'auto';
		/* jshint +W069 */
		
		if (!update) update = (JSON.stringify(windowcssPREV) != JSON.stringify(windowcss));
//		$("#body-content").css({paddingTop: navH+10 +'px'});
		if (update){
			fwLogger.warn('windowUPDATE', update, bodyH, frameH, navH, ftrH);
			$("#body-content").css(windowcss).scrollTop(0);
			windowcssPREV = windowcss;
			autosize.update($('textarea'));
		}
	}
	
	this.windowRESIZE = function(update){
		update = (!isEmpty(update) && update);
		fwLogger.warn('windowRESIZE', update);
		windowUPDATE(update);
		let visible_prev = false;
		if (setViewSize()){
			let visible = gFn.viewVisible('lg');
			if (visible_prev != visible){ // throttle, will fire on first and subsequent changes
				visible_prev = visible;
				fwLogger.log('viewsizer:changed', apivars.viewsize, visible);
				if (visible) gFn.payload('sidepanel');
			}
		}
		
//		$('#fwapi-footer').css({position:'fixed', bottom:'0', left:'0', width:'100%'});
	};
	
	function initCONSENT(){
		let footer = fwHTML.elem({tag:'button', attr:{'type':'button', 'class':'btn btn-primary', 'data-dismiss':'modal'}}, fwHTML.icon('square-check') +'Accept Cookie Policy');
		footer += fwHTML.elem({tag:'button',attr:{'type':'button','class':'btn btn-danger','data-dismiss':'modal'}},'X');
		let html = 'We process your personal information to measure and improve our sites and service, to assist our marketing campaigns and to provide personalised content and advertising.';
		if (isEmpty(apivars.consent)){
			fwAPP.api.myDLG({html:html, footer:footer, callback:function(){
				apivars.consent = fwAPP.auth.CONSENT({cookie:true});
			}});
		}
	}
	
	let gigpass = false;
	function initCONTEXT(callback){
		fwLogger.info('initCONTEXT:'+ rtdt.sdiff());
	
		locationUPDATE();
		apivars.consent = fwAPP.auth.CONSENT();
		
		if (typeof callback === 'function') callback();
		
		$(window).resize(throttle(gFn.windowRESIZE, 1000));	// set and throttle page resize
		

		$(window).click(function(e){
			let event_id = gFn.eventIDis(e);
			if (isEmpty(event_id)) return;

			let triggered = false; // triggerSET callback fwapi_param
			$.each(apivars.trgrevt, function(id, callback){
				if (event_id == id && isValid(apivars.trgrevt, id)){
					triggered = true;
					let param = $('#'+ id).attr('fwapi_param');
					let fn_callback = callback + (isEmpty(param) ? '()' : '("'+ param +'")');
					fwLogger.info('click', event_id, fn_callback);
					/* jshint evil: true */
					eval('('+ fn_callback +')');
				}
			});
			if (!triggered){
				setTimeout(function(){ windowUPDATE(); }, 250);
				fwLogger.warn('click', event_id);
			}
		});
		
		$(window).off('loadACTIONdone').on('loadACTIONdone', function(event, signedin, appdir, frame, page){
			gigpass = (!isEmpty(appdir) && appdir.toLowerCase() == 'gigpass');
			setTimeout(function(){ gFn.windowRESIZE(false); }, 100);  // update window
			setTimeout(function(){ gFn.windowRESIZE(false); }, 1000); // safety
			fwLogger.info('loadACTIONdone:'+ rtdt.sdiff(), gigpass, appdir, frame, page);

			if (frame != 'init' && frame != 'frame') return;
			if (apivars.state == 'init'){
				apivars.state = 'run';
				updateMODE();
				if (gigpass) return;
				$('#hdr_menu_home').attr('fwapi_param', 'home');
				triggerSET('trgrevt', 'hdr_menu_home', 'fwAPP.api.loadACTION');
				triggerSET('trgrevt', 'my_location', 'fwAPP.api.setLOCATION');
				triggerSET('trgrevt', 'contact_submit', 'fwAPP.api.submitCONTACT');
				triggerSET('trgrevt', 'gbl_search', 'fwAPP.api.search');
			}
			
			if (signedin){ // do something if signed in 
				if (isEmpty(fwAPP.usr)) fwAPP.usr = new usrAPI();
			}
			menuUPDATE(signedin);
			
			if (page != 'evalidate' && !isEmpty(html5QrcodeScanner)){ // cleanup and reset scanner
				fwLogger.error('html5QrcodeScanner', html5QrcodeScanner.getState(), html5QrcodeScanner);
				html5QrcodeScanner.clear();
//				delete html5QrcodeScanner;
				html5QrcodeScanner = null;
			}
			
			let result = signedin ? gFn.processACTIONusr(event, appdir, frame, page)
				: processACTION(event, appdir, frame, page);
			
			fwAPP.api.popoverENABLE();
			fwAPP.api.tooltipENABLE();
			
			$('.flipcard').flip({axis:'y', speed:300, autoSize:true});
			autosize.update($('textarea'));
//			$(window).resize();

			$("#page_root").click(); // trigger windows resize
			initCONSENT();
		});
		
		if (!gFn.modeUI() || gigpass) return;
		
		$('#usr_menu').off('show.bs.dropdown').on('show.bs.dropdown', function(event){
			let frame = fwAPP.api.get('frame');
			$('#usr_menu > .dropdown-item').removeClass('disabled');
			$('#m-'+ frame).addClass('disabled');
		});
	}
	
	function enableALLfeatures(){
		let location = gFn.myLOCATION();
		let enable = (location.zip == '60089');
		enable = true;
		
		if (!enable) fwLogger.warn('enableALLfeatures:'+ enable, location);
		return enable;
	}
	
	this.init = function(callback){
		if (apivars.state !== 'init') return fwLogger.warn(apivars.state +'!= init');
		let pageload = fwAPP.api.get('pageload');
		let elapsed_sec = (Date.now() - pageload.dt)/1000;
		if (pageload.throttle && (elapsed_sec>120)) pageload.throttle = false; // clear if stopped reloading for a while
		if (++pageload.cnt>5) pageload = {throttle:(elapsed_sec<60), cnt:1, dt:Date.now()};
		fwLogger.log('init:'+ rtdt.sdiff(), apivars, pageload);

		new storage('LOCAL').set('pageload', pageload);
			
		setURL();
		if (typeof callback === 'function') callback();

		getSYSVAL(function(data){
			setTimeout(function(){
				if (pageload.throttle) fwLogger.warn('throttle logic activated'); 
				singularity(initCONTEXT);
			}, pageload.throttle?1500:0);

		});
	};
	
	this.clone = function(o){
		return isEmpty(o) ? {} : JSON.parse(JSON.stringify(o));
	};
	
	this.addset = function(name, opt){
		if (isEmpty(opt)) return {};
		let var_page = fwAPP.api.get(name);
		$.each(opt, function(k, v){
			var_page[k] = v;
		});
		return fwAPP.api.set(name, var_page);
	};
	
	this.get = function(k, opt){
		if (isEmpty(opt)) opt = {};
		
		function cmnrec(lst, opt){
			let result = {};
			if (isEmpty(lst)) return result;
			if (isEmpty(opt)) return lst;
			$.each(lst, function(k, v){
				if (!isEmpty(opt.id) && opt.id == v.id){
					result = isEmpty(opt.idx) ? v : k;
					return false;
				}
			});
			return result;
		}
		
		function pasrec(passes, opt){
			fwLogger.log('evtrec', passes, opt);
			let result = {};
			if (isEmpty(passes)) return result;
			$.each(passes, function(i, etk){
				if (!isEmpty(opt.etkid) && opt.etkid == etk.id){
					result = etk;
					return false;
				}
			});
			return result;
		}
		
		function evtrec(events, opt){
			let result = {};
			if (isEmpty(events)) return result;
			if (isEmpty(opt)) return events;
			if (isEmpty(opt.obj)) opt.obj = 'evt';
			
			$.each(events, function(i, evt){
				if (!isEmpty(opt.evtid) && opt.evtid == evt.id){
					result = isEmpty(opt.idx) ? evt : i;
					return false;
				}	else if (!isEmpty(opt.encoded) && opt.encoded == evt.encoded){
					result = isEmpty(opt.idx) ? evt : i;
					return false;
				}
				if (!isEmpty(evt.pass)){
					$.each(evt.pass, function(j, pas){
						if (!isEmpty(opt.pasid) && opt.pasid == pas.id){
							result = (opt.obj == 'pas') ? pas : evt;
							return false;
						}
						if (!isEmpty(pas.item)){
							$.each(pas.item, function(k, itm){
								if (!isEmpty(opt.itmid) && opt.itmid == itm.id){
									result = evt;
									if (opt.obj == 'pas') result = pas;
									if (opt.obj == 'itm') result = itm;
									return false;
								}
							});
						}
					});
				}
			});
			return result;
		}

		let url = apivars.url;
		switch(k){
			case 'usr':	return fwAPP.auth.USR();
			case 'usrapp':
				let usr = fwAPP.auth.USR();
				return (!isEmpty(usr.app) && isValid(usr.app,'gigzantic.data')) ? usr.app.gigzantic.data : {};
			
			case 'url':	case 'var_page': case 'cache': case 'cart': case 'sysval': case 'viewsize': case 'state': case 'lstcur': return apivars[k];
			
			case 'param_id': return (isValid(url, 'param.i') ? url.param.i : (isValid(url, 'param.id') ? url.param.id : 0));
			case 'encoded':	return (isValid(url, 'param.e') ? url.param.e : '');
			case 'frame':	return (isValid(url, 'param.f') ? url.param.f : '');
				
			case 'evttmr': return apivars.cache[k];
			
			case 'advlst': return cmnrec(apivars.cache[k], opt);
			case 'fwsearch': return cmnrec(apivars.cache[k], opt);
			case 'lstusr': return cmnrec(apivars.cache[k], opt);
			case 'evtlst': return evtrec(apivars.cache[k], opt);
			case 'evtsle': return evtrec(apivars.cache[k], opt);
			case 'paslst': return isEmpty(opt) ? apivars.cache.paslst : pasrec(apivars.cache.paslst, opt);
			case 'vpaslst': return pasrec(apivars.vpaslst, opt);
			
			case 'pageload':
				let pageload = new storage('LOCAL').get('pageload');
				return isEmpty(pageload) ? {cnt:0, dt:Date.now(), throttle:false} : pageload;
		}
		
		fwLogger.warn('fwAPI.get:', k);
		return '';
	};
	
	this.set = function(k, v){
		if (isEmpty(v)) v = {};
		switch(k){
			case 'url': case 'var_page': case 'sysval': case 'cart': case 'lstcur': case 'vpaslst': case 'state':
				apivars[k] = v;
				return v;
				
			case 'fwsearch':
				apivars.cache[k] = v;
				return v;
		}
		fwLogger.error('fwAPI.set:', k, v);
		return '';
	};
	
	this.loadACTIONclean = function(page, data){
		if (isEmpty(data)) data = {};
		switch(page){
			case 'cart':
				fwAPP.api.set('cart', data);
				break;
				
			case 'mylists':
				fwAPP.api.cacheCLEAR('lstusr');
				break;
			
			case 'sale':
			case 'myacts':
			case 'myvenues':
			case 'myevents':
				fwAPP.api.cacheCLEAR('evtlst');
				fwAPP.api.cacheCLEAR('paslst');
				fwAPP.api.cacheCLEAR('evtsle');
				break;
		}
		fwAPP.api.cacheCLEAR('fwsearch');
		fwAPP.api.loadACTION(page, {force:true});
	};
	
	this.cacheCLEAR = function(name){
		let cache = fwAPP.api.get('cache');
		delete cache[name];
		fwAPP.api.set('cache', cache);
	};
	
	this.cacheREQ = function(fn, cmd, opt, callback){
		let cache = fwAPP.api.get('cache');
		
		let offset = String(cmd).replace('_','').toLowerCase();
		if (isValid(cache, offset)){
			if (typeof callback === 'function') return callback(cache[offset]); // cache should be cleared on any SET call
		}
		
		if (isEmpty(opt)) opt = {};
		fwAPP.api.srvREQ({fn:fn, cmd:cmd, opt:opt, info:'cacheREQ', callback:function(data){
			cache[offset] = data;
			fwAPP.api.set('cache', cache);
			if (typeof callback === 'function') callback(cache[offset]);
		}, err_callback:function(result){
		}});
	};
	
	this.updateURL = function(opt){
		let url = fwAPP.api.get('url');
		// window.history.pushState(null, document.title, "/gigpass/?id=kajsdhfasdf");
		// window.history.replaceState(null, document.title, "/gigpass/?id=kajsdhfasdf");
		if (!isEmpty(opt)){ // make changes
			if (typeof opt === 'string'){
				let newURL = (opt.charAt(0)=='/') ? opt : url.pathname + opt;
				fwLogger.warn('updateURL', opt, newURL, url);
				window.history.pushState(null, document.title, newURL);
				setURL();
			}
		}
	};

	function setURL(){
		let url = {pathname:window.location.pathname, appdir:'', page:'', rootpath:'/', apipath:'/api/', windowname:window.name};
		
		// https://stackoverflow.com/questions/23306882/javascript-close-current-window
		if (isEmpty(url.windowname)) url.windowname = window.name = 'GIGZANTIC'; // attempting to use for tab manipulation *** experimental onClick="javascript:window.close('','_parent','');"
		
//	let myarr = mylink.split(/[\\/]/).filter(n=>n);
		let arr = url.pathname.split('/').filter(n=>n); // parse and remove empty
		
		for (let n=arr.pop(); !isEmpty(n); n=arr.pop()){
			n = String(n).trim();
			let a = String(n).toUpperCase();
			if (a.indexOf('.HTML')>=0 || a.indexOf('.PHP')>=0){
				url.page = n.toLowerCase();
			}	else if (!isEmpty(n)) {
				if (!isEmpty(url.appdir)) url.appdir = '/' + url.appdir;
				url.appdir = n + url.appdir;
				url.appdir = String(url.appdir).toLowerCase();
			}
		}
		if (isEmpty(url.page) || url.page==='/') url.page = 'index.html';
		if (!isEmpty(url.appdir)) url.rootpath = '/'+ url.appdir +'/';
		url.param = getURLparams();
		fwAPP.api.set('url', url);
	}
	
	this.srvREQ = function(param){
		let url = getURL(param.fn);
		let formData = new FormData();

		param.legacy = (isEmpty(param.legacy) || param.legacy); // set legacy flag
		if (typeof param.err_callback === 'function') param.legacy = false; // auto remove legacy flag
		if (param.legacy && typeof param.callback !== 'function') param.legacy = false; // auto remove legacy flag

		let usr = fwAPP.api.get('usr');
		if (!isEmpty(usr) && usr.id>0){ // only use if user is known
			formData.append('usr', usr.id);
			formData.append('token', usr.token); // only if user is signed-in
		}

		let flds = fwAPP.api.get('var_page');
		if (param.fn == 'get'){
			flds = $.extend({}, flds, param.opt);
		} else {
			flds = $.extend({}, flds, (isEmpty(param.opt) ? getFLDS() : param.opt));
		}
		formData.append('cmd', param.cmd);
		formData.append('data', JSON.stringify(flds));
		formData.append('mode', isValid(apivars,'mode') ? apivars.mode : '');
		let show_opt = flds; 
		if (isValid(param,'opt.pwd')) show_opt.pwd = '########'; 
	
		const stms = rtdt.sdiff();
		const ms = rtdt.now();
		param.info = isEmpty(param.info) ? '' : param.info +':';
		httpREQ(url,{type:'POST', timeout:30000, loader:true, form:formData}, function(status, data){
			let obj = (status!=0) ? {result:{status:'REQ_ERROR', error:status, text:data}} : JSON.parse(data);
			obj.req = {fn:param.fn, cmd:param.cmd, opt:show_opt};
			
			fwLogger.log('srvREQ:'+ param.info + stms +':'+ rtdt.diff(ms), obj, url);
			if (param.legacy){
				fwLogger.warn('LEGACY_USE:'+ param.fn +':'+param.cmd);
				if (typeof param.callback === 'function') param.callback(obj);
				return;
			}
			
			fwAPP.api.onresult(obj, param.callback, param.err_callback);
		});
	};
	
	this.popoverHIDE = function(id){
		fwLogger.log(id);
		if (String(id).startsWith('popover')) return; // selected popover
		$('div[id^=popover]').removeClass('show').addClass('hide');
	};
	
	this.popoverENABLE = function(){
		// Enable all of document popovers
		const pTrgrLst = document.querySelectorAll('[data-bs-toggle="popover"]');
		const popoverLst = [...pTrgrLst].map(pTrgrEl => new bootstrap.Popover(pTrgrEl));
	};
	
	this.tooltipENABLE = function(){
		const pTrgrLst = document.querySelectorAll('[data-bs-toggle="tooltip"]');
		let tooltipLst = [...pTrgrLst].map(pTrgrEl => new bootstrap.Tooltip(pTrgrEl));
		tooltipLst.forEach(function(tooltip){tooltip.dispose();});
		tooltipLst = [...pTrgrLst].map(pTrgrEl => new bootstrap.Tooltip(pTrgrEl));
	};
	
	this.enablePAGEbootstrap = function(){
		// re-enable bootstrap functionality
		[].slice.call(document.querySelectorAll('select.cs-select')).forEach(function(el){
			new SelectFx(el);
		});
	};
	
	let action = {};
	
	this.submitCONTACT = function(fname){
		backdrop.hide();
		
		let opt = getFLDS(['contact_comment','contact_email','contact_name','contact_phone']);
		if (isEmpty(opt.contact_email) && isEmpty(opt.contact_phone)) return fwHTML.msg('warn', 'Contact', 'Please provide your email or phone number. Tnank you.', 15);
		if (isEmpty(opt.contact_email)) opt.contact_email = '';
		if (isEmpty(opt.contact_phone)) opt.contact_phone = '';
		if (isEmpty(opt.contact_comment)) opt.contact_comment = '';
		if (isEmpty(opt.contact_name)) opt.contact_name = '';
		
		function contact(){
			fwAPP.api.srvREQ({fn:'set', cmd:'CONTACT', opt:opt, callback:function(data){
				fwHTML.msg('success', 'Contact', 'Thank you for contacting our team.', 15);
				fwAPP.api.loadACTIONclean('home');
			}, err_callback:function(result){
				fwHTML.msg('danger', 'Contact', 'Please email us contact@gigzantic.com.', 30);
				fwAPP.api.loadACTIONclean('home');
			}});
		}
		
		return fwAPP.api.captcha('myaction', contact);
	};
	
	this.openURL = function(url, opt){
		backdrop.hide();
		if (isValid(opt,'advid') || isValid(opt,'tmrid')){
			let clicked = isValid(opt,'advid') ? {advid:[{id:opt.advid, cnt:1}]} : {};
			if (isValid(opt,'tmrid')) clicked = {tmrid:[{id:opt.tmrid, cnt:1}]};
			fwAPP.api.srvREQ({fn:'GIGPASSset', cmd:'DSP_UPD', opt:clicked});
		}
		let name = isValid(opt,'name') ? opt.name : '_blank';
		return window.open(url, name);
	};
	
	this.getACTION = function(name, prefix){
		if (isEmpty(prefix)) prefix = '';
		let fname = action[prefix +'_'+ name];
		return isEmpty(fname) ? '' : fname;
	};
	
	this.loadACTION = function(fname, opt, var_page){
		$('#body-content').scrollTop(0);
		
		let url = fwAPP.api.get('url');
		if (isEmpty(opt)) opt = {};
		if (isEmpty(var_page)) var_page = {};
		let param = '';
		if (isValid(opt, 'param')) param = '&'+ opt.param;
		
		let force = (!isEmpty(opt.force) && opt.force);
		let encoded = false; 
		let newurl = '?f='+ fname; 					
		let dbgurl = newurl; 					
		
		switch(fname){
			case 'eventinfo':
				force = true; 
				encoded = true; 
				newurl = '?e='+ opt;
				if (!isEmpty(url.appdir)) return gFn.openURL('/'+ newurl);
				break;
				
			case 'signin':
				force = true;
				/* falls through */
			case 'evalidate':
			case 'followup':
			case 'signauth':
				encoded = true; 
				newurl = '?f='+ fname +'&e='+ opt;
				break;
				
			case 'gigpassad':
				force = true; 
				/* falls through */
			default:
				if (typeof opt === 'string' || typeof opt === 'number') param = '&i='+ opt;
				break;
		}

		let name = isEmpty(opt.name) ? 'frame' : opt.name;
		if (!force && isValid(action, name) && action[name] === fname) return fwLogger.log('already there', action, name, fname);
		
		if (!isEmpty(param)) newurl += param;
		
		if (dbgurl != newurl) fwLogger.warn('loadACTION', fname, opt, encoded, dbgurl, newurl);
		
		fwAPP.api.set('var_page', var_page);

		action['P_'+ name] = action[name];
		action[name] = fname;
		gFn.updateURL(newurl);
		return singularity(); 
	};
	
	function loadPAGEset(oname,fname){
		function removeClassStartingWith(node,begin){
			node.removeClass(function(index,className){
				return (className.match(new RegExp("\\b"+begin+"\\S+", "g") ) || []).join(' ');
			});
		}
		let e = $('#fwapi-'+ oname);
		e.attr('fwapi',fname);
		e.attr('load-page',fname);
	}
	
	function loadPAGE(param, callback){
		fwLogger.log('loadPAGE', param);
		let found = false;
		
		$('div[load-page]').each(function(i, e){ // select all div with [load-page] attribute
			if (isEmpty($(e).attr('fwapi'))){
				fwLogger.error('missing fwapi attribute', e);
				return false; // must contain [fwapi] attribute
			}
			found = true;
			let fname = $(e).attr('load-page');
			$(e).removeAttr('load-page');
			
			gFn.getPAGE(fname, e, function(o){
				param.page = fname; // not used, recursive tracking only
				loadPAGE(param, callback); // keep loading until all recursive pages are loaded
			});
			return false;
		});

		if (found) return;
		// no more pages to load done with original load
		
		setTimeout(function(){
			if (isValid(param, 'cache') && param.cache !== 'static') gFn.cacheEMPTY();
			if (typeof callback === 'function') callback();
		}, 100);
	}
	
	this.cachePAGE = function(obj){
		let fname = obj.fname;
		let add_cache = true;
		
		if (isEmpty(apivars.cache_page)){
			apivars.cache_page = Array();
		} else {
			$.each(apivars.cache_page, function(i, obj){
				if (obj.fname === fname) add_cache = false;
			});
		}
		if (add_cache){
//			fwLogger.log('cachePAGE:'+ fname);
			apivars.cache_page.push(obj);
		}
	};
	
	this.cachePAGEget = function(fname){
		let html = '';
		
		if (isEmpty(apivars.cache_page)) return html;
		
		$.each(apivars.cache_page, function(i, obj){
			if (obj.fname === fname) html = obj.html;
		});
//		fwLogger.log('cachePAGEget:'+ fname);
		return html;
	};
	
	this.cacheEMPTY = function(){
		fwLogger.warn('cacheEMPTY', apivars.cache_page);
		apivars.cache_page = [];
	};
	
	this.getPAGE = function(fname, target, callback){
		let url = fwAPP.api.get('url');
		
		if (isEmpty(fname)){
			fwLogger.error('missing [fname]');
			return;
		}

		let html = gFn.cachePAGEget(fname); // do we already have it in the cache
		if (!isEmpty(html)){
			$(target).html(html);
			if (typeof callback === 'function') callback(html);
			return;
		}
		
		let cmd = 'page';
		if (String(fname).startsWith('cmn_')) cmd = '/cmn/page';	// override current path go get file from cmn repository
		else if (!isEmpty(url.appdir)) cmd = url.appdir +'/page';		// adjust current path offset
			
		srvFILE({cmd:cmd, fn:fname +'.html', mimetype:'text/html', process:false, callback:function(obj){
			if (!isEmpty(target)) $(target).html(obj);
			if (typeof callback === 'function') callback(obj);
			gFn.cachePAGE({fname:fname,html:obj});
		}, error_callback:function(obj){
			fwLogger.error('getPAGE:'+ cmd, fname +'.html', obj);
		}});
	};
	
	this.input = function(id, type, name, value, readonly){
		if (isEmpty(readonly)) readonly = false;
		var html = '';
		html += '<div class="row">';
		html += '<div class="col m-0 p-0">';
		html += '<div class="input-group m-0 p-0">';
		html += '<div class="input-group-prepend">';
		html += '<span class="input-group-text"><small class="font-weight-bold">'+ name +':</small></span>';
		html += '</div>';

		switch(type){
			case 'select':
				html += '<select class="form-control" '+ (readonly?'readonly="readonly" ':'') +'id="'+ id +'" name="'+ id +'">'+ value +'</select>';
				break;
				
			case 'phone':
				html += '<input '+ (readonly?'readonly="readonly" ':'') +'id="'+ id +'" type="'+ type +'" value="'+ value +'" class="form-control" placeholder="(999)999-9999" />';
				break;
				
			default:
			case 'text':
				html += '<input '+ (readonly?'readonly="readonly" ':'') +'id="'+ id +'" type="'+ type +'" value="'+ value +'" class="form-control" />';
				break;
		}
		html += '</div>';
		html += '</div>';
		html += '</div>';
		return html;
	};
	
	function initSEARCH(){
// https://stackoverflow.com/questions/27569372/selectize-js-events-wont-work
		let frame = fwAPP.api.get('frame');
	
		function searchONchange(text){
			if (isEmpty(text)) return h_search.api().clearOptions();
			
			fwAPP.mymodal.hide();
			
			// this will be id or text entered
			let value = h_search.data(function(k, v){
				if (v.name == text) return true;
			});
			fwLogger.log('searchONchange: '+ fmtStr.truncate(text, 20), value, h_search.api().getValue());
			if (isEmpty(value))	return fwHTML.msg('danger', 'Search', 'oops ... unknown error occured, we will notify our support team.', 15);
			
			value = value[0];
			switch(value.type){
				case 'event': return gFn.loadACTION('eventinfo', value.encoded);
				case 'evttmr': return gFn.showEVTTMR(value.id);
			}
			fwHTML.msg('danger', 'Search', 'oops ... unknown error occured, we will notify our support team.', 15);
		}
		
		function searchONtype(text, callback){
			if (String(text).length < 3) return;
			fwLogger.error(text);
			let flds = getFLDS(['text', 'lat', 'lng', 'filterbyaddr']);
			let location = gFn.myLOCATION();
			let opt = {text:text, lat:location.lat, lng:location.lng};
			if (!isEmpty(flds.filterbyaddr)) opt.filterbyaddr = true;
			
			fwAPP.api.srvREQ({fn:'get', cmd:'GBL_SRH', opt:opt, callback:function(data){
				h_search.setdata(data);
				h_search.api().clearOptions();				
				if (typeof callback === 'function') return callback(h_search.data(true));
			}, err_callback:function(result){
				if (typeof callback === 'function') return callback();
			}});
		}
		
		let opt = { create: false, closeAfterSelect: true,
			onChange: searchONchange,
			load: searchONtype,
			plugins: ['remove_button', 'restore_on_backspace', 'clear_button'],
			valueField: 'name',
			labelField: 'name',
			searchField: ['name','description','address']
		};
		
		let h_search = myselectize('search', opt);
		h_search.api().focus();
		
		fwHTML.checkboxSWevent('filterbyaddr', function(opt){
			fwLogger.info(opt);
			h_search.api().clearOptions();
			if (opt.action == 'on'){
			} else {
			}
			h_search.api().focus();
		});
	}
	
	this.search = function(){
		let title = fwHTML.icon({name:'magnifying-glass', type:'solid'}) +'Search';
		let html = fwHTML.elem({tag:'select', attr:{id:'search', name:'search', class:'text-start w-100'}});
		let footer = fwHTML.elem({tag:'button', attr:{'type':'button', 'class':'btn btn-sm btn-text-bg-dark-gz', 'data-dismiss':'modal'}}, fwHTML.icon('square-check') +'Ok');
		
		html = fwHTML.elem({tag:'div', attr:{class:'pt-1'}}, fwHTML.checkboxSW({id:'filterbyaddr', text:'Address Name'})) + html;
		html = fwHTML.subtitle('Search by event name, act or location') + html;
		fwAPP.api.myMODAL(fwHTML.title(title), html, footer, function(){
			initSEARCH();
		},function(event){
			fwAPP.mymodal.hide();
		});
	};
	
	function updateMODE(){
		let mode = 'logo';
		if (gFn.figimode()) mode = 'figi';
		$('#hdr_menu_home').attr('src', '/cmn/img/'+ mode +'.jpg');
	}
	
	this.setLOCATION = function(){
		shake('#my_location');
		
		function searchONchange(text){
			if (isEmpty(text)){
				h_lookup.api().clearOptions();
				return;
			}
			doUPDATE();
			fwAPP.mymodal.hide();
		}

		function setLevel(level){
			fwLogger.setLevel(level);
			beep();
			fwAPP.mymodal.hide();
		}
		
		function searchONtype(text, callback){
			text = String(text);
			if (text.length < 3) return;
			fwLogger.error(text);
			switch(text){
				case 'figi':
					apivars.mode = gFn.figimode() ? '' : 'figi';
					new storage('LOCAL').set('mode', apivars.mode);				
					updateMODE();
					beep();
					fwAPP.mymodal.hide();
					return setTimeout(navigateURL, 1000);
					
				case '+lall+': return setLevel('ALL');
				case '+loff+': return setLevel('OFF');
				case '+linf+': return setLevel('INFO');
			}
			
			fwAPP.api.srvREQ({fn:'get', cmd:'GET_LOC', opt:{text:text,lat:location.lat,lng:location.lng}, callback:function(data){
				h_lookup.setdata(data);				
				if (typeof callback === 'function') return callback(h_lookup.data(true));
			}, err_callback:function(result){
			}});
		}

		function doUPDATE(){
			let value = h_lookup.api().getValue();
			let data = h_lookup.data(function(k, v){
				return (value == v.zip);
			});
			if (!isEmpty(data)){
				gFn.myLOCATION('set', data[0]);
				locationUPDATE(true);
			}
		}

		let h_lookup = {};
		let footer = fwHTML.elem({tag:'button',attr:{'type':'button', 'class':'btn btn-sm btn-outline-dark btn-cmd-reset', style:'display:none;', 'data-dismiss':'modal'}});
		footer += fwHTML.elem({tag:'button', attr:{'type':'button', 'class':'btn btn-sm btn-text-bg-dark-gz', 'data-dismiss':'modal'}}, fwHTML.icon('square-check') +'Ok');
		fwAPP.api.myMODAL(fwHTML.title(fwHTML.spinner()), fwHTML.subtitle('Discovering GPS Coordinates ...'), footer, function(){
			let opt = { create: false,
				onChange: searchONchange,
				load: searchONtype,
				plugins: ['remove_button', 'restore_on_backspace', 'clear_button'],
				valueField: 'zip',
				labelField: 'name',
				searchField: ["name"]
			};
			
			function start(){
				let location = gFn.myLOCATION();
				let geo = gFn.myLOCATION('geo');
				let title = fwHTML.icon({name:'location-dot', text:'dark-gz'}) + location.name;
				
				fwLogger.log(location, geo);
				let name = fmtStr.truncate(geo.name, 10);		

				let badge = fwHTML.subtitle('Type your Zip Code or City below');
				html = fwHTML.elem({tag:'select',attr:{id:'lookup',name:'lookup',class:'w-100'}});
				html = badge + fwHTML.elem({tag:'label',attr:{for:'lookup',class:'input-group text-start'}}, html);
				$('.modal-title').html(fwHTML.title(title));
				$('.modal-body').html(html);
				if (location.user) $('.modal-footer > .btn-cmd-reset').html(fwHTML.icon('rotate')+ name).show();
				
				h_lookup = myselectize('lookup',opt);
				h_lookup.api().focus();
			}
			
			if (navigator.geolocation){
				navigator.geolocation.getCurrentPosition(function(pos){
					fwLogger.log('navigator.geolocation', pos.coords);
					fwAPP.api.addset('var_page', {lat:pos.coords.latitude, lng:pos.coords.longitude});
					getSYSVAL(function(data){
						start();
					});
				}, function(pos){
					start();
				}, {enableHighAccuracy:false, timeout:3000, maximumAge:0});
			} else {
				start();
			}
		
		},function(event){
			if (fwAPP.api.eventis(event, 'btn-primary')){
				doUPDATE();
			} else if (fwAPP.api.eventis(event, 'btn-cmd-reset')){
				gFn.myLOCATION('clear');
				locationUPDATE(true);
			}
			fwAPP.mymodal.hide();
		});
	};
	
	this.myLOCATION = function(cmd, opt){
		let sysval = fwAPP.api.get('sysval');
		let geo = isValid(sysval,'geo') ? sysval.geo : {};
		if (isEmpty(geo)) return fwLogger.error('sysval.geo');
		
		let location = new storage('LOCAL').get('location');
		if (!isEmpty(location)) location.user = true;
		
		switch(cmd){
			case 'get':
			default:
				return isEmpty(location) ? geo : location;
				
			case 'set':
				if (isEmpty(opt)) return isEmpty(location) ? geo : location;
				
				const cleanup = (({city, state, zip, name, timezone, daylightsaving, lat, lng}) => ({city, state, zip, name, timezone, daylightsaving, lat, lng}))(opt);
				new storage('LOCAL').set('location' ,cleanup);
				fwLogger.log('myLOCATION:'+ cmd, cleanup);
				return cleanup;
				
			case 'geo':
				return geo;
				
			case 'clear':
				new storage('LOCAL').clear('location');
				return geo;
		}
	};

	this.eventIDis = function(e, targetonly){
		let target = $(e.target);
		let event_id = target.prop('id');
		let getid_retry = 0;
		
		if (!isEmpty(targetonly) && targetonly) return event_id;
		
		while(isEmpty(event_id) && !isEmpty(target) && ++getid_retry<5){
			target = target.parent();
			if (!isEmpty(target)) event_id = target.prop('id');
		}
		
		return event_id;
	};
	
	this.eventis = function(event, myclass){
		return ($(event.target).hasClass(myclass) || $(event.target).parent().hasClass(myclass));
	};

	function myMODALtrap(onEVENT, trap){
		if (isEmpty(trap)) trap = '.modal-footer .btn';
		if (isEmpty(onEVENT)){
			$(trap).off('click');
			return false;
		}
		
		$(trap).off('click').on('click', function(event){
			onEVENT(event);
		});
		return true;
	}
	
	this.myMODAL = function(title,html,footer,onINIT,onEVENT,onCLOSE){
		$('#mymodal').off('shown.bs.modal').on('shown.bs.modal', function(){
			$('.modal-title').html(title);
			$('.modal-body').html(html);
			$('.modal-footer').html(footer);

			if (typeof onINIT === 'function') onINIT();
			
			$('#mymodal').off('hidden.bs.modal').on('hidden.bs.modal', function(event){
				fwLogger.log('myMODAL:CLOSE:trap='+ myMODALtrap());
				$('.modal-title').html('');
				$('.modal-body').html('');
				$('.modal-footer').html('');
				if (typeof onCLOSE === 'function') onCLOSE(event);
			});
			
			if (typeof onEVENT === 'function') myMODALtrap(onEVENT);
		});
		
		fwAPP.mymodal.show();
	};	
	
	this.myDLG = function(opt, callback){
		let title = isEmpty(opt.title) ? '' : opt.title;
		let footer = fwHTML.elem({tag:'button', attr:{'type':'button', 'class':'btn btn-primary', 'data-dismiss':'modal'}}, 'Confirm');
		footer += fwHTML.elem({tag:'button', attr:{'type':'button', 'class':'btn btn-danger', 'data-dismiss':'modal'}}, 'X');
		if (!isEmpty(opt.footer)) footer = opt.footer;

		let html = isValid(opt, 'html') ? opt.html : fwHTML.elem({tag:'p'}, opt.text);
		
		fwAPP.api.myMODAL(fwHTML.title(title), html, footer, function(){
			$('.btn-danger').focus();
			if (typeof opt.cbinit === 'function') opt.cbinit();
		}, function(event){
			if (fwAPP.api.eventis(event, 'btn-primary')){
				if (typeof opt.callback === 'function') opt.callback();
			}
			fwAPP.mymodal.hide();
		});
	};
	
	function getSYSVAL(callback){
		if (typeof callback !== 'function') return fwLogger.error('getSYSVAL:callback action is required');
		fwAPP.api.srvREQ({fn:'GIGPASSget', cmd:'SYS_VAL', opt:{}, callback:function(data){
			let sysval = fwAPP.api.set('sysval', data);
			if (typeof callback === 'function') callback(sysval);
		}, err_callback:function(result){
		}});
	}
	
	this.dtDUR2sdtANDedt = function(dt, hours, minutes){
		if (isEmpty(dt)) return fwLogger.error('dtDUR2sdtANDedt:dt');
		if (isEmpty(hours)) hours = 4;
		
		if (isEmpty(minutes)){
			let duration = hours;
			hours = Math.floor(duration);
			minutes = (duration%1).toFixed(2).substring(2);
		}
		
		let result = {s:{},e:{},d:{}};
		result.s.dt = Date.parse(dt);
		result.s.str = result.s.dt.toString('M/d/yyyy hh:mm tt');
		result.s.str_dt = result.s.dt.toString('M/d/yy hh:mm tt');
		result.s.str_date = result.s.dt.toString('M/d/yy');
		result.s.elapsed = (Date.today().compareTo(Date.parse(result.s.dt))>0);
		result.e.dt = Date.parse(dt).add({hours:hours,minutes:minutes});
		result.e.str = result.e.dt.toString('M/d/yyyy hh:mm tt');
		result.e.str_date = result.e.dt.toString('M/d/yy');
		result.d.h = hours;
		result.d.m = minutes;
		
		return result;
	};
		
	this.payload = function(target){
		let url = fwAPP.api.get('url');
		let homescreen = (isEmpty(url.appdir) || url.appdir == '/');  
		
		if (!enableALLfeatures()) return;
		if (isEmpty(target)) target = 'page_root';
		
		if (homescreen && target != 'page_root') return;  
		else if (!homescreen && target == 'page_root') return;
		
		$('#'+ target).html(fwAPP.html.getfeed());
	};
	
	this.upload = function(fpath, fname, data, callback){
		fwLogger.log('upload', fpath, fname);
		
		let formData = new FormData();
		formData.append('path', fpath);
		formData.append('file', data, fname);
		
		$.ajax(getURL('upload'), {
			method:'POST',
			data:formData,
			processData:false,
			contentType:false,
			success:function(result){
				result = JSON.parse(result);
				result = (typeof callback === 'function') ? callback(result) : fwLogger.info('Upload success', result);
			},
			error:function(result){
				result = JSON.parse(result);
				result = (typeof callback === 'function') ? callback(result) :	fwLogger.error('Upload error', result);
			}
		});
	};
	
	this.captchavalidated = function(){
		return $('#captchadone').is(":hidden");
	};
	
	function validateREADY(name){
		let v = $('#'+ name).val();
		if (v.length > 4){
			v = v.substring(0, 4);
			$('#'+ name).val(v);
		}
		return (v.length == 4);
	}
	
	this.validateCODEreset = function(){
		$('#validatecode').val('').focus();
	};
	
	this.validateCODE = function(name, callback){
		let flds = fwAPP.api.get('var_page');
		
		let html = fwHTML.input_floating({id:'validatecode', type:'text', name:'XXXX'});
		
		html = fwHTML.elem({tag:'div', attr:{class:'card-text form-group text-start'}}, html);
		let icon = fwHTML.icon({name:'square-caret-left', pos:'center'});
		let btn = fwHTML.elem({tag:'a', attr:{class:'btn btn-sm btn-text-bg-dark-gz w-100', onclick:"fwAPP.api.validateCODEreset();"}}, icon);
		html += fwHTML.elem({tag:'div', attr:{class:'text-center'}}, btn);
		html = fwHTML.elem({tag:'div', attr:{class:'card-header fw-bold fst-italic'}}, 'REQUEST CODE') + html;
		html += fwHTML.elem({tag:'div', attr:{class:'card-footer fst-italic'}}, 'Use code from &lt;'+ flds.email +'&gt; message.');
		html = fwHTML.elem({tag:'div', attr:{class:'card'}}, html);
		$('#'+ name).html(html);
		
		gFn.validateCODEreset();
		
		$('#validatecode').off('keyup').on('keyup', function(event){
			if (validateREADY('validatecode')){
				let code = $('#validatecode').val();
				fwAPP.api.srvREQ({fn:'AUTHget', cmd:'REQUEST_VALID', opt:{'lnktok':flds.lnktok,'code':code}, callback:function(data){
					$('.card-header').addClass('text-bg-dark-gz');
					if (!isEmpty(data) && data){
						if (typeof callback === 'function'){
							setTimeout(function(){callback();}, 1000);
						}
						return;
					}
					$('.card-header').removeClass('text-bg-dark-gz');
					shake('#'+ name, true);
					gFn.validateCODEreset();
				}, err_callback:function(result){
				}});
			}
		});
	};
	
	this.captcha = function(name, callback, param){
		if (name == 'captcha') return fwLogger.error('id cannot == captcha');
		
		if (!isEmpty(name) && $("#"+ name).length == 0){
			fwLogger.warn('captcha:adding', name);
			$('#fwapi-frame').html(fwHTML.elem({tag:'div', attr:{id:name, class:'d-flex flex-column align-items-center'}}));
		}
		
		if (!isEmpty(name) && $("#"+ name).length > 0){
			let icon = fwHTML.icon({name:'rotate', pos:'center'});
			let html = fwHTML.elem({tag:'a', attr:{class:'btn btn-sm btn-text-bg-dark-gz w-100', onclick:"fwAPP.api.captcha();"}}, icon);
			
			html = fwHTML.elem({tag:'img', attr:{id:'captchaimg', class:'card-img-bottom'}}) + html;
			html = fwHTML.elem({tag:'div', attr:{id:'captchadone', class:'card-text'}}, html);
			
			icon = fwHTML.icon({name:'square-check', pos:'center'});
			let body = fwHTML.elem({tag:'button', attr:{id:'captchavalid', type:'text', class:'btn', onclick:"fwAPP.api.captchavalid();"}}, icon);
			body = fwHTML.elem({tag:'input', attr:{id:'captcha', type:'text', class:'form-control text-uppercase', placeholder:'xxxx'}}) + body;
			body = fwHTML.elem({tag:'div', attr:{class:'input-group'}}, body);
			body = fwHTML.elem({tag:'label', attr:{id:'captchalabel', for:'captcha', class:'form-label small fst-italic m-0'}}) + body;
			body = fwHTML.elem({tag:'div', attr:{class:'card-body text-bg-dark-gz p-1'}}, body);
			html = fwHTML.elem({tag:'div', attr:{class:'card'}}, body + html);
			
			$('#'+ name).html(html);
			
			$('#captcha').off('keyup').on('keyup', function(event){
				if (validateREADY('captcha')){
					let captcha = $('#captcha').val();
					fwAPP.api.srvREQ({fn:'AUTHget', cmd:'CAPTCHA_VALID', opt:{'captcha':captcha}, callback:function(data){
						if (!isEmpty(data) && data){
							$('#captchalabel').html('Validated');
							$('#captcha').addClass('bg-success-subtle').prop('disabled', 'disabled');
							$('#captchavalid').removeClass('btn-secondary').addClass('btn-success');
							$('#captchadone').hide();
							shake('#'+ name, 'ping');
							setTimeout(function(){
								$('#captchalabel').html(icon);
								if (typeof callback === 'function'){
									setTimeout(function(){callback(captcha);}, 1000);
								}
							}, 1000);							
							return;
						}
						fwAPP.api.captcha();
					}, err_callback:function(result){
					}});
				}
			});
		}
		
		fwAPP.api.srvREQ({fn:'AUTHget', cmd:'CAPTCHA', opt:{}, callback:function(data){
			if (!isEmpty(data)){
				$('#captchadone').show();
				$('#captcha').removeAttr('disabled').removeClass('bg-success-subtle').val('').attr('autocomplete','new-password').focus();
				$('#captchavalid').removeClass('btn-success').addClass('btn-secondary').addClass('disabled');
				$('#captchaimg').attr('src', data);
				$('#captchalabel').html('Enter text shown below');
			}
		}, err_callback:function(result){
		}});
	};
	
	return gFn;
}