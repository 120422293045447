function authAPI(){
	const gFn = this;
	var signoutCNT = 0;
	var t;
	var role_active = '';
	
	/*
	window.onmousemove = resetTIMER; // catches mouse movements
	window.onmousedown = resetTIMER; // catches mouse movements
	window.onscroll = resetTIMER;    // catches scrolling
	*/
	window.onload = function(){gFn.resetTIMER();};
	window.onclick = function(){gFn.resetTIMER();};     // catches mouse clicks
	window.onkeypress = function(){gFn.resetTIMER();};  //catches keyboard actions
	
	this.CLEAN = function(opt){
		if (opt == 'consent') return new storage('LOCAL').set('consent', {});
		new storage('LOCAL').set('validated', {});
	};
	
	this.SIGNOUT = function(url){
		gFn.CLEAN();
		
		if (isEmpty(fwAPP.usr)) return (signoutCNT=0); // nothing todo already cleaned
		
		delete fwAPP.usr; // cleanup user session
		if (isEmpty(url)) url = '/';
		console.log('authAPI.SIGNOUT',url);
		window.onload = null;
		window.onclick = null;
		window.onkeypress = null;
		navigateURL(url);
	};
	
	this.WATCHDOG = function(url){
		if (++signoutCNT>30){
			console.log('authAPI.WATCHDOG');
			return gFn.SIGNOUT();
		}
		gFn.resetTIMER(true);
	};
		
	this.resetTIMER = function(skipCNT){
		if (isEmpty(skipCNT)) skipCNT = false;
		if (!skipCNT) signoutCNT = 0;
		clearTimeout(t);
		t = setTimeout(function(){gFn.WATCHDOG();}, 60000);  // time is in milliseconds (1000 is 1 second)
	};
	
	this.VALIDATED = function(){ // we have a user and email is validated
		let usr = gFn.USR();
		return (parseInt(usr.id)>0 && isValid(usr,'email') && parceInt(usr.validated));
	};
	
	this.AUTH = function(cmd, opt, callback){
		console.log('authAPI.AUTHset:'+ cmd, opt);
		fwAPP.api.srvREQ({fn:'AUTHset', cmd:cmd, opt:opt, callback:function(data){
			if (typeof callback === 'function') callback(true, data);
		}, err_callback:function(result){
			if (typeof callback === 'function') callback(false, result);
		}});
	};
	
	this.CONSENT = function(consent){ // we have a user consent
		if (!isEmpty(consent)) new storage('LOCAL').set('consent', consent);
		return new storage('LOCAL').get('consent');
	};
	
	this.USR = function(){ // we have a user and email is validated
		let validated = new storage('LOCAL').get('validated');
		return isEmpty(validated) ? {} : validated;
	};
	
	this.SIGNEDIN = function(){ // user is logged in
		let usr = gFn.USR();
		console.info('SIGNEDIN', usr);
		if (!isValid(usr,'id')) return false;
		return (parseInt(usr.id)>0 && isValid(usr,'token'));
	};

	function setVALIDATED(data){
		new storage('LOCAL').set('validated', data);
		return data;
	}
	
	this.CRTUSR = function(data){
		return setVALIDATED(data);
	};
	
	this.REVALIDATE = function(callback){
		fwAPP.api.srvREQ({fn:'AUTHget', cmd:'REVALIDATE', opt:{fobar:false}, callback:function(data){
			setVALIDATED(data);
			if (typeof callback === 'function') callback(data);
		}, err_callback:function(result){
			setVALIDATED({id:0});
		}});
	};
	
	this.SIGNIN = function(usr, pwd, callback, err_callback){
		if (gFn.SIGNEDIN())	gFn.CLEAN(); // if still signed-in

		console.log('SIGNIN:'+ usr);
		fwAPP.api.srvREQ({fn:'AUTHget', cmd:'SIGNIN', opt:{usr:usr, pwd:pwd}, callback:function(data){
			setVALIDATED(data);
			if (typeof callback === 'function') callback(data);
		}, err_callback:function(result){
			setVALIDATED({id:0});
			if (typeof err_callback === 'function') err_callback(result);
		}});
	};
	
	this.EXEC = function(cmd, opt, callback, err_callback){
		if (gFn.SIGNEDIN())	gFn.CLEAN(); // if still signed-in
		
		if (isEmpty(cmd)) return console.error('EXEC:missing command');

		console.log('EXEC:'+ cmd, opt);
		fwAPP.api.srvREQ({fn:'AUTHget', cmd:cmd, opt:opt, callback:function(data){
			$(document).keypress(function(e){});
			setVALIDATED(data);
			if (typeof callback === 'function') callback(data);
		}, err_callback:function(result){
			setVALIDATED({id:0});
			if (typeof err_callback === 'function') err_callback(result);
		}});
	};
	
	return gFn;
}