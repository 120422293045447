function signinAPI(){
	const gFn = this;
	let email = '';
	
	this.init = function(){
		console.info('signinAPI:init');
		gFn.usr();
	};

	this.setup = function(){
		console.info('signinAPI:setup');

		$(document).keypress(function(e){
			if (e.which == 13){ // enter to accept login
				$("#signin").click();
				let result = isEmpty(email) ? gFn.next('signin') : gFn.exec('signin');
			}
		});
	};
	
	this.clear = function(){
		$(document).keypress(function(e){});
	};
	
	function getVALS(name){
		let url = fwAPP.api.get('url');
		
		let email_only = (isEmpty(name) || (name != 'signin_menu' && isValid(url, 'param.e')));
		if (email_only) name = 'signin';
		
		let flds = getFLDS([name +'_email', name +'_pwd', name +'_cb']);
		$('input[type="password"]').val('');
//		setFLDval(name +'_pwd');
		
		email = flds[name +'_email'];
		let remember = !isEmpty(flds[name +'_cb']);
		
		if (!email_only) new storage('LOCAL').set('usr', remember ? {email:email} : {});
		
		return email_only ? {email:email} : {email:email, pwd:flds[name +'_pwd'], remember:remember};
	}
	
	this.usr = function(){
		let usr = new storage('LOCAL').get('usr');
		if (!isEmpty(usr)) email = usr.email;
		return usr;
	};
	
	this.exec = function(name){
		function next(){
			fwAPP.auth.EXEC(action.cmd, {email:v.email}, function(data){
				if (action.cmd == 'USR_VLD' && !isEmpty(data.verified) && data.verified){
					fwHTML.msg('success', action.title, '&lt;'+ email +'&gt; has been verified.', 30);
				} else {
					fwHTML.msg('info', action.title, 'In a few minutes please check &lt;'+ email +'&gt; Inbox. If still not found, also check your SPAM and Junk Folders.', 30);
				}
				fwAPP.api.loadACTION('home');
			}, function(result){
				let msg = ', ';
				switch(result.text){
					case 'USR_NOT_VERIFIED': msg += fwHTML.elem({tag:'a', attr:{onclick:"fwAPP.api.loadACTION('signin');"}}, 'Verify your email');
						break;
						
					default: msg += 'please try at a later date.'; break;
				}
				fwHTML.msg('danger', action.title, 'request failed with '+ result.text + msg, 15);
				fwAPP.api.loadACTION('home');
			});
		}
		
		gFn.clear();
		let v = getVALS(name);
		let action = {};
		
		switch(name){
			case 'signin':
			case 'signin_menu':
				if (isEmpty(v.email)) return fwHTML.msg('danger', 'Sign-in', 'Your email is required to sing-in.', 10);
				
				fwAPP.auth.SIGNIN(v.email, v.pwd, function(data){
					fwAPP.api.srvREQ({fn:'GIGPASSget', cmd:'ACC_APR', opt:{fn:'signin'}, callback:function(data){
						fwAPP.api.loadACTIONclean();
					}, err_callback:function(result){
					}});
				}, function(result){
					fwHTML.msg('danger','Sign-in','please re-verify email &lt;'+ email +'&gt; and the password you entered.',15);
					result = (fwHTML.fwapi() == 'signin') ? shake('#myaction', true) : fwAPP.api.loadACTION('signin');
				});
				return;
				
			case 'verify':
				action.cmd = 'USR_VLD';
				action.title = 'verify your email';
				return fwAPP.api.captcha('myaction', next);
				
			case 'pwdchange':
				action.cmd = 'PWD_RST';
				action.title = 'reset password';
				return fwAPP.api.captcha('myaction', next);
		}
		if (isEmpty(action)) return console.error('empty selection');
	};
	
	this.show = function(name){
		let flipcard = $("#flipcard");
		if (isEmpty(flipcard) || isEmpty(flipcard.data("flip-model"))) return gFn.loadcard(name);
		
		if (flipcard.data("flip-model").isFlipped){
			flipcard.on('flip:done',function(){
				flipcard.off('flip:done');
				gFn.loadcard(name);
			});
			flipcard.flip(false);
			return;
		}
		gFn.loadcard(name);
	};
	
	this.process = function(){
		let url = fwAPP.api.get('url');
		let flds = fwAPP.api.get('var_page');
		
		if (isValid(url, 'param.e') && url.param.e == 'verify'){
			fwAPP.auth.AUTH('USR_VLD', {lnktok:flds.lnktok}, function(ok, data){
				if (ok) fwHTML.msg('success', 'verify your email', '&lt;'+ data.email +'&gt; has been verified.', 30);
				else fwHTML.msg('warning', 'verify your email', 'unable to verify, please try again at a later time.', 30);
				return fwAPP.api.loadACTION('home');
			});
			return;
		}
		
		let pwd = $('#signin_pwd').val();
		fwAPP.auth.AUTH('PWD_RST', {lnktok:flds.lnktok, pwd:pwd}, function(ok, data){
			if (ok) fwHTML.msg('success', 'reset password', 'success, please use your new password on next sign-in.', 30);
			else fwHTML.msg('warning', 'reset password', 'unable to complete this operation, please try again at a later time.', 30);
			return fwAPP.api.loadACTION('signin');
		});
	};

	this.validatereset = function(){
		let url = fwAPP.api.get('url');
		let flds = fwAPP.api.get('var_page');
		
		function setpwd(){
			function badge(name, description){
				return fwHTML.elem({tag:'span', attr:{id:name, class:'badge text-dark text-start text-truncate w-100'}}, fwHTML.icon('square-check') + description);
			}
			
			function verifyPWD(){
				function updateRULE(name, rule){
					let result = rule ? $('#pwd'+ name).removeClass('text-dark').addClass('text-bg-dark-gz') : $('#pwd'+ name).removeClass('text-bg-dark-gz').addClass('text-dark');
					return rule;
				}
				
				let value = $('#signin_pwd').val();
				// Minimum of 8 characters
				let l = updateRULE('l', (value.length >= 8));
				// At least one lowercase letter
				let w =	updateRULE('w', (/[a-z]/.test(value)));
				// At least one uppercase letter
				let u =	updateRULE('u', (/[A-Z]/.test(value)));
				// At least one number
				let n =	updateRULE('n', (/\d/.test(value))); 
				// At least one from following: #?!@$%&*
				let s =	updateRULE('s', (/[#?!@$%&*]/.test(value)));
				return (l && u && w && n && s);
			}

			let html = fwHTML.elem({tag:'span', attr:{class:'badge text-start fst-italic w-100'}}, 'Email: '+ flds.email);
			let icon = fwHTML.icon({name:'square-check', pos:'center'});
			if (isValid(url, 'param.e') && url.param.e == 'pwdchange'){
				let fld = fwHTML.input_floating({id:'signin_pwd', type:'password', autocomplete:true, name:'New Password'});
				fld += fwHTML.elem({tag:'span', attr:{id:'check_pwd', class:'input-group-text'}}, icon);
				html += fwHTML.elem({tag:'div', attr:{class:'input-group'}}, fld);
				
				fld = fwHTML.input_floating({id:'signin_pwd2', type:'password', autocomplete:true, name:'Confirm password'});
				fld += fwHTML.elem({tag:'span', attr:{id:'check_pwd2', class:'input-group-text'}}, icon);
				html += fwHTML.elem({tag:'div', attr:{class:'input-group'}}, fld);
				
				html = fwHTML.elem({tag:'div', attr:{class:'card-text form-group text-start bg-dark'}}, html);
				html = fwHTML.elem({tag:'div', attr:{class:'card-header text-bg-dark-gz text-start fw-bold ps-2'}}, 'Enter New Password') + html;
			}
			
			let btn = fwHTML.elem({tag:'a', attr:{id:'process', class:'btn btn-text-bg-dark-gz disabled', onclick:"fwAPP.signin.process();"}}, 'Save');
			html += fwHTML.elem({tag:'div', attr:{class:'card-footer fst-italic'}}, btn);
			html += badge('pwdl', 'Minimum of 8 characters');
			html += badge('pwdw', 'At least one lowercase letter');
			html += badge('pwdu', 'At least one uppercase letter');
			html += badge('pwdn', 'At least one number');
			html += badge('pwds', 'At least one from: #?!@$%&*');
			html = fwHTML.elem({tag:'div', attr:{class:'card'}}, html);
			$('#myaction').html(html);
			
			$('#signin_pwd,#signin_pwd2').off('keyup').on('keyup', function(event){
				let pwd = $('#signin_pwd').val();
				let pwd2 = $('#signin_pwd2').val();
				let verify = verifyPWD();
				
				let result = verify ? $('#check_pwd').addClass('text-bg-dark-gz') : $('#check_pwd').removeClass('text-bg-dark-gz');
				
				if (verify && pwd == pwd2){
					$('#check_pwd2').addClass('text-bg-dark-gz');
					$('#process').removeClass('disabled');
				} else {
					$('#check_pwd2').removeClass('text-bg-dark-gz');
					$('#process').addClass('disabled');
				}
			});
		}
		
		function LNKVLD(){
			fwAPP.api.updateURL('/?f=signauth&e=verify');
			gFn.process();
		}
		
		if (isValid(url, 'param.a')){
			flds = fwAPP.api.set('var_page',{'lnktok':url.param.a});
		
			fwAPP.api.srvREQ({fn:'AUTHget', cmd:'LNK_VLD', opt:{'lnktok':flds.lnktok}, callback:function(data){
				switch(data.tname){
					case 'usr_new':
					case 'usr_new_html':
					case 'pwd_rst':
					case 'pwd_rst_html':
						flds = fwAPP.api.set('var_page',{'lnktok':url.param.a, email:data.email});
						fwAPP.api.updateURL('/?f=signauth&e=pwdchange');
						url = fwAPP.api.get('url');
						fwAPP.api.captcha('myaction', setpwd);
						break;
						
					default:
						return fwAPP.api.captcha('myaction', LNKVLD);
				}
			}, err_callback:function(result){
				fwHTML.msg('danger', 'validate link', result.text, 15);
				fwAPP.api.loadACTION('home');
			}});
			return;
		}
		
		if (!isValid(flds, 'lnktok') || isEmpty(flds.lnktok)){
			fwHTML.msg('danger', 'REQUEST CODE', 'Needed information is missing or expired.', 30);
			return fwAPP.api.loadACTION('signin');
		}			
			
//		return setpwd();
		if (isValid(url, 'param.e') && url.param.e == 'verify') return fwAPP.api.validateCODE('myaction', gFn.process);
		
		function captcha(){
			fwAPP.api.captcha('myaction', setpwd);
		}
		fwAPP.api.validateCODE('myaction', captcha);
	};
	
	function signinURL(name){
		let	url = fwAPP.api.get('url');
		if (isEmpty(name) && isValid(url, 'param.e')){
			switch(url.param.e){
				case 'pwdchange': name = 'RESET PASSWORD'; break;
				case 'verify': name = 'VERIFY EMAIL'; break;
				case 'new': name = 'NEW ACCOUNT'; break;
				case 'other': name = 'THIRD PARTY SIGN-IN'; break;
			}
		}
		
		let action = '';
		if (isEmpty(name)) name = 'sign-in';
		name = String(name).toUpperCase();
		switch(name){
			case 'RESET PASSWORD': action = '&e=pwdchange'; tabFOCUS(1); break;
			case 'VERIFY EMAIL': action = '&e=verify'; tabFOCUS(2); break;
			case 'NEW ACCOUNT': action = '&e=new'; tabFOCUS(3); break;
			case 'THIRD PARTY SIGN-IN': action = '&e=other'; tabFOCUS(4); break;
		}
		fwAPP.api.updateURL('/?f=signin'+ action);
		return name;
	}
	
	this.loadcard = function(name){
		gFn.setup();
		name = signinURL(name);
		email = '';
		
		// CARD FRONT
		let html = fwHTML.elem({tag:'label', attr:{class:'fw-bold fst-italic'}},'Email address');
		html += fwHTML.elem({tag:'input', attr:{id:'signin_email', type:'email', class:'form-control', placeholder:'Email'}});
		html = fwHTML.elem({tag:'div', attr:{class:'card-text form-group text-start'}}, html);
		if (name == 'SIGN-IN') html += fwHTML.elem({tag:'div', attr:{class:'form-check pt-3'}}, fwHTML.elem({tag:'label'}, fwHTML.elem({tag:'input', attr:{id:'signin_cb', type:'checkbox',class:'form-check-input'}}) + ' Remember Me'));
		let btn = fwHTML.elem({tag:'a', attr:{class:'btn btn-text-bg-dark-gz', onclick:"fwAPP.signin.next('signin');"}}, 'Next');
		html += fwHTML.elem({tag:'div', attr:{class:'text-center py-3'}}, btn);
		
		let title = fwHTML.elem({tag:'div', attr:{id:'card_title', class:'text-bg-dark-gz p-2'}}, name);
		let fhtml = fwHTML.card({title:title, text:html, priv:true});
		
		// CARD BACK
		html = fwHTML.elem({tag:'label', attr:{id:'pwd_label', class:'fw-bold fst-italic'}});
		html += fwHTML.elem({tag:'input', attr:{id:'signin_pwd', type:'password', class:'form-control', placeholder:'Password'}});
		html = fwHTML.elem({tag:'div', attr:{class:'form-group text-start'}}, html);
		
		btn = fwHTML.elem({tag:'a',attr:{class:'btn btn-text-bg-dark-gz', onclick:"fwAPP.signin.exec('signin');"}}, 'Submit');
		html += fwHTML.elem({tag:'div', attr:{class:'text-center py-3'}}, btn);
		
		title = fwHTML.elem({tag:'div', attr:{id:'card_title', class:'text-bg-dark-gz p-2'}}, name);
		let bhtml = fwHTML.card({title:title, text:html, priv:true});

		html = fwHTML.elem({tag:'div', attr:{class:'card w-100 pb-5'}}, fwHTML.flipcard({id:'flipcard', fhtml:fhtml, bhtml:bhtml}));
		$('#myaction').html(html);
		$('#flipcard').flip({axis:'y', speed:300, autoSize:true, trigger:'manual'});
		switch(name){
			case 'SIGN-IN':
			tabFOCUS();
			/* falls through */
			default:
				let usr = gFn.usr();
				if (!isEmpty(usr)){
					$('#signin_email').val(usr.email);
					$('#signin_menu_email').val(usr.email);
					$('#signin_cb').prop('checked', true);
					$('#signin_menu_cb').prop('checked', true);
				}
				$('input[type="email"]').focus();		
				break;
		}
		
		html = fwHTML.elem({tag:'a',attr:{class:'btn btn-outline-primary mt-5','data-bs-toggle':'popover','data-trigger':'focus','data-bs-title':'SHARING OF YOUR PERSONAL INFORMATION',
			'data-bs-content':'We will not, in any circumstances, share your personal information with other individuals or organizations without your permission, including public organizations, corporations or individuals, except when applicable by law. We do not sell, communicate or divulge your information to any mailing lists. The only exception is if the law or a court order compels us to. We will share your information with government agencies if they need or request it.'}}, 'Our Personal Information Policy');
		
		$('#myaction').append(html);
		fwAPP.api.popoverENABLE();
		fwAPP.api.tooltipENABLE();
	};

	this.next = function(name){
console.error('exec', name);		
		if (isEmpty(getVALS(name).email)){
			shake('#myaction', true);
			return fwHTML.msg('danger', 'Sign-in', 'your email is required.', 10);
		}
		
		let title = $('#card_title').html();
//		title = 'NOSIGNIN';

		let html = '';
		switch(title){
			case 'NOSIGNIN':
				html = fwHTML.elem({tag:'h5'}, 'We are working to go live in Q1/2024, please check back.');
				html += fwHTML.elem({tag:'button', attr:{class:'btn btn-text-bg-dark-gz', onclick:"fwAPP.api.loadACTION('home');"}}, 'Ok');
				break;
				
			case 'VERIFY EMAIL':
				html = fwHTML.elem({tag:'h5'}, '&lt;'+ email +'&gt; will receive your verification code.');
				html += fwHTML.elem({tag:'button', attr:{class:'btn btn-text-bg-dark-gz', onclick:"fwAPP.signin.exec('verify');"}}, 'Send My Code');
				html += fwHTML.elem({tag:'button', attr:{class:'btn btn-secondary', onclick:"fwAPP.api.loadACTION('signin','verify');"}}, 'X');
				break;
				
			case 'NEW ACCOUNT':
				html = fwHTML.elem({tag:'h5'}, '&lt;'+ email +'&gt; will receive your account activation code.');
				html += fwHTML.elem({tag:'button', attr:{class:'btn btn-text-bg-dark-gz', onclick:"fwAPP.signin.exec('pwdchange');"}}, 'Send My Code');
				html += fwHTML.elem({tag:'button', attr:{class:'btn btn-secondary', onclick:"fwAPP.api.loadACTION('signin','new');"}}, 'X');
				break;
				
			case 'RESET PASSWORD':
				html = fwHTML.elem({tag:'h5'}, '&lt;'+ email +'&gt; will receive your account reset code.');
				html += fwHTML.elem({tag:'button', attr:{class:'btn btn-text-bg-dark-gz', onclick:"fwAPP.signin.exec('pwdchange');"}}, 'Send My Code');
				html += fwHTML.elem({tag:'button', attr:{class:'btn btn-secondary', onclick:"fwAPP.api.loadACTION('signin','pwdchange');"}}, 'X');
				break;
				
			case 'THIRD PARTY SIGN-IN':
				html = fwHTML.elem({tag:'h5'}, 'Feature is coming late 2024, please check back.');
				html += fwHTML.elem({tag:'button', attr:{class:'btn btn-text-bg-dark-gz', onclick:"fwAPP.api.loadACTION('home');"}}, 'Ok');
				break;
				
			default:
				$('#pwd_label').html('Enter Password for &lt;'+ email +'&gt;');
				$('input[type="password"]').focus();		
				break;
		}
		if (!isEmpty(html)) $('.back .card-text').html(fwHTML.elem({tag:'div', attr:{class:'text-center w-100'}}, html));
		$('#flipcard').flip(true);
	};

	function tabFOCUS(tid){
		$('[id^=tab]').removeClass('text-bg-dark-gz').addClass('text-bg-primary');
		if (tid>0) $('#tab'+ tid).removeClass('text-bg-primary').addClass('text-bg-dark-gz');
	}
	
	gFn.init();
	return gFn;
}