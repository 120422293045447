function htmlAPI(){
	const gFn = this;
	
	this.getmapURL = function(plc){
		let q = isEmpty(plc.address) ? 'loc:'+ plc.lat +'+'+ plc.lng : plc.address; 
		return 'http://maps.google.com/maps?z=12&t=m&q='+ q;
	};
	
	this.place = function(plc){
		if (isEmpty(plc)) return fwHTML.msg('danger','htmlAPI:place','data is missing.',15);
		if (!isValid(plc, 'address')) return fwHTML.msg('danger','htmlAPI:place.address','data is missing.',15);
		let name = isValid(plc, 'name') ? plc.name : plc.address;
		let html = fwHTML.icon({name:'location-dot', text:'dark-gz'}) + fmtStr.truncate(name);
		return fwHTML.elem({tag:'div', attr:{class:'text-truncate text-decoration-underline my-1', onclick:"fwAPP.api.openURL('"+ fwAPP.html.getmapURL(plc) + "');"}}, html);
	};
	
	this.act = function(evtid, a){
		if (isEmpty(a)) return '';
		let tab = '', pane = '';
		$.each(a, function(k, v){
			let paneid = 'pane'+ evtid +'-'+ v.actid;
			let active = (k==0) ? ' active' : '';
			let html = isValid(v.img, '0') ? fwHTML.elem({tag:'img', attr:{class:'img-fluid img-thumbnail me-3', width:'100', src:v.img[0]}}) : '';
			tab += fwHTML.elem({tag:'button', attr:{class:'nav-link border'+ active, 'data-bs-toggle':'tab', 'role':'tab', 'data-bs-target':'#'+ paneid}}, fmtStr.truncate(v.name));
			
			if (!isEmpty(html)) html = fwHTML.elem({tag:'div', attr:{class:'float-start'}}, html);
			html += fwHTML.elem({tag:'div', attr:{style:'min-height:70px;'}}, fmtStr.truncate(v.description, 240));
			
			if (!isEmpty(active)) active += ' show';
			pane += fwHTML.elem({tag:'div', attr:{id:paneid, class:'tab-pane text-start border-start border-end p-3'+ active, role:'tabpanel'}}, html);
		});
		return fwHTML.elem({tag:'div', attr:{class:'nav nav-tabs nav-fill', role:'tablist'}}, tab) + fwHTML.elem({tag:'div', attr:{class:'tab-content bg-white'}}, pane);
	};
	
	this.time = function(start, end){
		return start +' - '+ end;
	};

	this.pagenav = function(page){
		function pagenavGEN(data){
			let attr = {class:'breadcrumb-item'};
			if (page != 'usrdash') attr.onclick = "fwAPP.api.loadACTION('usrdash')";
			
			let html = fwHTML.elem({tag:'li', attr:attr}, 'Home');
			if (!Array.isArray(data)) return '';
			
			let len = data.length;
			$.each(data.reverse(), function(k, v){
				let myattr = {class:'breadcrumb-item text-light', onclick:"fwAPP.api.loadACTION('"+ v.page +"')"};
				let text = v.name;
				if (k == (len - 1)){
					myattr = {class:'breadcrumb-item text-light text-truncate active pe-2'};
					text = v.name +' ... '+ v.desc;
				}
				html += fwHTML.elem({tag:'li', attr:myattr}, '&gt;&nbsp;'+ text);
			});

			html = fwHTML.elem({tag:'ul', attr:{class:'breadcrumb fst-italic p-0 m-0'}}, html);
			return fwHTML.elem({tag:'nav', attr:{class:'badge ms-2 my-2'}}, html);
		}
		
		let data = [];
		let usr = fwAPP.api.get('usr');
		if (isEmpty(usr)){ // NOT signed-in
			switch(page){
				case 'signin': data.push({page:'home', name:'Sign-in', desc:'use advanced features'});
					break;
					
				case 'signauth': data.push({page:'home', name:'Authorize', desc:'update user related information'});
					break;
					
				default: return '';
			}
			return pagenavGEN(data);
		}
		
		switch(page){
			case 'sadash':	data.push({page:'sadash', name:'Admin Dash', desc:'manage admin functions'});
				break;
				
			case 'gigpassad': data.push({page:'gigpassad', name:'Create', desc:'design your targeted campaign'});
			/* falls through */
			case 'gigpassads':	data.push({page:'gigpassads', name:'Advertisement', desc:'manage your advertisement campaigns'});
				break;
				
			case 'venue': data.push({page:'venue', name:'Link', desc:'venue profile description'});
			/* falls through */
			case 'myvenues': data.push({page:'myvenues', name:'Venues', desc:'manage your venues'});
			/* falls through */
			case 'myevents': data.push({page:'myevents', name:'Events', desc:'manage your events'});
				break;
				
			case 'act': data.push({page:'act', name:'Create', desc:'act profile description'});
			/* falls through */
			case 'myacts': data.push({page:'myacts', name:'Acts', desc:'manage your acts'});
			/* falls through */
			case 'myevents': data.push({page:'myevents', name:'Events', desc:'manage your events'});
				break;
				
			case 'mypasses': data.push({page:'mypasses', name:'Passes', desc:'manage your passes'});
			/* falls through */
			case 'myevents': data.push({page:'myevents', name:'Events', desc:'manage your events'});
				break;
				
			case 'followup': data.push({page:'myevents', name:'Follow-up', desc:'handle requests'});
			/* falls through */
			case 'myevents': data.push({page:'myevents', name:'Events', desc:'manage your events'});
				break;
			
			case 'event': data.push({page:'event', name:'Create', desc:'new event'});
			/* falls through */
			case 'myevents': data.push({page:'myevents', name:'Events', desc:'manage your events'});
				break;
				
			case 'evalidate': 
				let encoded = fwAPP.api.get('encoded');
				let evt = fwAPP.api.get('evtlst', {encoded:encoded});
				data.push({page:'evalidate', name:fmtStr.truncate(evt.name, 35), desc:'validate'});
			/* falls through */
			case 'myevents': data.push({page:'myevents', name:'Events', desc:'manage your events'});
				break;
				
			case 'sendmail': data.push({page:'sendmail', name:'Monitor', desc:'monitor sendmail queue'});
			/* falls through */
			case 'myevents': data.push({page:'myevents', name:'Events', desc:'manage your events'});
				break;
				
			case 'mylists': data.push({page:'mylists', name:'My Lists', desc:'manage your contacts'});
			/* falls through */
			case 'myevents': data.push({page:'myevents', name:'Events', desc:'manage your events'});
				break;
				
			case 'mysettings': data.push({page:'mysettings', name:'Settings', desc:'manage profiles, login security, password, email and phone settings'});
				break;
				
			case 'usrdash': data.push({page:'myevents', name:'User Dashboard', desc:'navigate to desired feature'});
				break;
				
			case 'checkout':
			case 'cart': data.push({page:'home', name:'Cart', desc:'shopping cart'});
				break;
				
			case 'sale': data.push({page:'home', name:'Sale', desc:'thank you for your order'});
				break;
				
			case 'followup':
			case 'support': data.push({page:'home', name:'Support', desc:'contact platform support'});
				break;
				
			default: return '';
		}

		return pagenavGEN(data);
	};
	
	this.hintMSGS = function(hints){
		let html = '';
		if (isEmpty(hints)) return '';
		
		$.each(hints, function(k, v){
			let icon = 'circle-info';
			let tag = 'div';
			let attr = {class:'fw-medium fst-italic'};
			let text = v.text;
			if (!isEmpty(v.onclick)){
				icon = 'square-check';
				tag = 'a';
				text = fwHTML.icon('up-right-from-square') + v.text;
				attr = {class:'alert-link', onclick:v.onclick};
			}
			v.html = fwHTML.elem({tag:'h5', attr:{class:'alert-heading'}}, fwHTML.icon(icon) + v.title);
			let mylink = fwHTML.elem({tag:tag, attr:attr}, text);
			v.html += fwHTML.elem({tag:'div', attr:{class:'pre-wrap text-start mb-0'}}, mylink);
			html += fwHTML.alert('success', v.html);	
		});
		return fwHTML.elem({tag:'div', attr:{class:'text-start pt-3'}}, html);
	};
	
	function fwSEARCHinfo(){
		let html = '';
		let mylink = '';
		let hints = [];
		
		function btn(name, onclick){
			return fwHTML.elem({tag:'a', attr:{class:'btn btn-lg btn-primary', onclick:onclick}}, fwHTML.icon('square-plus') + name);
		}
		
		switch(fwAPP.api.get('frame')){
			case 'gigpassads':
				mylink = "fwAPP.api.loadACTION('gigpassad')";
				hints.push({title:'Enjoy our limited time 3 month <b>100% FREE</b> advertising offer.', text:'Promote your product or service while helping GIGZANTIC tune our platform to better support your needs.'});
				hints.push({title:'<b>PROMO CODE</b> / 2024 National Launch activation code: <b>GO2024</b>', text:'Create a new advertising campaign.', onclick:mylink});
				html = btn('New Ad', mylink);
				break;
				
			case 'myacts':
				mylink = "fwAPP.api.loadACTION('act')";
				hints.push({title:'Search, then link to existing Acts for your Events', text:'Search and link to an already existing Acts.'});
				hints.push({title:'Create, then use New Acts in your Events', text:'Designate Acts as private or public, then reuse them in events.', onclick:mylink});
				html = btn('New Act', mylink);
				break;
				
			case 'mylists':
				mylink = "fwAPP.usr.api.list.edit({global:'new'})";
				hints.push({title:'Lists help you manage your contacts', text:'Add, Import and then arrange all contacts in hierarchical order.'});
				html = btn('New List', mylink);
				break;
				
			case 'mypasses':
				hints.push({title:'Manage your Passes here', text:'Any passes linked to your profile will be available here.'});
				break;
				
			case 'myvenues':
				hints.push({title:'Search, then link to existing Venues for your Events', text:'Search and link to existing Venues.'});
				hints.push({title:'Create, New Venues for your Events', text:'Designate Venue as private or public, then reuse them in events.', onclick:"fwAPP.api.loadACTION('venue')"});
				html = btn('New Venue', "fwAPP.api.loadACTION('venue')");
				break;
		}
		return html + fwAPP.html.hintMSGS(hints);
	}
	
	function fwSEARCHpage(opt, callback){
		let myopt = isEmpty(opt.opt) ? {} : opt.opt;
		let frame = fwAPP.api.get('frame');
		let	searchtoggle = (frame=='myacts' || frame=='myvenues');
		
		let search = {
			fwS:fwSEARCH(searchtoggle ? {} : {mask:'filter', mode:'filter'}),
			start:function(){
				search.fwS.change = function(txt, ptxt, mode, id){
					fwLogger.error('change', txt, ptxt, mode, id);
					search.query();
				};
				
				if (searchtoggle){
					search.fwS.onmode = function(mode){
						let disable = (search.disable && mode == 'filter');
fwLogger.log('onmode', mode, search.disable, disable);
						$('#srch_input').prop('disabled', disable);
						$('#srch_srch').prop('disabled', disable);
					};
				}
				search.fwS.query();
				return this;
			},
			
			get:function(){
				let cache = fwAPP.api.get('cache');
				return cache.fwsearch;
			},
			
			cnt:function(){
				return countITM(search.get());
			},
				
			next:function(){
				let txt = search.fwS.get();
				search.disable = (search.cnt() == 0 && isEmpty(txt));
				
fwLogger.error('next', search.disable, searchtoggle);
				if (search.disable && !searchtoggle){
					$('#srch_input').prop('disabled', search.disable);
					$('#srch_srch').prop('disabled', search.disable);
				} else {
					if (search.cnt() == 0 && search.fwS.mode() != 'search') search.fwS.srchBTNmode('search');
				}
				
				let myclass = 'text-start m-3 p-0';
				if (search.disable){
					search.fwS.content(fwSEARCHinfo(), myclass);
					return this;
				}
				
				if (search.cnt() == 0) search.fwS.content('Nothing found', myclass);
				return this;
			},
			
			query:function(){
				myopt.text = search.fwS.get();			
				myopt.mode = search.fwS.mode();
				if (myopt.mode == 'search'){
					let location = fwAPP.api.myLOCATION();
					myopt.lat = location.lat;			
					myopt.lng = location.lng;			
				}
				
				fwAPP.api.srvREQ({fn:opt.fn, cmd:opt.cmd, opt:myopt, callback:function(data){
					let cache = fwAPP.api.get('cache');
					cache.fwsearch = data;
					fwAPP.api.set('cache', cache);
					
					if (typeof opt.elem !== 'function') opt.elem = function(){return '';};
					
					search.fwS.write(data, function(k, v){
						let myclass = 'col' + ((k % 2) ? ' bg-light' : ''); // alternate color each row
						return fwHTML.elem({tag:'div', attr:{class:myclass}}, opt.elem(k, v));
					});
					
					return search.next().done();
				}, err_callback:function(result){
					search.done();
				}});
				return this;
			},
			
			done:function(){
				search.fwS.endquery();
				if (typeof callback === 'function') callback();
				if (search.cnt() == 0) return this;
				return this;
			}
		};
		
		search.start();
	}
	
	this.sendmail = function(){
		fwSEARCHpage({fn:'get', cmd:'SENDMAIL', elem:function(k, v){
			let html = isValid(v, 'status') ? fwHTML.elem({tag:'div', attr:{class:'col fw-bold'}}, v.status +':'+ v.type) : '';
			if (isValid(v, 'email'))	html += fwHTML.elem({tag:'div', attr:{class:'col-6 fst-italic small text-truncate'}}, v.email);
			let icon = fwHTML.icon({name:'pen-to-square',pos:'center'});
			let action = fwHTML.elem({tag:'button', attr:{class:'btn btn-sm btn-outline-dark disabled', onclick:"fwAPP.usr.sendmailCMD('"+ v.id +"')"}}, icon);
			if (isValid(v, 'id')) html += fwHTML.elem({tag:'div', attr:{class:'col text-end p-0'}}, action);
			return fwHTML.elem({tag:'div', attr:{class:'row'}}, html);
		}});
	};
	
	function setMENU(id, actions){
		if (isEmpty(actions)) return;
		$(id).html(fwHTML.dropdown(actions, {name:'Action', myclass:'small', bg:'light'}));
		$(id).attr('fwapi_param', 'event');
	}
	
	this.hdrMENU = function(page){
		let item1 = [], item2 = [];
		switch(page){
			case 'home': item1.push({name:'Sign-in', icon:'user-plus', text:'primary', onclick:"fwAPP.api.loadACTION('signin')"}); break;
			case 'event': item1.push({name:'Events', icon:'calendar-check', text:'dark', onclick:"fwAPP.api.loadACTION('myevents')"}); break;
			case 'myacts': item1.push({name:'New Act', icon:'masks-theater', text:'primary', onclick:"fwAPP.api.loadACTION('act')"}); break;
			case 'gigpassad':
			case 'myevents': item1.push({name:'New Event', icon:'calendar-check', text:'primary', onclick:"fwAPP.api.loadACTION('event')"}); break;
			default: item1.push({name:'New Event', icon:'calendar-check', text:'dark', onclick:"fwAPP.api.loadACTION('event')"}); break;
		}
		
		switch(page){
			case 'home': item2.push({name:'Sign-up', icon:'users', text:'dark', onclick:"fwAPP.api.loadACTION('signin','new')"}); break;
			case 'myacts': item2.push({name:'New Tour', icon:'link', text:'primary', onclick:"fwAPP.usr.tour.notice()"}); break;
			case 'myvenues': item2.push({name:'New Venue', icon:'shop', text:'primary', onclick:"fwAPP.api.loadACTION('venue')"}); break;
			case 'mylists': item2.push({name:'New List', icon:'square-plus', text:'primary', onclick:"fwAPP.usr.api.list.edit({global:'new'})"}); break;
			case 'gigpassad': item2.push({name:'Ads', icon:'rectangle-ad', text:'dark', onclick:"fwAPP.api.loadACTION('gigpassads')"}); break;
			case 'gigpassads': item2.push({name:'New Ad',icon:'rectangle-ad', text:'primary', onclick:"fwAPP.api.loadACTION('gigpassad')"}); break;
			default: item2.push({name:'New Ad',icon:'rectangle-ad', text:'primary', onclick:"fwAPP.api.loadACTION('gigpassad')"}); break;
		}
		setMENU('#hdr_menu_item1', item1);
		setMENU('#hdr_menu_item2', item2);
	};
	
	this.myact_tour = function(v){
		
		function row(c1, c2){
			let html = fwHTML.elem({tag:'div', attr:{class:'col-4 fw-bold text-truncate p-0'}}, c1);
			html += fwHTML.elem({tag:'div', attr:{class:'col-8 fst-italic text-truncate d-none d-sm-block'}}, c2);
//			html += fwHTML.elem({tag:'div', attr:{class:'col text-end p-0'}}, c3);
			return fwHTML.elem({tag:'div', attr:{class:'row'}}, html);
		}
		
		function ischecked(v, evtid){
			let atur = fwAPP.usr.tour.actget(v);
			if (isEmpty(atur) || isEmpty(atur.evtid)) return false;
			
			let found = false;
			$.each(atur.evtid, function(i, id){
				if (evtid == id) found = true;
			});
			return found;
		}
		
		let html = '';
		if (isValid(v, 'evtlst')){
			let turid = fwAPP.usr.tour.turget(v);
			html = fwHTML.elem({tag:'div', attr:{class:'btn-group'}}, gFn.myact_menu(v, turid, 'turid'));
			let cbhtml = '';
			$.each(v.evtlst, function(i, evt){
				let evtdt = fwAPP.api.dtDUR2sdtANDedt(evt.start_dt, evt.duration);
				let attr = {id:evt.id, name:'actid'+ v.id, disabled:'disabled', type:'checkbox', class:'form-check-input m-1'};
				if (ischecked(v, evt.id)) attr.checked = 'checked';
				let cb = fwHTML.elem({tag:'input', attr:attr});
				cbhtml += row(cb + evtdt.s.str_dt, evt.name);
			});
			let elem = turid>0 ? {tag:'div', attr:{id:'turid'+ turid}} : {tag:'div'};
			html += fwHTML.elem(elem, cbhtml);				
		}
		return html;
	};
	
	this.myact_menu = function(v, turid, update){
		if (isEmpty(turid)) turid = 0;
		if (isEmpty(update)) update = 'init';
		
		function fmtName(s) {
			s = String(s);
			return s.charAt(0).toUpperCase() + s.slice(1);
		}
		
		let cnt = {turlst:countITM(v.turlst), evtlst:countITM(v.evtlst)};
		let cmd = fwAPP.usr.tour.cmd['actid' + v.id];
		
		let actions = [];
		
		if ((isEmpty(cmd) && cnt.evtlst>1) || cmd=='new'){
			actions.push({name:(cnt.turlst>0?'New':'New Tour'),icon:'link',text:'dark',onclick:'fwAPP.usr.tour.creat('+ v.id +')'});
			if (cnt.turlst>0) actions.push({type:'dropdown-divider'});
		}
		if (isEmpty(cmd) && cnt.turlst>0){
			actions.push({name:'Edit',icon:'pen-to-square',text:'primary',onclick:'fwAPP.usr.tour.edit('+ v.id +')'});
		}
		if (!isEmpty(cmd)){
			if (cmd != 'new') actions.push({id:'save'+ v.id,name:'Update / Save',icon:'circle-check',text:'dark-gz',onclick:'fwAPP.usr.tour.save('+ v.id +')'});
			actions.push({name:'Cancel',icon:'xmark',text:'danger',onclick:'fwAPP.usr.tour.edit('+ v.id +', true)'});
			actions.push({type:'dropdown-divider'});
			actions.push({id:'checkall'+ v.id, name:'Select All', icon:'square-check', text:'dark', onclick:"fwAPP.usr.tour.checkall({name:'actid', id:"+ v.id +", check:true})"});
		}
		
		let html = fwHTML.dropdown(actions, {name:'Tour', bg:'dark'});
		if (!update && cnt.turlst<1) return html;
		
		actions = [];
		if (cnt.turlst>0){
			let atur = fwAPP.usr.tour.actget(v);
			
			if (cnt.turlst>1){
				actions.push({name:'View',icon:'bars',text:'dark',onclick:"fwAPP.api.loadACTION('tour','"+ turid +"')"});
				actions.push({type:'dropdown-divider'});
			} else {
				actions.push({name:fmtName(atur.name),icon:'bars',text:'dark',onclick:"fwAPP.api.loadACTION('tour','"+ turid +"')"});
			}
			
			if (!isEmpty(v.turlst)){
				$.each(v.turlst, function(i, tur){
					if (atur.id != tur.id) actions.push({name:fmtName(tur.name),icon:'link',text:'dark-gz',onclick:'fwAPP.usr.tour.set('+ v.id +','+ tur.id +')'});
				});
			}
			
			let name = fwHTML.icon('link')+fmtName(atur.name);
			let tourbtn = fwHTML.dropdown(actions, {name:name, bg:'text-bg-dark-gz', prop:'attached'});
			if (!isEmpty(update) && update.indexOf('tourbtn')>=0) return tourbtn;
			
			html += fwHTML.elem({tag:'div', attr:{id:'tourbtn', class:'btn-group'}}, tourbtn);
		}
		return html; 
	};
	
	this.myacts = function(){
		fwSEARCHpage({fn:'get', cmd:'FWS_ACT', elem:function(k, v){
			let turid = fwAPP.usr.tour.actset(v);
			
			let html = isValid(v, 'name') ? fwHTML.elem({tag:'div', attr:{class:'col fw-bold text-truncate p-0'}}, v.name) : '';
			if (isValid(v, 'description')) html += fwHTML.elem({tag:'div', attr:{class:'col-8 fst-italic text-truncate d-none d-sm-block'}}, fmtStr.truncate(v.description));
			
			let icon = fwHTML.icon({name:'pen-to-square',pos:'center'});
			let style = 'outline-dark';
			let action = "fwAPP.api.loadACTION('act',"+ v.id +")";
			if (!v.waccess){
				icon = fwHTML.icon({name:'link', pos:'center'});
				style = v.usr2actlnk ? 'dark-gz' : 'secondary';
				action = 'fwAPP.usr.act.usr2actlnk('+ v.id +')';
			}
			
			action = fwHTML.elem({tag:'button', attr:{class:'btn btn-sm btn-'+ style, onclick:action}}, icon);
			if (isValid(v, 'id')) html += fwHTML.elem({tag:'div', attr:{class:'col text-end p-0'}}, action);

			html += fwHTML.elem({tag:'div', attr:{id:'grp-actid'+ v.id}}, gFn.myact_tour(v, turid));
			return fwHTML.elem({tag:'div', attr:{class:'row'}}, html);
		}});
	};
	
	this.myvenues = function(){
		fwSEARCHpage({fn:'get', cmd:'FWS_PLC', elem:function(k, v){
			let html = isValid(v, 'name') ? fwHTML.elem({tag:'div', attr:{class:'col fw-bold text-truncate p-0'}}, v.name) : '';
			if (isValid(v, 'address')) html += fwHTML.elem({tag:'div', attr:{class:'col-8 fst-italic text-truncate d-none d-sm-block'}}, fmtStr.truncate(v.address));

			let icon = fwHTML.icon({name:'pen-to-square',pos:'center'});
			let style = 'outline-dark';
			let action = "fwAPP.api.loadACTION('venue',"+ v.id +")";
			if (!v.waccess){
				icon = fwHTML.icon({name:'link', pos:'center'});
				style = v.usr2plclnk ? 'dark-gz' : 'secondary';
				action = 'fwAPP.usr.venue.usr2plclnk('+ v.id +')';
			}

			action = fwHTML.elem({tag:'button', attr:{class:'btn btn-sm btn-'+ style, onclick:action}}, icon);
			if (isValid(v, 'id')) html += fwHTML.elem({tag:'div', attr:{class:'col text-end p-0'}}, action);
			return fwHTML.elem({tag:'div', attr:{class:'row'}}, html);
		}});
	};
	
	this.sadash = function(advid){
		let title = 'Approve Ad';
		function advEDT(opt, callback){
			let sysval = fwAPP.api.get('sysval');
			opt.status = idnameFIND(sysval.optype, opt.status).id;
			fwAPP.api.srvREQ({fn:'GIGPASSset', cmd:'ADV_EDT', opt:opt, callback:function(data){
				if (typeof callback === 'function') callback(data);
			}, err_callback:function(result){
			}});
		}
		
		if (!isEmpty(advid)){
			fwLogger.log('sadash', advid);
			let footer = fwHTML.elem({tag:'button', attr:{'type':'button', 'class':'btn btn-primary', 'data-dismiss':'modal'}}, fwHTML.icon('square-check') +'Accept');
			footer += fwHTML.elem({tag:'button',attr:{'type':'button','class':'btn btn-danger','data-dismiss':'modal'}}, fwHTML.icon('square-xmark') +'Deny');
			let html = 'Accept or provide denial reason below.';
			html += fwHTML.elem({tag:'textarea', attr:{id:'reason', 'class':'form-control'}});
			
			let unlock = true;
			fwAPP.api.myMODAL(fwHTML.title(title), html, footer, function(){
				autosize($('textarea'));
				advEDT({advid:advid, status:'LOCKED'}); // optype.locked
			}, function(event){
				fwLogger.error('test', event);
				unlock = false;
				if (fwAPP.api.eventis(event, 'btn-primary')){
					advEDT({advid:advid, status:'APPROVED'}, function(status){ // optype.approved
						fwHTML.msg((status=='SUCCESS')?'success':'danger', title, "Approved : "+ advid, 15);
					});
				} else {
					let note = $('#reason').val();
					advEDT({advid:advid, status:'REJECTED', note:note}, function(status){ // optype.rejected
						fwHTML.msg((status=='SUCCESS')?'success':'danger', title, "Rejected : "+ advid, 15);
					});
				}
				fwAPP.mymodal.hide();
				fwAPP.api.loadACTION('sadash',{force:true});
			}, function(event){
				if (unlock) advEDT({advid:advid, status:'SUBMITTED'}); // optype.submitted
			});
			return;
		}
		
		function buildROW(v){
			let sysval = fwAPP.api.get('sysval');
			let html = isValid(v, 'name') ? fwHTML.elem({tag:'div', attr:{class:'fw-bold text-truncate p-0'}}, '['+ idnameFIND(sysval.optype,v.status).name +'] '+ v.name) : '';
			$.each([{w:300,h:100},{w:300,h:200},{w:250,h:250},{w:75,h:300}], function(i, ratio){
				let fname = imgMGRapi.useimg(v.img, {width:ratio.w, height:ratio.h}, 'noimg');
				fwLogger.error(fname, ratio, v);
//				html += (fname != 'noimg') ? fwHTML.elem({tag:'img', attr:{width:ratio.w/2, height:ratio.h/2, src:fname}}) : '';
				let img = (fname != 'noimg') ? fwHTML.elem({tag:'img', attr:{width:ratio.w/2, height:ratio.h/2, src:fname}}) : '';
				html += fwHTML.elem({tag:'div', attr:{class:'col p-0'}}, img);
			});
			html += fwHTML.elem({tag:'textarea', attr:{class:'p-0 text-wrap', readonly:'readonly'}}, v.text);
			html += fwHTML.elem({tag:'div', attr:{class:'p-0'}}, v.url);
			return fwHTML.elem({tag:'div', attr:{class:'row d-flex justify-content-between'}}, html);
		}
		
		fwSEARCHpage({fn:'GIGPASSget', cmd:'FWS_ADM', elem:function(k, v){
//			let html = fwHTML.elem({tag:'div', attr:{class:'col-11 fw-bold text-truncate p-0'}}, buildROW(v));
			let html = '';
			let icon = fwHTML.icon({name:'pen-to-square', pos:'center'});
			let action = fwHTML.elem({tag:'button', attr:{class:'btn btn-sm btn-outline-dark', onclick:"fwAPP.html.sadash("+ v.id +")"}}, icon);
			if (isValid(v, 'id')){
				html += fwHTML.elem({tag:'div', attr:{class:'col-1 text-end p-0 pe-2'}}, action);
				html += fwHTML.elem({tag:'div', attr:{class:'col fw-bold text-truncate p-0'}}, buildROW(v));
			}
			return fwHTML.elem({tag:'div', attr:{class:'row border'}}, html);
		}});
	};

	this.usrdash = function(){
		let cnt = 0;
		let color = '#00ece9';
		
		function usrcard(opt){
			if (cnt>=1){ 
				cnt = 0;
				color = (color=='#e6ec04')?'#00ece9':'#e6ec04';
			} else cnt++;
			
			opt.color = color;
			
			let img = fwHTML.elem({tag:'i', attr:{class:'fa-solid fa-square fa-stack-2x'}});
			img += fwHTML.elem({tag:'i', attr:{class:'fa-solid fa-'+ opt.icon +' fa-stack-1x fa-inverse'}});
			if (!isEmpty(opt.id)){ // count placement
				let attr = {class:'position-absolute bottom-0 start-0 badge fs-6 fst-italic p-2 text-bg-dark rounded-3'};
				attr.id = 'card-'+ opt.id; 
				img += fwHTML.elem({tag:'span', attr:attr}, '0');
			}
			img = fwHTML.elem({tag:'span', attr:{class:'fa-stack fa-3x', style:'--fa-inverse:'+ opt.color +';'}}, img);
			
			img = fwHTML.elem({tag:'div', attr:{class:'col-md-4', style:'height:100px;'}}, img);

			let html = fwHTML.elem({tag:'h5', attr:{class:'card-title m-0'}}, opt.title);
			html += fwHTML.elem({tag:'p', attr:{class:'card-text d-none d-md-block'}}, opt.text);
			html = fwHTML.elem({tag:'div', attr:{class:'card-body d-none d-sm-block pb-0'}}, html);
			html = fwHTML.elem({tag:'div', attr:{class:'col-md-8'}}, html);
			html = fwHTML.elem({tag:'div', attr:{class:'row'}}, img + html);
			html = fwHTML.elem({tag:'div', attr:{class:'card rounded-1', onclick:opt.onclick}}, html);
			
			return fwHTML.elem({tag:'div', attr:{class:'col py-1', style:'max-width:330px;'}}, html);
		}
		
		let tile = [];
		tile.push({id:'evtcnt', title:'Event', text:'Create and manage events.', icon:'calendar', onclick:"fwAPP.api.loadACTION('myevents')"});
		tile.push({id:'pascnt', title:'Passes', text:'Event passes/tickets.', icon:'ticket', onclick:"fwAPP.api.loadACTION('mypasses')"});
		tile.push({id:'plccnt', title:'Venues', text:'Manage event places.', icon:'shop', onclick:"fwAPP.api.loadACTION('myvenues')"});
		tile.push({id:'actcnt', title:'Acts', text:'Define and use event performers.', icon:'masks-theater', onclick:"fwAPP.api.loadACTION('myacts')"});
		tile.push({id:'lstcnt', title:'Contact Lists', text:'Handle event invitations.', icon:'bars-staggered', onclick:"fwAPP.api.loadACTION('mylists')"});
		tile.push({id:'advcnt', title:'Advertising', text:'Execute your campaign presence.', icon:'rectangle-ad', onclick:"fwAPP.api.loadACTION('gigpassads')"});
		tile.push({title:'Account Settings', text:'User settings, security and profiles.', icon:'user-shield', onclick:"fwAPP.api.loadACTION('mysettings')"});
		
		let html = '';
		$.each(tile, function(k, v){
			html += usrcard(v);
		});
		return fwHTML.elem({tag:'div', attr:{class:'row row-cols-1 row-cols-sm-2 row-cols-lg-3 justify-content-center'}}, html);
	};
	
	this.checkPUBLIC = function(v){
		let name  = (isValid(v,'public') && parseInt(v.public)) ? 'public' : 'private';
		$('#'+ name).prop('checked', true);
	};

	function setEVENTinit(evt){
		let newevt = isEmpty(evt);
		if (newevt) evt = {start_dt:(Date.today().next().friday().toString('M/d/yyyy') +' 18:30'), duration:4};

		let start_dt = Date.today().next().friday().toString('M/d/yyyy') +' 18:30';
		
		let evtdt = fwAPP.api.dtDUR2sdtANDedt(evt.start_dt, evt.duration);
		fwLogger.log('event', evt, evtdt);

		fwAPP.usr.updateEVENTdisplay(evt.start_dt, evt.duration);
		gFn.checkPUBLIC(evt);
		
		let start_date = Date.parse(evtdt.s.str).toString('yyyy-MM-dd');
		let start_time = Date.parse(evtdt.s.str).toString('HH:mm');
		
		let start_date_min = Date.today().toString('yyyy-MM-dd');
		let start_date_max = Date.today().add(12).months().toString('yyyy-MM-dd');
		
		$('#start_date').attr('min',start_date_min).attr('max',start_date_max).val(start_date);
		$('#start_time').val(start_time);
		$('#hours').val(parseInt(evtdt.d.h));
		$('#minutes').val(parseInt(evtdt.d.m));
	}
	
	this.pagePASSadd = function(pass){
		let sysval = fwAPP.api.get('sysval');
		if (isEmpty(pass)) pass = {type:'1', qty:'50', amt:'30.00'};

		if (pass.required == true){
			$('#pass_container').append(fwAPP.html.evtpas(pass));
		} else {
			$('#pass_container').append(fwAPP.html.evtpas(pass));
			let cnt = 0;
			$('div[name="page_pass"]').each(function(i, e){ cnt += 1; });
			if (cnt>=3) return $('#pass_add').prop('disabled', true);
		}
	};
	
	this.pagePASSrmv = function(o){
		$(o).closest('div[name="page_pass"]').remove();
		$('#pass_add').prop('disabled', false);
	};
	
	this.event = function(){
fwLogger.error('EVENT');

		function getEVT(evtid, callback){ 
			let evt = fwAPP.api.get('evtlst',{evtid:evtid});
			if (!isEmpty(evt) && typeof callback === 'function') return callback(evt);

			fwAPP.api.srvREQ({fn:'GIGPASSget', cmd:'EVT_LST', opt:{evtid:evtid}, callback:function(data){
				if (typeof callback === 'function') return callback(data[0]);
			}, err_callback:function(result){
			}});
		}

		fwAPP.usr.pageCACHE(function(cache){
			let id = fwAPP.api.get('param_id');
			fwAPP.api.set('var_page', {evtid:id});
			
			fwLogger.log('pageCACHE', cache.actlst);
	
			$('#act_add').off('click').on('click', function(event){
				fwLogger.log('act_add', cache.actlst);
				$('#actid').html(setSELECT(cache.actlst));
			});
	
			if (id>0){
				getEVT(id, function(evt){
					setEVENTinit(evt);
					
					let plclst = []; // convert index ids to geoid to be used
					let plcid = 0; 
					$.each(cache.plclst, function(k, v){
						plclst.push({id:v.geoid,name:v.name});
						if (v.geoid == evt.place.geoid) plcid = v.geoid;
					});
					$('#geoid').html(setSELECT(plclst, plcid));
					
					$('#name').val(evt.name);
					$('#description').html(evt.description);
					
					if (!isEmpty(evt.act)){
						$.each(evt.act, function(k, v){
							fwAPP.usr.act.pageADD(v.actid);
						});
					}
					
					if (!isEmpty(evt.pass)){
						$.each(evt.pass, function(k, v){
							v.required = (k==0); 
							gFn.pagePASSadd(v);
						});
					}
				});
			} else {
				if (countITM(cache.plclst) == 0){
					fwHTML.msg('info', 'New Event', 'Please link to existing or create a new venue address before creating a New Event.', 30);
					return fwAPP.api.loadACTION('myvenues');
				}
				setEVENTinit();
				$('#geoid').html(setSELECT(cache.plclst));
				if (countITM(cache.actlst) == 0) $('#add_act').attr('onclick', "fwAPP.api.loadACTION('myacts')");
				else $('#actid').html(setSELECT(cache.actlst));
//				$('#actid').addClass('disabled');
				gFn.pagePASSadd({type:'1', qty:'50', amt:'30.00', required:true});
			}
			autosize.update($('textarea'));
		});
	};
	
	function cmnPAGEimg(dest, data){
		let curpick = {};
		
		function updatePLANvalue(){
			
			const pricing = {
				duration:['','9','2.5','1'],
				audience:['','1','1.2','1.3','1.1'],
				plan:['','10','3','1'],
				target:['','1,000','1','1','1'],
				pick:['1','1','500','100','25']
			};
			let duration = $('#duration').val();
			
			let audience = $('#audience').val();
			let plan = $('#plan').val();
			let pick = $('#pick').val();
			let target = $('#target').val();
			
			fwLogger.error('updatePLANvalue', target, pick, curpick);
			if (!isEmpty(pick)) curpick[target] = pick;
			
			let geo = currency(pricing.target[target]);
			if (target == 2 || target == 3 || target == 4){
				geo = currency(pricing.pick[target]).multiply(countITM(pick));
				geo = currency(pricing.target[target]).multiply(geo);
			}
			
			let amt = currency(geo).multiply(pricing.plan[plan]).multiply(pricing.duration[duration]).multiply(pricing.audience[audience]).format({symbol:'', separator:''});
			fwAPP.api.addset('var_page', {amt:amt});
			amt = currency(amt).format();
			
			let html = fwHTML.elem({tag:'span', attr:{class:'position-absolute top-50 start-100 translate-middle badge rounded-pill text-bg-dark'}}, 'Total Value');
			html = fwHTML.elem({tag:'div', attr:{class:'position-relative text-bg-light-gz', style:'max-width:230px;'}}, html+amt);
			$('#planvalue').html(html);
		}
		
		let mysel_option = {};
		function myselectizeSELECT(id, data, create){
			create = (!isEmpty(create) && create);
			if (isEmpty(data)) data = [];
			const type_opt = { create:create, closeAfterSelect:true,
				plugins: ['remove_button', 'restore_on_backspace', 'clear_button'],
				valueField: 'id',
				labelField: 'name',
				searchField: ['name'],
				options: nameBEAUTIFY(data, 'name')
			};
			return myselectize(id, type_opt);
		}
		

		let last_target = 0;
		function targetOPTIONS(){
			if (!isValid(data, 'pick')) data.pick = {};
			fwLogger.error('targetOPTIONS', data.pick);
			let setdata = (last_target == 0);
			
			function pickSETval(target, v){
				fwLogger.error('pickSETval', v, setdata, data.pick);
				if (setdata && !isEmpty(data.pick)) v = String(data.pick).split(','); // use saved as default
				if (isEmpty(curpick[target])) curpick[target] = v;
				mysel_option.api().setValue(curpick[target]);
				
				$('#pick').off('change').on('change', function(event){
					updatePLANvalue();
				});
			}
			
			let target = $('#target').val();
			
			fwLogger.error('targetOPTIONS', last_target, target);
			if (!setdata && (last_target == target)) return; // when not initially loading data

			if (!isEmpty(mysel_option))	mysel_option = mysel_option.destroy();
			
			$('#pick').removeAttr('multiple');
			let location = fwAPP.api.myLOCATION();

			switch (target){
				case '2':
					$('#pick').prop('disabled', false);
					$('#pick').attr('multiple', 'multiple');
					$('#pick').html(setSELECT(fwAPP.api.get('sysval').usregn));
					mysel_option = myselectizeSELECT('pick');
					pickSETval(target, '3');
					break;
					
				case '3':
					$('#pick').prop('disabled', false);
					$('#pick').attr('multiple', 'multiple');
					$('#pick').html(setSELECTstate(location.state));
					mysel_option = myselectizeSELECT('pick');
					pickSETval(target, location.state);
					break;
					
				case '4':
					$('#pick').prop('disabled', false);
					$('#pick').attr('multiple', 'multiple');
					
					let currdata = [];
					let pick = isEmpty(data.pick) ? {} : String(data.pick).split(',');
					pick = setdata ? pick : curpick[target];
					if (!isEmpty(pick)){
						$.each(pick, function(k, v){
							currdata.push({id:v, name:v});
						});
					}
					
					if (isEmpty(currdata)){
						curpick[target] = location.zip;
						currdata = [{id:location.zip, name:location.zip}];
					}
					
					$('#pick').html(setSELECT(currdata));
					mysel_option = myselectizeSELECT('pick', currdata, true);
					pickSETval(target, location.zip);
					break;
					
				default: // All
					if (!isEmpty(mysel_option)) mysel_option = mysel_option.destroy(); 
					$('#pick').html(setSELECT([{id:0, name:'*'}]));
					$('#pick').prop('disabled', true);
					break;
			}
			last_target = target;
		}
		
		$('#tag').html(setSELECT(fwAPP.api.get('sysval').plctype));
		myselectizeSELECT('tag');
		
		$('.form-select').off('change').on('change', function(e){
			e.preventDefault();
			e.stopPropagation();
			
			let id = $(this).prop('id');
			fwLogger.error('DO', id, $('#'+ id).val());
			
			switch(id){
				case 'imgsize':
					imgMGRapi.setImg(dest);
					break;
			}
			targetOPTIONS();
			updatePLANvalue();
		});
		
		let advid = fwAPP.api.get('param_id');
		if (!isValidID(advid)){ // new ad values defaults
			$('#duration').val(2); // Quarter
			$('#target').val(4); // Zip Codes
			$('#plan').val(2); // Balanced
		}
		fwAPP.usr.setFLDgroups('gigpassad', data);
		targetOPTIONS();
		updatePLANvalue();
		imgMGRapi.setImg(dest); 
	}
	
	this.mysettings = function(){
		let usr = fwAPP.api.get('usr');
		let viewport = { width: 100, height: 100, type: 'circle' };
		let fname = (usr.img) ? imgMGRapi.bldpath('/cmn/img/', 'usr', usr.id, '', viewport) : '';
		
		let usrimg = imgMGR('#usrimg', {
			mouseWheelZoom: false,
			enableOrientation: true,
			viewport: viewport,
			boundary: { width: 150, height: 150 },
			orientation: 1
		});
		
		if (!isEmpty(fname)) usrimg.croppie('bind',{url:fname, zoom:0});
		
		$('#imgMGRupload').off('change').on('change', function(event){
			usrimg.readFile(this);
		});
		
		$('#imgMGRrotate').off('click').on('click', function(event){
			usrimg.rotate();
		});
		
		$('#imgMGRimg').off('click').on('click', function(event){
			$('#imgMGRget').click();
		});
		
		$('#imgMGRsave').off('click').on('click', function(event){
			fname = imgMGRapi.bldpath('/cmn/img/', 'usr', usr.id);
			usrimg.upload('usr', fname, function(result){
				fname += '?t='+ (new Date()).getMilliseconds();
				imgMGRapi.loadimg('#profile', fname);
				fwHTML.msg('success','User Image','Updated user image.',15);
				usrimg.croppie('bind',{
					url: fname,
					zoom: 0
				}).then(function(){
					usrimg.disable();
				});
			});
		});
		
		let usrapp = fwAPP.api.get('usrapp');
		
		let nav = fwHTML.elem({tag:'a', attr:{class:'nav-link', onclick:"fwAPP.usr.event.access();"}}, 'Access to New Events');
		nav = fwHTML.elem({tag:'li', attr:{class:'nav-item'}}, nav);
		
		let html = fwHTML.elem({tag:'ul', attr:{class:'nav text-bg-white justify-content-end'}}, nav);
		html += fwHTML.input_floating({id:'email', name:'Email', value:usr.email, disabled:true});
		html += fwHTML.input_floating({id:'name', name:'Display Name', value:usr.name, validation:'Display name is required'});
		html += fwHTML.input_floating({id:'fname', name:'First Name', value:usrapp.fname});
		html += fwHTML.input_floating({id:'mname', name:'Middle Name', value:usrapp.mname});
		html += fwHTML.input_floating({id:'lname', name:'Last Name', value:usrapp.lname});
		html += fwHTML.checkboxSW({id:'legal', text:'I certify that I am 18 years of age'});
		html += fwHTML.elem({tag:'button', attr:{type:'submit', class:'btn btn-text-bg-dark-gz mt-2 btn-sm disabled'}}, 'Save');
		
		$('#page_root').html(html);
		fwHTML.checkboxSWevent('legal', function(opt){
			let result = (opt.action == 'on') ? $('button[type="submit"]').removeClass('disabled') : $('button[type="submit"]').addClass('disabled');
		});
		if (!isEmpty(usrapp.legal) && usrapp.legal) $('#legal').click();
	};
	
	this.gigpassad = function(){
		let id = fwAPP.api.get('param_id');
		
		function actionBTN(adv){
			let actions = [];
			if (adv.status == 'INITIALIZE'){
				actions.push({name:'Submit for Review', icon:'calendar-plus', text:'dark-gz', onclick:'fwAPP.usr.adv.review()'});
			} else if (adv.status == 'APPROVED'){
				actions.push({name:'Activate', icon:'calendar-plus', text:'dark-gz', onclick:'fwAPP.cart.sleadd(433,'+ id +')'});
				actions.push({name:'Edit', icon:'calendar-plus', text:'primary', onclick:'fwAPP.usr.adv.edit('+ id +')'});
			} else if (adv.status == 'SUBMITTED'){
				actions.push({name:'Edit', icon:'calendar-plus', text:'primary', onclick:'fwAPP.usr.adv.edit('+ id +')'});
			}
			
			if (adv.status == 'INITIALIZE' || adv.status == 'REJECTED'){
				actions.push({name:'Preview', icon:'eye', text:'primary', onclick:'fwAPP.usr.adv.preview()'});
				actions.push({type:'dropdown-divider'});
				actions.push({name:'Load Image', icon:'images', text:'primary', onclick:"fwAPP.usr.getImage('adv')"});
			}
			
			let menu = fwHTML.elem({tag:'div', attr:{class:'col m-0 my-2 p-0 text-start'}}, fwHTML.dropdown(actions, {name:'Action', bg:'dark'}));
			menu += fwHTML.elem({tag:'div', attr:{id:'planvalue', class:'col m-0 my-2 p-0 fw-bold'}}, '$');
			return menu;
		}
		
		let html = fwHTML.input_textarea({id:'text', label:'Promotion text', name:'Text'});
		let txt = '2024 National Launch activation code: GO2024';
		html += fwHTML.elem({tag:'div', attr:{id:'review', class:'text-start text-bg-danger p-0'}});
		html += fwHTML.elem({tag:'div', attr:{class:'fw-bold text-start text-bg-dark-gz p-2'}}, txt);
		txt = 'Enjoy our limited time offer of 3 month 100% FREE advertising. Promote your product or service while helping GIGZANTIC tune our platform to better support your needs.';
		txt = fwHTML.elem({tag:'p', attr:{class:'text-start p-2'}}, txt);
		html += fwHTML.alert('success', txt);
		let chtml = fwHTML.elem({tag:'div', attr:{class:'col p-0'}}, html);
		html = fwHTML.elem({tag:'div', attr:{class:'col p-0', style:'max-width:200px;'}}, fwHTML.imagesizer('adv'));
		html += chtml;
		$('#imagebox').append(html);
		
		function reviewNOTES(adv){
			let sysval = fwAPP.api.get('sysval');
			let html = idnameFIND(sysval.optype, adv.status).name;
			html = fwHTML.elem({tag:'span', attr:{class:'badge text-start text-bg-dark text-truncate'}}, html.toUpperCase() + ' ... Please Review and Re-Submit');
			if (isValid(adv,'review') && countITM(adv.review)>0){
				$.each(adv.review, function(k, v){
					html += fwHTML.elem({tag:'div', attr:{class:'p-0'}}, v.note);
				});
			}
			return html;
		}
		
		fwAPP.api.srvREQ({fn:'GIGPASSget', cmd:'FWS_ADV', opt:{id:id}, callback:function(data){
			if (isEmpty(data.status)) data.status = 'INITIALIZE';
			
			if (data.status == 'INITIALIZE' || data.status == 'REJECTED'){
				$('#actionbtn').append(actionBTN(data));
				cmnPAGEimg('adv', data);
			} else {
				fwAPP.usr.adv.preview(id, data.status, function(adv){
					let html = actionBTN(data); 
					html = fwHTML.elem({tag:'div', attr:{class:'row px-3'}}, html);
					$('#fwapi-frame').prepend(html);
					html = fwHTML.elem({tag:'span', attr:{class:'badge rounded-pill text-bg-dark ms-3'}}, 'Total Value');
					$('#planvalue').html(currency(adv.amt).format() + html);
				});				
			}
			if (data.status == 2) $('#review').html(reviewNOTES(data));
		}, err_callback:function(result){
		}});
	};
	
	this.gigpassads = function(lst){
		fwSEARCHpage({fn:'GIGPASSget', cmd:'FWS_ADV', elem:function(k, v){
			let html = isValid(v, 'name') ? fwHTML.elem({tag:'div', attr:{class:'col-10 fw-bold'}}, v.name) : '';
			html += isValid(v, 'email') ? fwHTML.elem({tag:'div', attr:{class:'col'}}, v.email) : '';
			let icon = fwHTML.icon({name:'pen-to-square', pos:'center'});
			
			let attn = 'btn-outline-dark';
			if (v.status == 'REJECTED') attn = 'btn-outline-danger';
			else if (v.status == 'SUBMITTED') attn = 'btn-outline-warning';
			
			let action = fwHTML.elem({tag:'button', attr:{class:'btn btn-sm '+ attn, onclick:"fwAPP.api.loadACTION('gigpassad',"+ v.id +")"}}, icon);
			html += isValid(v, 'id') ? fwHTML.elem({tag:'div', attr:{class:'col text-end p-0'}}, action) : '';
			return fwHTML.elem({tag:'div', attr:{class:'row'}}, html);
		}});
	};
	
	this.fmtLSTname = function(v, menu){
		menu = (!isEmpty(menu) && menu);
		
		function email_menu(){
			let attr = {class:'nav-link dropdown-toggle link-dark'};
			/* jshint -W069 */
			attr['role'] = 'button';
			attr['data-bs-toggle'] = 'dropdown';
			attr['data-bs-auto-close'] = 'true';
			attr['aria-expanded'] = 'false';
			/* jshint +W069 */
			if (!menu) attr = {class:'px-2 text-truncate'};
			if (v.email != v.name) attr.class += ' text-capitalize';
			if (!menu) return fwHTML.elem({tag:'div', attr:attr}, fwHTML.icon('envelope') + name);

			html = fwHTML.elem({tag:'a', attr:attr}, fwHTML.icon('envelope') + v.name);
			
			let actions = [];
			actions.push({name:'Copy',icon:'copy',text:'dark',onclick:"fwAPP.usr.api.list.copy({idx:"+ v.idx +"})"});
			actions.push({type:'dropdown-divider'});
			actions.push({name:'Edit',icon:'pen-to-square',text:'primary',onclick:"fwAPP.usr.api.list.contact({id:"+ v.id +",email:'"+ v.email +"',name:'"+ v.name +"'})"});
			actions.push({name:'Unlink', icon:'square-minus', text:'primary', onclick:"fwAPP.usr.api.list.rmvitm({id:"+ v.idx +"})"});
			
			html += fwHTML.elem({tag:'ul', attr:{class:'dropdown-menu rounded-1 text-small'}}, fwHTML.dropdown_menu_li(actions));

			return fwHTML.elem({tag:'div', attr:{class:'px-2 dropdown d-flex'}}, html);
		}
		
		let name = v.name.toLowerCase();
		if (v.type == 'list') name = name.toUpperCase();
		if (v.type == 'email') return email_menu();
		
		return fwHTML.elem({tag:'div', attr:{class:'px-2 text-truncate'}}, name);
	};

	function lstITEM(v){
		function fwSEARCHfilter(){
			let txt = $('#srch_input').val().trim().toLowerCase();
			if (!isEmpty(txt)){
				let show = false;
				show = (isValid(v, 'name') && v.name.toLowerCase().indexOf(txt)>=0);
				if (!show) show = (isValid(v, 'email') && v.email.toLowerCase().indexOf(txt)>=0);
				return !show;
			}
			return false;
		}
		if (fwSEARCHfilter()) return '';
		
		let id = v.id;
		let name = 'lstid';
		let value = gFn.fmtLSTname(v, true);
		
		if (!isEmpty(v.idx)){ // use index for selection
			id = v.idx;
			name = 'lstidx';
		}
		
		let attr = {id:id, name:name, type:'checkbox', class:'form-check-input m-1'};
		let cb = fwHTML.elem({tag:'input', attr:attr});
		return fwHTML.elem({tag:'div', attr:{class:'col text-start text-dark form-check'}}, cb + value);
	}
	
	function lstLSTcur(lst){
		if (isEmpty(lst)) return '';
		
		let html = '';
		$.each(lst, function(k, v){
			if (!isValid(v,'type')) v.type = 'list';
			html += lstITEM(v);
		});
		return fwHTML.elem({tag:'div', attr:{class:'row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-xl-4 bg-white py-2 px-1 fst-italic'}}, html);
	}
	
	function evtLSTcur(evt){
		function listMENU(evt){
			let showlst = (!isEmpty(evt.lstcur) && countITM(evt.lstcur)>0);
			
			let actions = [];
			if (showlst){
				actions.push({id:'checkall'+ evt.id, name:'Select All', icon:'square-check', text:'dark', onclick:"fwAPP.usr.api.list.checkall({name:'lstid', id:"+ evt.id +"})"});
				actions.push({id:'evtupdate'+ evt.id, name:'Send Update', icon:'pen-to-square', text:'primary', onclick:"fwAPP.usr.api.list.evtUPDATE('"+ evt.id +"')"});
			}
			if (evtIS(evt).own){
				if (showlst) actions.push({type:'dropdown-divider'});
				actions.push({name:'Add/Link List', icon:'folder-plus', text:'primary', onclick:"fwAPP.usr.api.list.add({evtid:"+ evt.id +"})"});
				if (showlst) actions.push({id:'unlink'+ evt.id, name:'Unlink List', icon:'square-minus', text:'danger', onclick:"fwAPP.usr.api.list.rmv({evtid:"+ evt.id +"})"});
				actions.push({name:'Manage My Lists', icon:'list-check', text:'primary', onclick:"fwAPP.api.loadACTION('mylists')"});
			}
			return fwHTML.elem({tag:'div', attr:{class:'col m-0 my-2 p-0 text-start'}}, fwHTML.dropdown(actions, {name:'Invite', bg:'dark'}));
		}
		let html = listMENU(evt); // there is something to show
		return isEmpty(html) ? html : fwHTML.elem({tag:'div', attr:{class:'row ms-3'}}, html) + lstLSTcur(evt.lstcur);
	}
	
	function genpass(pass, sletype){
		let sysval = fwAPP.api.get('sysval');
		
		function callFn(id, type, sletype){
			if (sletype == 'orgpay') return "fwAPP.cart.orgpay("+ id +")";
			return "fwAPP.cart.sleadd(431,"+ id +","+ type +")"; 
		}
		
		if (!isEmpty(pass)){
			$.each(pass, function(k, v){
				let myclass = (v.amt>=50) ? 'badge text-bg-dark-gz' : 'badge bg-secondary';
				v.name = fwHTML.elem({tag:'h6',attr:{onclick:callFn(v.id, v.type, sletype)}}, 
					idnameFIND(sysval.etktype, v.type).name +' '+ fwHTML.elem({tag:'span', attr:{class:myclass}}, currency(v.amt).format()));
			});
		}
		return pass;
	}
	
	function imgoverlay(v){
		let pass = isEmpty(v.pass) ? {} : v.pass;

		let msg = {number:100, text:''};		
		$.each(pass, function(k, v){
			let val = currency(v.qty_avail).divide(v.qty).intValue;
			if (val <= 40 && val > 20 && val < msg.number){
				msg = {number:val, text:'Limited quantity'};	
			}	else if (val <= 20 && val > 10 && val < msg.number){
				msg = {number:val, text:'Very limited quantity'};	
			}	else if (val <= 10 && val > 0 && val < msg.number){
				msg = {number:val, text:'Almost sold out'};	
			}	else if (val == 0){
				msg = {number:val, text:'SOLD OUT'};	
			}
			fwLogger.error('imgoverlay', currency(v.qty_avail).divide(v.qty).intValue);
		});
		let icon = fwHTML.icon({name:'circle-info', text:(isEmpty(msg.text) ? 'light' : 'success')}); 
		return fwHTML.elem({tag:'button', attr:{type:'button', class:'btn btn-sm btn-dark position-absolute top-0 start-0', onclick:"fwAPP.api.loadACTION('eventinfo','"+ v.encoded +"')"}}, icon);
	}
	
	function evtsle(v){
		let evtdt = fwAPP.api.dtDUR2sdtANDedt(v.start_dt, v.duration);
		fwLogger.error('evtsle', v, evtdt);
		
		let fname = imgMGRapi.useimg(v.img, {width:300, height:100}, '/cmn/img/band.jpg'); 
		let opt = {};
		
		opt.fhtml = fwHTML.card({title:evtdt.s.str +' - '+ v.name, text:gFn.place(v.place), internal:true});
		opt.bhtml = fwHTML.card({text:fmtStr.truncate(v.description, 200), internal:true});

		let sletype = evtIS(v).orgpay ? 'orgpay' : 'cart';
				
		let html = isEmpty(v.description) ? fwHTML.card({title:evtdt.s.str +' - '+ v.name, text:gFn.place(v.place), list:genpass(v.pass, sletype), src:fname, imgoverlay:imgoverlay(v), onclick:"fwAPP.api.loadACTION('eventinfo','"+ v.encoded +"')", cardwrap:true})
			: fwHTML.card({html:fwHTML.flipcard(opt), list:genpass(v.pass, sletype), src:fname, imgoverlay:imgoverlay(v), onclick:"fwAPP.api.loadACTION('eventinfo','"+ v.encoded +"')"});
		return fwHTML.masonry(html);
	}
	
	this.mylists = function(lstusr){
		function listMENU(opt, lstcur){
			let actions = [];
			if (!isEmpty(lstcur)){
				actions.push({id:'checkall'+ opt.plstid, name:'Select All', icon:'square-check', text:'dark', onclick:"fwAPP.usr.api.list.checkall({name:'lstidx', id:"+ opt.plstid +"})"});
				actions.push({type:'dropdown-divider'});
			}
			actions.push({name:'Add Contact', icon:'square-plus', text:'primary', onclick:'fwAPP.usr.api.list.contact({lstid:'+ opt.plstid +'})'});
			actions.push({name:'Import', icon:'envelopes-bulk', text:'primary', onclick:'fwAPP.usr.api.list.import('+ opt.plstid +')'});
			if (!isEmpty(lstcur)){
				actions.push({id:'export'+ opt.plstid, name:'Export', icon:'download', text:'dark', onclick:'fwAPP.usr.api.list.export('+ opt.plstid +')'});
			}
			actions.push({type:'dropdown-divider'});
			actions.push({name:'New List', icon:'square-plus', text:'primary', onclick:"fwAPP.usr.api.list.edit({global:'new'})"});
			let list = fwHTML.dropdown(actions, {name:'List', bg:'dark'});
			
			actions = [];
			if (!isEmpty(lstcur)){
				actions.push({id:'unlink'+ opt.plstid, name:'Unlink', icon:'square-minus', text:'primary', onclick:'fwAPP.usr.api.list.rmv({id:'+ opt.plstid +'})'});
				actions.push({id:'move'+ opt.plstid, name:'Move', icon:'right-to-bracket', text:'primary', onclick:'fwAPP.usr.api.list.mv({id:'+ opt.plstid +'})'});
				actions.push({type:'dropdown-divider'});
			}
			if (!isEmpty(fwAPP.usr.api.list.availLIST(opt.plstid))){
				actions.push({name:'Link', icon:'square-plus', text:'primary', onclick:'fwAPP.usr.api.list.link({id:'+ opt.plstid +'})'});
			}
			actions.push({name:'Edit', icon:'pen-to-square', text:'primary', onclick:"fwAPP.usr.api.list.edit({global:'edit', id:"+ opt.plstid +"})"});
			actions.push({name:'Remove', icon:'square-minus', text:'danger', onclick:"fwAPP.usr.api.list.rmv({global:true, id:"+ opt.plstid +"})"});
			html = fwHTML.elem({tag:'div', attr:{class:'btn-group'}}, list + fwHTML.dropdown(actions, {name:fwHTML.icon('list'), bg:'text-bg-dark-gz', prop:'attached'}));
			return fwHTML.elem({tag:'div', attr:{class:'mx-3 mb-3 text-start'}}, html);
		}
		
		function lstbodyHTML(opt, lstcur){
			fwLogger.log('lstbodyHTML', opt, lstcur);
			let html = fwHTML.elem({tag:'h5', attr:{class:'fst-italic fw-light ps-5'}}, fwHTML.icon('circle-arrow-up') + 'Import contacts or Add lists ...');
			return listMENU(opt, lstcur) + (isEmpty(lstcur) ? html : lstLSTcur(lstcur));
		}
		
		let search = {
			fwS:fwSEARCH({mask:'filter', mode:'filter', clctnr:'text-start m-3 p-0', clelem:'row'}),
			start:function(){
				search.fwS.change = function(txt, ptxt, mode, id){
					fwLogger.error(txt, ptxt, mode, id);
					search.query();
				};
				
				search.fwS.query();
				return this;
			},
				
			next:function(){
				let txt = search.fwS.get();
				let disable = (isEmpty(lstusr) && isEmpty(txt));
				
				$('#srch_input').prop('disabled', disable);
				$('#srch_srch').prop('disabled', disable);
				
				if (disable){
					search.fwS.content(fwSEARCHinfo());
					return this;
				}
				
				if (search.cnt() == 0) search.fwS.content('Nothing found');
				return this;
			},
			
			query:function(){
				let txt = search.fwS.get();
				if (isEmpty(lstusr)) return search.next().done();
				
				let html = fwHTML.accordion({id:'lstusr'}, lstusr, function(k,v,a){ // header
					return fwHTML.elem({tag:'div', attr:{id:v.id, class:'row fst-italic py-1 w-100 text-capitalize'}}, v.name);
				}, function(k,v,a){ // body
					return fwHTML.spinner();
				});
				search.fwS.content(html);

				fwAPP.api.srvREQ({fn:'get', cmd:'FWS_LST', opt:{text:txt}, callback:function(data){
					let rmvids = [];
					$.each(lstusr, function(k, v){
						let found = false;
						$.each(data, function(i, d){
							if (d == v.id) found = true;
						});
						if (!found) rmvids.push(v.id);
					});
					
					$.each(rmvids, function(i, d){
						$('#lstusr-'+ d).remove();
					});
					
					return search.next().done().openitem();
					
				}, err_callback:function(result){
					search.done();
				}});
				return this;
			},
			
			cnt:function(){
				return $('#lstusr .accordion-item').length;
			},
			
			openitem:function(){
				if (search.cnt() == 0) return this;
				
				let plstid = fwAPP.api.pin('get', 'plstid');
				fwLogger.warn('pin:plstid', plstid);
				if (isEmpty(plstid) || $('#lstusr-'+plstid).length == 0){
					$("#lstusr .accordion-button:first").trigger("click");
					$("#lstusr .accordion-button:after").trigger("click");
				} else {
					list2top('lstusr', '.accordion-item', plstid);
					fwAPP.usr.api.list.checkall({name:'lstidx', id:plstid, cmd:'init'});
					$("#lstusr .accordion-button>#"+ plstid).trigger("click");
				}
				return this;
			},

			done:function(){
				search.fwS.endquery();
				if (search.cnt() == 0) return this;
				
				$('#lstusr .accordion-item').off('hidden.bs.collapse').on('hidden.bs.collapse',function(){
					fwLogger.log('hidden.bs.collapse');
				});
				
				$('#lstusr .accordion-item').off('show.bs.collapse').on('show.bs.collapse',function(){
					fwLogger.log('show.bs.collapse');
					let showitem = $(this);
					
					let opt = {};
					opt.plstid = showitem.find('div.row').prop('id');
					if (isEmpty(opt.plstid)) return fwLogger.error('plstid');

					let idx = $(this).parent().children().index(this);
					if (idx > 0){
						let plstid = fwAPP.api.pin('get', 'plstid');
						if (plstid != opt.plstid){
							fwLogger.error('accordion-item', idx, plstid, opt.plstid);	
							fwAPP.api.pin('set', 'plstid', opt.plstid);
							pin2top('lstusr', '.accordion-item', idx);
						}
					}
					
					fwAPP.api.srvREQ({fn:'get', cmd:'LST_USR', opt:opt, callback:function(data){
						lstcur = fwAPP.api.set('lstcur', data);
						let html = lstbodyHTML(opt, lstcur);
						showitem.find('.accordion-body').html(html);
						fwAPP.usr.api.list.checkall({name:'lstidx', id:opt.plstid, cmd:'init'});
					}, err_callback:function(result){
					}});
				});
				return this;
			}
		};
		
		search.start();
	};
	
	function evtIS(v){
		let usr = fwAPP.api.get('usr');
		let opt = {own:(usr.id == v.usrid), orgpay:(!isEmpty(v.orgpay) && v.orgpay), active:(v.opstat=='ACTIVE'), advfree:(!isEmpty(v.advfree) && v.advfree)};
		opt.access = opt.own; // seto false if not owner
		if (!opt.access && !isEmpty(v.access)){
			$.each(v.access, function(k, v){ // place each pass in the correct list
				if (usr.id == v.usrid) opt.access = true;
			});
		}

		return opt;
	}
	
	this.pasIS = function(v){
		let usr = fwAPP.api.get('usr');
		return {own:(usr.id == v.event_usrid),etk:(usr.id == v.usrid),hld:(usr.id == v.hldid),paid:!isEmpty(v.trntype),validated:!isEmpty(v.validated)};
	};
	
	function pasLST(paslst, evtid){
		let html = '';
		
		let my_events = {name:'my events', lst:[]};
		let attending = {name:'attending events', lst:[]};
		$.each(paslst, function(k, v){ // place each pass in the correct list
			if (evtid == v.evtid){ 
				if (gFn.pasIS(v).own || gFn.pasIS(v).etk) my_events.lst.push(v);
				else if (gFn.pasIS(v).etk || gFn.pasIS(v).hld) attending.lst.push(v);
			}
		});
		let all_events = [my_events, attending];
		
		$.each(all_events, function(k, v){
			if (!isEmpty(v.lst)){
				let tkt_grp = [];
				$.each(v.lst, function(tk, tv){ // group each pass into their event list
					let found = false;
					$.each(tkt_grp, function(gk, gv){
						if (tv.evtid == gv.evtid){
							gv.lst.push(tv);
							return !(found=true);
						}
					});
					if (!found) tkt_grp.push({name:tv.name, evtid:tv.evtid, lst:[tv]});												
				});
				fwLogger.error(v, tkt_grp);
				let gv = tkt_grp[0]; 
				html = fwHTML.accordion({id:'evt'+ gv.evtid}, [gv], function(tk, tv, ta){ // header
					let actions = fwHTML.elem({tag:'div', attr:{class:'btn btn-sm btn-primary fst-italic', onclick:"fwAPP.usr.eticketACTION('assign')"}}, fwHTML.icon('pen-to-square') + 'Assign Passes');
					if (v.name == 'my events') actions += fwHTML.elem({tag:'div', attr:{class:'btn btn-sm btn-danger fst-italic', onclick:"fwAPP.usr.eticketACTION('release')"}}, fwHTML.icon('calendar-minus') + 'Release Passes');
					return fwHTML.elem({tag:'div', attr:{class:'badge text-bg-dark-gz text-capitalize'}}, 'epasses: '+ v.name +actions);
					
				}, function(tk, tv, ta){ // body
					fwLogger.error('BODY',tk,tv,ta);
					let html = '';
					$.each(tv.lst,function(i, t){
						html += paskit.pass(t);
					});
					return html;
				});
			}
		});
		
		return html;
	}

	let maxInput = 3;
	this.padkey = function(key){
		function isStart(c){ return (c != Number(c)); }

		if (key == 'clear'){
			$('#padkeypad').html('');
			return beep();
		}
		
		if (isStart(key)) maxInput = 5;
		
		let padval = isStart(key) ? key : $('#padkeypad').html()+key;
		if (padval.length == maxInput){
			maxInput = 3;
			$('#padkeypad').html(padval);
			let flds = fwAPP.api.get('var_page');
			fwLogger.warn('VERIFY', padval, flds);
			fwAPP.usr.qrscan({evtid:flds.evtid, code:padval}, function(){
				$('#padkeypad').html('');
			});
			return;
		} else if (padval.length > maxInput){
			return;
		}
		$('#padkeypad').html(padval);
	};
	
	function codePAD(){
		
		let encoded = fwAPP.api.get('encoded');
		let evt = fwAPP.api.get('evtlst',{encoded:encoded});
		let sysval = fwAPP.api.get('sysval');
		
		let html = '', khtml = '';
		for (let i = 1; i <= 9; i++){
			khtml += fwHTML.elem({tag:'div', attr:{id:'padkey'+i, class:'col btn btn-light px-3 py-2 border', onclick:"fwAPP.html.padkey('"+ i +"')"}}, i);
			/* jshint -W018 */
			if (!(i % 3)){
				html += fwHTML.elem({tag:'div', attr:{class:'row row-cols-3'}}, khtml);
				khtml = '';
			}
			/* jshint +W018 */
		}
		
		let phtml = fwHTML.icon('terminal') + fwHTML.elem({tag:'div', attr:{id:'padkeypad', class:'badge text-bg-primary'}});
		phtml = fwHTML.elem({tag:'div', attr:{class:'btn text-start btn-text-bg-dark-gz'}}, phtml);
		html = fwHTML.elem({tag:'div', attr:{class:'row', onclick:"fwAPP.html.padkey('clear')"}}, phtml) + html;

		khtml = '';
		$.each(evt.pass,function(k, v){
			let type = idnameFIND(sysval.etktype, v.type);
			let padkey = fwAPP.api.codeType(v.type, 'A');
			khtml += fwHTML.elem({tag:'div', attr:{id:'padkey'+padkey, class:'col btn btn-light px-3 py-2 border', onclick:"fwAPP.html.padkey('"+ padkey +"')"}}, padkey);
		});
		html += fwHTML.elem({tag:'div', attr:{class:'row row-cols-'+ countITM(evt.pass) +' fst-italic'}}, khtml);
		
		return fwHTML.elem({tag:'div', attr:{class:'fw-bold', style:'max-width:150px;'}}, html);
	}

	function getPASLST(evtid){
		fwAPP.api.srvREQ({fn:'GIGPASSget', cmd:'PAS_LST', opt:{}, callback:function(data){
			let paslst = data;
			fwAPP.api.set('vpaslst', paslst);
			if (!isEmpty(paslst)){ // populate alias
				$.each(paslst, function(k, v){
					if (v.evtid == evtid && !isEmpty(v.alias) && gFn.pasIS(v).hld && !gFn.pasIS(v).validated){
						let html = fmtStr.truncate(v.alias +' > '+ v.etkid.substring(v.etkid.length-4) +' > '+ idnameFIND(sysval.etktype,v.type).name);
						html = fwHTML.elem({tag:'div', attr:{class:'col small text-truncate'}}, html);
						html = fwHTML.elem({tag:'div', attr:{class:'col small text-truncate'}}, html);
						let action = fwHTML.elem({tag:'button', attr:{class:'btn btn-sm btn-outline-dark', onclick:"fwAPP.usr.eticketACCEPT('"+ v.id +"')"}}, icon);
						html += fwHTML.elem({tag:'div', attr:{class:'col-1 text-end p-0'}}, action);
						$('#srch_content').append(fwHTML.elem({tag:'div', attr:{class:'row'}}, html));
						fwLogger.log(text, gFn.pasIS(v), v);
					}
				});
			}
		}, err_callback:function(result){
		}});
	}
	
	this.evalidate = function(evtlst){
		$('#mytest').html(codePAD());
		
		let encoded = fwAPP.api.get('encoded');
		if (isEmpty(encoded)) fwLogger.error('evalidate:encoded value is missing');
		
		let evt = fwAPP.api.get('evtlst', {encoded:encoded});
		if (isEmpty(evt)) return fwLogger.error('evalidate:event info is missing');
		
		fwAPP.api.set('var_page', {evtid:evt.id});
		let sysval = fwAPP.api.get('sysval');
		
		let icon = fwHTML.icon({name:'circle-check', pos:'center'});
		fwSEARCHpage({fn:'GIGPASSget', cmd:'FWS_EPS', opt:{evtid:evt.id}}, function(){
			let paslst = fwAPP.api.get('cache').fwsearch;
			
			let passes = paskit.parse('pasid', paslst); // everything to check against
			let html = '';
			
			$.each(passes, function(i, pasid){
				let name = paskit.sysvalLSTname('etktype', pasid, paslst, 'pasid', 'type');
				html += fwHTML.elem({tag:'div', attr:{class:'h5 fw-bold text-start'}}, name);
				html += showPASSES(pasid, paslst, false, 'row-cols-lg-2');
			});
			$('#srch_content').attr('class', 'row row-cols-1 bg-white py-3 px-1' ).html(html);
		});
	};
	
	this.followup = function(){ // called from myevents page
		let encoded = fwAPP.api.get('encoded');
		if (isEmpty(encoded)){
			fwLogger.error('followup:encoded value is missing');
			return fwAPP.api.loadACTION('myevents');
		}
		
		let evt = fwAPP.api.get('evtlst', {encoded:encoded});
		if (isEmpty(evt)){
			fwLogger.error('followup:event info is missing');
			return fwAPP.api.loadACTION('myevents');
		}
		
		fwAPP.api.set('var_page', {evtid:evt.id});
		let sysval = fwAPP.api.get('sysval');
		fwLogger.log('followup', encoded, evt, sysval);
		
		fwSEARCHpage({fn:'get', cmd:'FWS_VAL', opt:{evtid:evt.id}, elem:function(k, v){
			fwLogger.log(v, idnameFIND(sysval.dtaint, v.interest).name);
			icon = fwHTML.icon({name:'square-envelope'});
			let html = icon + '<b>'+ v.email +'</b> &gt '+ idnameFIND(sysval.dtaint,v.interest).name;
			html += fwHTML.elem({tag:'div', attr:{class:'row'}}, fmtStr.truncate(v.note));
			html = fwHTML.elem({tag:'div', attr:{class:'col small text-truncate'}}, html);
			
			let actions = [];
			if (fwAPP.api.evtOP(v).ended){
				actions.push({name:'Open',icon:'folder-closed',text:'secondary',onclick:"fwAPP.usr.event.followup("+ evt.id +","+ v.id +")"});
			} else{
				actions.push({name:'Reserve Passes',icon:'folder-open',text:'primary',onclick:"fwAPP.usr.eticketRESERVE("+ evt.id +",0,"+ v.id +")"});
				actions.push({name:'Close',icon:'folder-closed',text:'dark',onclick:"fwAPP.usr.event.followup("+ evt.id +","+ v.id +")"});
			}

			let color = 'dark-gz';
			if (fwAPP.api.evtOP(v).open) color = 'primary';
			if (fwAPP.api.evtOP(v).ended) color = 'dark';
			icon = fwHTML.icon({name:'pen-to-square', pos:'center', text:color});
			let action = fwHTML.elem({tag:'button', attr:{class:'btn dropdown btn-sm btn-outline-dark'}}, icon + fwHTML.dropdown_menu_li(actions));
			action = fwHTML.dropdown(actions, {name:icon, myclass:'small', bg:'white'});
			if (isValid(v, 'id')) html += fwHTML.elem({tag:'div', attr:{class:'col-1 text-end p-0'}}, action);
			return fwHTML.elem({tag:'div', attr:{class:'row'}}, html);
		}});
	};
	
	this.evtpas = function(pas){
		if (isEmpty(pas)){
			fwLogger.log('evtpas:pass params required');
			return '';
		}
		let sysval = fwAPP.api.get('sysval');
		
		html = fwHTML.elem({tag:'select', attr:{id:'pass_type[]', class:'form-select form-control bg-primary text-light text-start', 'aria-label':'Pass Type'}}, setSELECT(sysval.etktype, pas.type));
		
		let qty = fwHTML.elem({tag:'input', attr:{type:'number', class:'form-control', id:'pass_qty[]', placeholder:'0', value:pas.qty}});
		qty += fwHTML.elem({tag:'label', attr:{for:'pass_qty[]'}}, 'Number of Passes');
		html += fwHTML.elem({tag:'div', attr:{class:'form-floating'}}, qty);
		
		html += fwHTML.elem({tag:'span', attr:{class:'input-group-text'}}, '$');
		
		let amt = fwHTML.elem({tag:'input', attr:{type:'number', class:'form-control', id:'pass_amt[]', step:'1.00', value:pas.amt, 'aria-label':'Dollar amount (with dot and two decimal places)'}});
		amt += fwHTML.elem({tag:'label', attr:{for:'pass_amt[]'}}, 'Dollar amount');
		html += fwHTML.elem({tag:'div', attr:{class:'form-floating'}}, amt);
		
		let icon = fwHTML.icon({type:'regular',name:'square-plus'}) + fwHTML.icon({name:'ticket'});
		if (pas.required == true){
			html += fwHTML.elem({tag:'button', attr:{type:'button', class:'btn btn-primary', id:'pass_add', onclick:"fwAPP.html.pagePASSadd()"}}, icon);
		} else {
			icon = fwHTML.icon({type:'regular',name:'square-minus'}) + fwHTML.icon({name:'ticket'});
			html += fwHTML.elem({tag:'button', attr:{type:'button', class:'btn btn-outline-danger', id:'pass_remove', onclick:"fwAPP.html.pagePASSrmv(this)"}}, icon);
		}
		return fwHTML.elem({tag:'div', attr:{name:'page_pass', class:'input-group input-group-sm'}}, html);
	};

	this.actinfo = function(k, v){
		let caption = fwHTML.elem({tag:'div', attr:{class:'text-bg-success'}}, v.name); 
		let html = fwHTML.elem({tag:'div', attr:{class:'col-sm-auto col-md-4 col-lg-3'}}, gFn.carouselIMGS('mycarousel'+k, v.img, {height:125, caption:caption}));
		
		description = v.description;
		if (!isEmpty(v.url)){
			let url = fwHTML.elem({tag:'a', attr:{class:'link-dark link-underline-dark link-offset-3', href:v.url, target:'_blank', rel:'noopener noreferrer'}}, v.url); 
			description += fwHTML.elem({tag:'div', attr:{class:'bg-light text-end mt-2'}}, url);
		}
		
		html += fwHTML.elem({tag:'div', attr:{class:'col d-none d-sm-block'}}, fwHTML.elem({tag:'div', attr:{class:'card-body'}},
		fwHTML.elem({tag:'div', attr:{class:'card-text text-start small'}}, description)));
		
		html = fwHTML.elem({tag:'div', attr:{class:'row g-0'}}, html);
		return fwHTML.elem({tag:'div', attr:{class:'card'}}, html);
	};
	
	function sendEVTmeta(v){
		let evtdt = fwAPP.api.dtDUR2sdtANDedt(v.start_dt, v.duration);
		
		function getMETAimg(imgs){
			let useimg = imgMGRapi.useimg(imgs, {width:300, height:200}, 'noimg');
			if (useimg =='noimg') useimg = imgMGRapi.useimg(imgs, {width:250, height:250}, 'noimg');
			if (useimg =='noimg') useimg = imgMGRapi.useimg(imgs, {width:300, height:100}, '/cmn/img/logo_v1.gif');
			return useimg;
		}
		
// https://ogp.me/#types		
		const taglst = [{attr:'property', name:'og:title', content:evtdt.s.str +' '+ v.name},
			{attr:'property', name:'og:type', content:'article'},
			{attr:'property', name:'og:image', content:getMETAimg(v.img)},
			{attr:'property', name:'og:url', content:window.location.href},
			{attr:'property', name:'og:description', content:v.description},
			{name:'twitter:image:alt', content:evtdt.s.str +' '+ v.name}
		];
		fwAPP.api.headerMETAtags(taglst);
	}
	
	this.evtinfo = function(v){
		fwLogger.log('evtinfo', v);
		
		sendEVTmeta(v);
		
		let evtdt = fwAPP.api.dtDUR2sdtANDedt(v.start_dt, v.duration);
		html = fwHTML.elem({tag:'div', attr:{class:'text-bg-dark text-start fw-bold fst-italic py-2 px-3'}}, evtdt.s.str);
		
		let description = fwHTML.elem({tag:'div', attr:{class:'col text-start fw-bold py-2 px-3'}}, v.name);
		description = fwHTML.elem({tag:'div', attr:{class:'row g-0'}}, description);
		
		let sletype = evtIS(v).orgpay ? 'orgpay' : 'cart';
		
		let description_internal = fwHTML.elem({tag:'div', attr:{class:'col text-start border px-3'}}, gFn.place(v.place));
		description_internal += fwHTML.elem({tag:'div', attr:{class:'col-md-8 col-sm-12 border'}}, fwHTML.card_ul(genpass(v.pass, sletype)));
		description += fwHTML.elem({tag:'div', attr:{class:'row g-0'}}, description_internal);
		
		let header = fwHTML.elem({tag:'div', attr:{class:'col'}}, description);
		header += fwHTML.elem({tag:'div', attr:{class:'col-md-4 col-sm-12 bg-dark'}}, gFn.carouselIMGS('evtcarousel', v.img, {height:75}));
		html += fwHTML.elem({tag:'div', attr:{class:'row g-0'}}, header);
		
		if (!isEmpty(v.act)){
			html += fwHTML.elem({tag:'div', attr:{class:'text-bg-success text-start fw-bold fst-italic px-3'}}, 'Starring'); 
			$.each(v.act,function(n, i){
				html += fwAPP.html.actinfo(n, i);
			});
		}
		
		html += fwHTML.elem({tag:'div', attr:{class:'text-bg-dark text-start fw-bold fst-italic py-2 px-3'}}, 'Description'); 
		if (!isEmpty(v.description)) html += fwHTML.elem({tag:'div', attr:{class:'pre-wrap border-0 text-start small p-3'}}, v.description);
		html += htmlHR();
		
		return html + fwAPP.html.getfeed(true);
	};
	
	function htmlHR(){
		return fwHTML.elem({tag:'div', attr:{'class':'border-dark-gz border-3 border-top mt-3'}}, '&nbsp;');		
	}
	
	this.carouselIMGS = function(name, images, opt){
		if (!isEmpty(images)){
			$.each(images, function(k, v){
				images[k] = imgMGRapi.nocacheName(v);
			});
		} else images[0] = 'cmn/img/noimage.jpg';
		
		return fwHTML.carousel(name, images, opt);
	};
	
	const form = {
		textarea:function(opt){ 
			let html = fwHTML.elem({tag:'textarea', attr:{id:opt.id, readonly:'readonly', 'class':'form-control'}}, opt.text);
			html += fwHTML.elem({tag:'label', attr:{for:opt.id}}, opt.title);
			return fwHTML.elem({tag:'div', attr:{class:'form-floating'}}, html);
		}
	};

	const paskit = {
		isIN:function(v, lst){
			return (!isEmpty(v) && $.inArray(v, lst)>=0);
		},
		
		parse:function(e, lst){
			if (isEmpty(lst)) return {};
			
			let result = []; 
			$.each(lst, function(k, v){
				if (!isEmpty(v[e]) && $.inArray(v[e], result)<0) result.push(v[e]);
			});
			
			return result;
		},
		
		get:function(e, id, lst){
			let result = [];
			$.each(lst, function(k, v){
				if (id == v[e]) result.push(v);
			});
			return result;
		},
		
		sysvalLSTname:function(e, id, lst, lk, lv){
			let sysval = fwAPP.api.get('sysval');
			
			let name = '';
			$.each(lst, function(k, v){
				if (id == v[lk]){
					name = idnameFIND(sysval[e], v[lv]).name;
					return false;
				}
			});
			return name;
		},
		
		type:function(t, abbr){
			let sysval = fwAPP.api.get('sysval');
			let name = idnameFIND(sysval.etktype, t.type).name;
			if (!isEmpty(abbr) && abbr){
				switch(t.type){
					case '1': name = 'GA'; break;
					case '2': name = 'VIP'; break;
					case '3': name = 'SECT'; break;
					case '4': name = 'SEAT'; break;
					case '5': name = 'EBD'; break;
					case '6': name = 'PWC'; break;
				}
			}
			return name;
		},
		
		icon:function(t){
			let text = 'dark';
			let icon = gFn.pasIS(t).paid ? fwHTML.icon({name:'ticket', pos:'x', text:text}) : fwHTML.icon({name:'paperclip', pos:'x', text:text});
			return gFn.pasIS(t).validated ? fwHTML.icon({name:'circle-check', pos:'x', text:'success'}) : icon;
		},
		
		holder:function(t){
			let holder = isValid(t, 'holder.email') ? t.holder.email : '';
			if (isValid(t, 'next_holder.email')) holder = t.next_holder.email;
			return fmtStr.truncate(holder, 20);
		},
		
		shortrno:function(trno){
			return 'T-'+ trno.substring(trno.length-5).toUpperCase();
		},
		
		name:function(t){
			let holder = paskit.holder(t);
//			let etkSTART = t.etkid.substring(t.etkid.length-12, t.etkid.length-4) +'-';
//			let etkEND = t.etkid.substring(t.etkid.length-4);
			let etkSTART = t.etkid.substring(t.etkid.length-5) +'-';
			let etkEND = t.qckid;
			if (!isEmpty(holder)) etkSTART = holder +'-';
			if (!isEmpty(t.alias)) etkSTART = t.alias +'-';
			
			return (etkSTART + etkEND);
		},
		
		alink:function(t){
			let name = paskit.name(t);
			return paskit.icon(t) + fwHTML.elem({tag:'a', attr:{class:'d-flex link-dark px-2', href:'/gigpass?id='+ t.etkid,target:'_blank',rel:'noopener noreferrer'}}, name);
		},
		
		pass:function(t){
			let html = paskit.alink(t);
			let attr = {id:t.etkid, name:'etktok', type:'checkbox', class:'form-check-input'};
			if (!gFn.pasIS(t).hld || gFn.pasIS(t).validated) attr.disabled = 'disabled';
			let badge = fwHTML.elem({tag:'input',attr:attr});
			return fwHTML.elem({tag:'div', attr:{class:'p-1 m-0'}}, badge) + html;
		},
		
		expand:function(name, cnt){
			if (isEmpty(cnt)) cnt = 0;
//			name = String(name).trim().toLowerCase();
			name = String(name).trim().toUpperCase();
			let html = fwHTML.elem({tag:'div', attr:{class:'d-flex align-items-center text-capitalize text-truncate'}}, name);
			html = fwHTML.elem({tag:'span', attr:{class:'badge rounded text-bg-dark-gz me-1 my-1'}}, cnt) + html;
			return fwHTML.elem({tag:'div',attr:{class:'d-inline-flex'}}, html);
		}
	};
	
	let htmlPASSdone = [];
	
	function htmlPASS(v, chkbox){
		if ($.inArray(v.id, htmlPASSdone)<0) htmlPASSdone.push(v.id);
		let html = (!isEmpty(chkbox) && chkbox) ? paskit.pass(v) : paskit.alink(v);
		return fwHTML.elem({tag:'div', attr:{class:'col d-flex align-items-center text-truncate m-0 p-0 ps-3 text-start'}}, html);
	}
	
	function showPASSEStype(e, paslst, validate, chkbox){
		if (isEmpty(paslst)) return '';
		validate = (!isEmpty(validate) && validate);
		chkbox = (!isEmpty(chkbox) && chkbox);
		
		let html = '';
		
		$.each(paskit.parse(e, paslst), function(i, y){
			let lst = paskit.get(e, y, paslst);
			if (e == 'trno') y = paskit.shortrno(y); 
			
			if (validate){
				let validlst = [];
				$.each(lst, function(k, v){
					if (!paskit.isIN(v.id, htmlPASSdone)) validlst.push(v);
				});
				lst = validlst;
			}

			if (lst.length>1){
				let grphtml = fwHTML.accordion({id:e+ trimWS(y).toLowerCase()}, [{}], function(k, p, a){ // header
					return paskit.expand(y, lst.length);
				}, function(k, p, a){ // body
					let html = '';
					$.each(lst, function(j, av){
						html += htmlPASS(av, chkbox);
					});
					return fwHTML.elem({tag:'div', attr:{class:'row row-cols-1 border py-2'}}, html);
				});
				html += fwHTML.elem({tag:'div', attr:{class:'col m-0 px-1 text-start'}}, grphtml);
			}
		});
		return html;
	}
	
	function showPASSES(pasid, v, chkbox, fmt){
		chkbox = (!isEmpty(chkbox) && chkbox);
		
		if (isEmpty(v)) return '';
		
		let html = '';
		htmlPASSdone = [];
		
		let paslst = paskit.get('pasid', pasid, v);
		
		html += showPASSEStype('alias', paslst, false, chkbox);
		html += showPASSEStype('trno', paslst, true, chkbox);
		
		$.each(paslst, function(k, v){
			if (!paskit.isIN(v.id, htmlPASSdone)) html += htmlPASS(v, chkbox);
		});
		if (isEmpty(fmt)) fmt = 'row-cols-md-2 row-cols-lg-3 row-cols-xl-4'; 
		return fwHTML.elem({tag:'div', attr:{class:'row row-cols-1 '+ fmt +' bg-white py-3 px-1'}}, html);
	}

	function evtHEADER(v){
		let evtid = fwAPP.api.pin('get', 'evtid');
		let evtop = fwAPP.api.evtOP(v);
		
		let html = fwHTML.elem({tag:'span', attr:{class:'badge rounded-1 '+ (evtop.ended?'bg-red-gz':'text-bg-dark-gz')}}, evtop.evtdt.s.str);
		if (evtIS(v).active) html += fwHTML.elem({tag:'span', attr:{class:'position-absolute top-0 start-0'}}, fwHTML.icon({name:'clock', text:'white', pos:'s'}));
		
		html = fwHTML.elem({tag:'div', attr:{class:'text-start p-0 m-0'}}, html);
		let icon = (v.id == evtid) ? fwHTML.icon({name:'thumbtack', text:'dark-gz'}) : '';
		if (isEmpty(icon)) icon = fwHTML.icon({name:evtIS(v).orgpay?'ticket':'barcode', text:'dark-gz'});
		html += fwHTML.elem({tag:'div', attr:{class:'text-start text-truncate'}}, icon + v.name);
	
		return fwHTML.elem({tag:'div', attr:{class:'row fst-italic py-1 w-100'}}, html);
	}

	function pin2top(name, iname, idx){
		let wrapper = $('#'+ name);
		let items = wrapper.children(iname);
		let itmcnt = countITM(items);
		
		if (idx <= 0 || idx >= itmcnt) return; // nothing todo
		let arr  = [idx];
		for (let i = 0; i < itmcnt; i++){
			if (i != idx) arr.push(i); 
		}			
		//items.detach(); if arr doesn't reuse all items
		wrapper.append( $.map(arr, function(v){ return items[v]; }) );
	}
	
	function list2top(name, iname, id){
		let wrapper = $('#'+ name);
		let items = wrapper.children(iname);
		let itmcnt = countITM(items);
		
		let pos = 0;
		switch(name){
			case 'evtlst': pos = fwAPP.api.get('evtlst', {evtid:id, idx:true}); break;
			case 'lstusr': pos = fwAPP.api.get('lstusr', {id:id, idx:true}); break;
		}

		if (pos <= 0 || pos >= itmcnt) return; // nothing todo
		let arr  = [pos];
		for (let i = 0; i < itmcnt; i++){
			if (i != pos) arr.push(i); 
		}			

		//items.detach(); if arr doesn't reuse all items
		wrapper.append( $.map(arr, function(v){ return items[v]; }) );
	}

	this.mypasses = function(){
		let evtlst = {};
		let search = {
			fwS:fwSEARCH({mask:'filter', mode:'filter', clctnr:'text-start m-3 p-0', clelem:'row'}),
			start:function(){
				search.fwS.change = function(txt, ptxt, mode, id){
					fwLogger.error(txt, ptxt, mode, id);
					search.query();
				};
				
				search.fwS.query();
				return this;
			},
				
			next:function(){
				let txt = search.fwS.get();
				let disable = (isEmpty(evtlst) && isEmpty(txt)); 
				$('#srch_input').prop('disabled', disable);
				$('#srch_srch').prop('disabled', disable);
				
				if (disable){
					search.fwS.content(fwSEARCHinfo());
					return this;
				}
				
				if (isEmpty(evtlst)) search.fwS.content('Nothing found');
				return this;
			},
			
			query:function(){
				let opt = {text:search.fwS.get(), mode:search.fwS.mode()};
				
				fwAPP.api.srvREQ({fn:'GIGPASSget', cmd:'FWS_PAS', opt:opt, callback:function(data){ // hldid - for other pass holders
					evtlst = data;

					if (isEmpty(evtlst)) return search.next().done();
					
					let html = fwHTML.accordion_ctnr('evtlst', 'evtctnr', evtlst, evtHEADER);
					search.fwS.content(html);
					
					fwHTML.accordion_ctnr_write('#evtctnr', evtlst, function(k, v){
						let passes = paskit.parse('pasid', v.pass); // everything to check against
						let html = '';
						
						$.each(passes, function(i, pasid){
							let name = paskit.sysvalLSTname('etktype', pasid, v.pass, 'pasid', 'type');
							let icon = fwHTML.icon({name:'file-pdf', text:'dark-gz'});
							let report = fwHTML.elem({tag:'div', attr:{class:'ms-2', onclick:'fwAPP.usr.report('+ v.id +','+ pasid +')'}}, icon);
							if (evtIS(v).orgpay) report = '';
							html += fwHTML.elem({tag:'div', attr:{class:'fw-bold text-start d-inline-flex'}}, name + report);
							html += showPASSES(pasid, v.pass);
						});
						return html;
					});
					
					$('#evtlst .accordion-item').off('show.bs.collapse').on('show.bs.collapse',function(){
						fwLogger.log('show.bs.collapse');
						
						let id = $(this).prop('id');
						let myidx = 0, idx = 0;
						$('#evtlst [class="accordion-item"').each(function(i){
							let myid = $(this).prop('id');
							if (myid.indexOf('evtlst-') == 0){
								if (id == myid) myidx = idx;
								idx += 1;
							}
						});
						pin2top('evtlst', '.accordion-item', myidx);
					});
					search.done().openitem();
					
				}, err_callback:function(result){
					search.done();
				}});
				return this;
			},
			
			cnt:function(){
				return $('#evtlst .accordion-item').length;
			},
			
			openitem:function(){
				if (search.cnt() == 0 || !isValid(evtlst[0],'id')) return this;
				$('#collapse-evtlst-'+ evtlst[0].id).collapse("show");
				return this;
			},
			
			done:function(){
				search.fwS.endquery();
				return this;
			}
		};
		
		search.start();
	};
	
	this.myevents = function(evtlst){
		let sysval = fwAPP.api.get('sysval');
		let paslst = fwAPP.api.get('paslst');
		
		function passMENU(v, p, pasCNT){
			let evtop = fwAPP.api.evtOP(v);
			if (evtop.init || evtop.ended) return '';
			let actions = [];
			if (p.qty_avail>0) actions.push({name:'Reserve Passes', icon:'calendar-plus', text:'primary', onclick:'fwAPP.usr.eticketRESERVE('+ v.id +','+ p.id +')'});
			if (pasCNT>0){
				actions.push({name:'Assign', icon:'pen-to-square', text:'dark', onclick:"fwAPP.usr.eticketACTION('assign')"});
				actions.push({name:'Release', icon:'calendar-minus', text:'danger', onclick:"fwAPP.usr.eticketACTION('release')"});
			}
			return fwHTML.elem({tag:'div', attr:{class:'col m-0 my-2 p-0 text-start'}}, fwHTML.dropdown(actions, {name:'Passes', bg:'dark'}));
		}
		
		function eventMENU(v){
			let evtid = fwAPP.api.pin('get', 'evtid');
			let evtop = fwAPP.api.evtOP(v);
			let actions = [];
			actions.push({name:(evtid == v.id?'Unpin':'Pin'),icon:'thumbtack',text:'dark-gz',onclick:'fwAPP.usr.event.'+ (evtid == v.id?'unpin':'pin') +'('+ v.id +')'});
			actions.push({name:'View',icon:'bars',text:'dark',onclick:"fwAPP.api.loadACTION('eventinfo','"+ v.encoded +"')"});
			actions.push({type:'dropdown-divider'});
			if (evtIS(v).own){
				if (evtop.init){
					actions.push({name:'Edit',icon:'pen-to-square',text:'primary',onclick:"fwAPP.api.loadACTION('event',"+ v.id +")"});
					actions.push({name:'Pass Options',icon:'ticket',text:'primary',onclick:'fwAPP.usr.event.show('+ v.id +')'});
					actions.push({name:'Load Images',icon:'images',text:'primary',onclick:"fwAPP.usr.getImage('evt')"});
				}
			}
			
			if (!evtop.init && !evtIS(v).orgpay) actions.push({name:'Report',icon:'file-pdf',text:'dark',onclick:'fwAPP.usr.report('+ v.id +')'});
			actions.push({name:'Update Note',icon:'file',text:'dark',onclick:'fwAPP.usr.event.note('+ v.id +')'});
			let html = fwHTML.dropdown(actions, {name:'Event', bg:'dark'});
			
			actions = [];
			if (evtop.init || evtop.pause){
				actions.push({name:'Activate',icon:'toggle-on',text:'primary',onclick:'fwAPP.usr.event.activate('+ v.id +')'});
			} else {
				actions.push({name:'Validate', icon:'circle-check', text:'dark-gz', onclick:"fwAPP.api.loadACTION('evalidate','"+ v.encoded +"')"});
				if (v.followup>0) actions.push({name:'Follow-up', icon:'clock', text:'dark', onclick:"fwAPP.api.loadACTION('followup','"+ v.encoded +"')"});
				if (evtIS(v).own){
					if (!isEmpty(actions)) actions.push({type:'dropdown-divider'});
					actions.push({name:'Manage Features',icon:'toggle-on',text:'dark-gz',onclick:'fwAPP.usr.event.activate('+ v.id +')'});
					actions.push({name:'Manage Access',icon:'users',text:'dark',onclick:'fwAPP.usr.event.access('+ v.id +')'});
				}
			}
			if (!evtop.ended) html += fwHTML.dropdown(actions, {name:fwHTML.icon('list'), bg:'text-bg-dark-gz', prop:'attached'});
			html = fwHTML.elem({tag:'div', attr:{class:'btn-group'}}, html);
			return html + gFn.place(v.place);
		}

		function passLIST(evt, pas){
			let passes = paskit.get('pasid', pas.id, paslst);
			let html = fwHTML.elem({tag:'div', attr:{id:'pas'+ pas.id}});
			let menu = passMENU(evt, pas, passes.length);
			if (isEmpty(menu)) return '';
			return fwHTML.elem({tag:'div', attr:{class:'row ms-3'}}, menu) + html;
		}

		function eventPASS(v){
			let evtop = fwAPP.api.evtOP(v);
			return fwHTML.accordion({id:'event'+ v.id}, v.pass, function(k, p, a){ // header
				let amt = (isValid(p, 'acct.amt')) ? p.acct.amt : {431:0};
				let l_html = fwHTML.elem({tag:'div', attr:{class:'col-auto text-start'}}, p.qty_avail +'/'+ p.qty);
				l_html += fwHTML.elem({tag:'div', attr:{class:'col-auto fs-6'}}, currency(p.amt).format());
				l_html += fwHTML.elem({tag:'div', attr:{class:'col-auto fw-bold'}}, paskit.type(p, true));
				let salamt = evtIS(v).orgpay ? '' : currency(amt[431]).format() +' / ';
				l_html += fwHTML.elem({tag:'div', attr:{class:'col text-end'}}, salamt + currency(p.qty).multiply(p.amt).format());
				return fwHTML.elem({tag:'div', attr:{class:'row fst-italic py-1 w-100'}}, l_html);
			}, function(k, p, a){ // body
				let l_html = '';
				if (isEmpty(p.item)) p.item = {}; // set it to empty anyway.
				$.each(p.item, function(i, oi){
					let name = (oi.name === idnameFIND(sysval.itmtype,oi.type).name) ? oi.name : oi.name +'/'+ idnameFIND(sysval.itmtype,oi.type).name;	
					
					let i_html = fwHTML.elem({tag:'div', attr:{class:'col-auto text-start'}}, oi.qty +'x '+ currency(oi.amt).format());
					i_html += fwHTML.elem({tag:'div', attr:{class:'col-auto fw-medium text-start'}}, name);
					i_html += fwHTML.elem({tag:'div', attr:{class:'col fw-medium text-end'}}, currency(oi.qty).multiply(oi.amt).format() +' value');
					
					if (evtop.init){
						i_html += fwHTML.elem({tag:'div', attr:{class:'col-auto text-end'}}, fwHTML.button({name:'Remove',icon:'square-minus',text:'secondary',onclick:'fwAPP.usr.itemRMV('+ oi.id +')'}));
					}
					l_html += fwHTML.elem({tag:'div', attr:{class:'row py-1 w-100'}}, i_html);
				});
				
				l_html += passLIST(v, p);
				
				if (evtop.init){
					let i_html = fwHTML.button({name:'Add Item',icon:'square-plus',text:'primary',onclick:'fwAPP.usr.itemADD('+ p.id +')'});
					l_html += fwHTML.elem({tag:'div',attr:{class:'row py-1 w-100'}}, fwHTML.elem({tag:'div',attr:{class:'col text-start'}}, i_html));
				}
				if (isEmpty(l_html)){
					let i_html = fwHTML.button({name:'No Relevant Information to View',icon:'square-caret-up',text:'primary',onclick:'fwAPP.usr.event.show('+ v.id +','+ p.id +')'});
					return fwHTML.elem({tag:'div',attr:{class:'row py-1 w-100'}}, fwHTML.elem({tag:'div',attr:{class:'col text-start'}}, i_html));
				}
				return l_html;
			});
		}
		
		let search = {
			fwS:fwSEARCH({mask:'filter', mode:'filter', clctnr:'text-start m-3 p-0', clelem:'row'}),
			start:function(){
				search.fwS.change = function(txt, ptxt, mode, id){
					fwLogger.error(txt, ptxt, mode, id);
					search.query();
				};
				
				search.fwS.query();
				return this;
			},
			
			next:function(){
				let txt = search.fwS.get();
				let disable = (isEmpty(evtlst) && isEmpty(txt)); 
				$('#srch_input').prop('disabled', disable);
				$('#srch_srch').prop('disabled', disable);
				
				if (disable){
					search.fwS.content(fwSEARCHinfo());
					return this;
				}
				
				if (isEmpty(evtlst)) search.fwS.content('Nothing found');
				return this;
			},
			
			filter:function(){
				let txt = search.fwS.get().toLowerCase();
				let evtlst_filter = [];
				$.each(evtlst, function(i, evt){
					let found = isEmpty(txt);
					if (!found) found = (evt.name.toLowerCase().indexOf(txt) >= 0);
					if (!found) found = (evt.description.toLowerCase().indexOf(txt) >= 0);
					if (!found && !isEmpty(evt.lstcur)){
						$.each(evt.lstcur, function(k, v){
							if (!found) found = (v.name.toLowerCase().indexOf(txt) >= 0);
						});
					}
					if (found) evtlst_filter.push(evt);
				});
				return evtlst_filter;
			},
			
			query:function(){
				if (isEmpty(evtlst)) return search.next().done();
				
				let evtlst_filter = search.filter();
				
				let html = fwHTML.accordion_ctnr('evtlst', 'evtctnr', evtlst_filter, evtHEADER);
				search.fwS.content(html);
				
				fwHTML.accordion_ctnr_write('#evtctnr', evtlst_filter, function(k, v){
					let tophtml = eventMENU(v);
					tophtml = fwHTML.elem({tag:'div', attr:{class:'col'}}, tophtml);
					tophtml = fwHTML.elem({tag:'div', attr:{class:'row row-cols m-0'}}, tophtml);


					let html = '';
					if (!isEmpty(v.note)) html += form.textarea({id:'note'+ v.id, title:'Organizer Note', text:fmtStr.truncate(v.note)});
					html += fwHTML.elem({tag:'div', attr:{class:'border'}}, eventPASS(v));
					if (!isEmpty(v.description)) html += form.textarea({id:'description'+ v.id, title:'Description', text:fmtStr.truncate(v.description, 120)});
					
					html = fwHTML.elem({tag:'div', attr:{class:'col col-md ms-0 p-0'}}, html);
					html += fwHTML.elem({tag:'div', attr:{class:'col col-md-auto p-0'}}, fwHTML.imagesizer('evt', v.id));
					html = tophtml + fwHTML.elem({tag:'div', attr:{class:'row row-cols-1 row-cols-md justify-content-md-center m-0 pb-3'}}, html);
					
					html += gFn.act(v.id, v.act);
					html += fwHTML.elem({tag:'div', attr:{class:'border'}}, evtLSTcur(v));
					return html;
				});

				imgMGRapi.imagesizerInit();
				
				$.each(evtlst_filter, function(i, evt){
					fwHTML.accordion_ctnr_write('#pas', evt.pass, function(k, v){
						let passes = paskit.get('pasid', v.id, paslst);
						return showPASSES(v.id, passes, !fwAPP.api.evtOP(evt).ended);
					});
				});
				
				let lastSHOWN = '';
				$('#evtlst').off('show.bs.collapse').on('show.bs.collapse', function(e){
					fwLogger.log('show.bs.collapse');
					let shown = fwAPP.api.eventIDis(e);
					if (lastSHOWN != shown){
						lastSHOWN = shown;
						$('input:checkbox').prop('checked', false);
						let evtid = (shown.indexOf('collapse-evtlst') == 0) ?  shown.substring(shown.lastIndexOf('-')+1) : 0;
						if (evtid > 0){
							fwAPP.api.updateURL('/?f=myevents&i='+ evtid);
							fwAPP.usr.api.list.checkall({name:'lstid', id:evtid, cmd:'init'});
							imgMGRapi.setImg();
						}
					}
					setTimeout(function(){ fwAPP.api.windowRESIZE(true); }, 350);
				});
				search.next().done().openitem();
			},
			
			cnt:function(){
				return $('#evtlst .accordion-item').length;
			},
			
			openitem:function(){
				if (search.cnt() == 0) return this;
				let evtid = fwAPP.api.pin('get', 'evtid');
				let idx = fwAPP.api.get('evtlst', {evtid:evtid, idx:true});
				if (isEmpty(idx)) evtid = fwAPP.api.pin('set', 'evtid'); // clear old pin if no longer needed
				
				if (isEmpty(evtid)){ // select first one to open
					let evtlst = fwAPP.api.get('cache').evtlst;
					if (!isEmpty(evtlst)) evtid = evtlst[0].id;
				}
				if (!isEmpty(evtid)){
					list2top('evtlst', '.accordion-item', evtid);
					fwAPP.usr.api.list.checkall({name:'evtlst', id:evtid, cmd:'init'});
				}
				if (isEmpty(evtid) || evtid<=0) evtid = fwAPP.api.get('param_id');
				if (isEmpty(evtid) || evtid<=0) evtid = isEmpty(idx) ? 0 : evtlst[0].id;
				
				if (!isEmpty(evtid)) $('#collapse-evtlst-'+ evtid).collapse("show");
				autosize($('textarea'));
				autosize.update($('textarea'));
				return this;
			},
			
			done:function(){
				search.fwS.endquery();
				return this;
			}
		};
		
		search.start();
	};
	
	let lastQOUTE = 0;
	function nextQUOTE(){
		let quote = fwAPP.api.get('sysval').quote;
		let pos = ((lastQOUTE++)%countITM(quote));
		return fwHTML.figure(quote[pos]);
	}
	
	function cartlinks(evt){
		if (isEmpty(evt.pass)) return '';
		
		let evtdt = fwAPP.api.dtDUR2sdtANDedt(evt.start_dt, evt.duration);
		let sysval = fwAPP.api.get('sysval');
		
		let actions = [];
		
		let html = '';
		$.each(evt.pass, function(k, v){
			let myclass = (v.amt>=50) ? 'badge text-bg-dark-gz' : 'badge bg-secondary';
			let onclick = evtIS(evt).orgpay ? "fwAPP.cart.orgpay("+ v.id +","+ v.type +")"
				: "fwAPP.cart.sleadd(431,"+ v.id +","+ v.type +")";
			let name = fwHTML.elem({tag:'span', attr:{class:myclass}}, currency(v.amt).format());
			name += ' '+ idnameFIND(sysval.etktype, v.type).name;
			actions.push({name:name, icon:'ticket', text:'primary', onclick:onclick});
		});
		
		return fwHTML.dropdown(actions, {name:evtdt.s.str, bg:'dark', myclass:'btn-sm'});
	}

	let dispstat = {data:{}, show:{}, rt:{},
		clear:function(type, id){
			dispstat.data = {};
			dispstat.show = {};
			dispstat.rt = {};
			let encoded = fwAPP.api.get('encoded');
			let evt = fwAPP.api.get('evtsle',{encoded:encoded}); 
			if (!isEmpty(evt)) dispstat.rt.evtid = evt.id; // if on eventinfo page
		},
		
		isIN:function(type, id){
			return ($.inArray(id, dispstat.data[type])>=0);
		},
		
		rmv:function(type, id){
			if (!dispstat.isIN(type, id)) return;
			let idx = dispstat.data[type].indexOf(id);
			if (idx>=0) dispstat.data[type].splice(idx, 1);
			
			// update show stats
			type = (type == 'advfree') ? 'evtsle' : type.substring(0, 6);
			if (isEmpty(dispstat.show[type])) dispstat.show[type] = [];
			idx = dispstat.show[type].findIndex(function(e){return e.id==id;});
			if (idx>=0) dispstat.show[type][idx].cnt++;
			else dispstat.show[type].push({id:id, cnt:1});
		}
	};
	
	function vstack(width, c){
		let html = fwHTML.elem({tag:'div', attr:{class:'col text-start'}}, c[0]);
		html += fwHTML.elem({tag:'div', attr:{class:'col d-flex align-items-center text-start'}}, c[1]);
		return html;
	}
	
	const block = {
		evtsle:function(type, v){
			let opt = section.type2opt(type);
			let advfree = (!isEmpty(opt.advfree) && opt.advfree);
			
			let html = '';
			let evtdt = fwAPP.api.dtDUR2sdtANDedt(v.start_dt, v.duration);
			
			let onclick = "fwAPP.api.loadACTION('eventinfo','"+ v.encoded +"')";

			let img = imgMGRapi.matchimg(v.img, [{w:300,h:100}, {w:300,h:200}]);
			
			let attr = {};
			
			if (!isEmpty(img)){
				attr = {class:'card-img-top', src:img.src, onclick:onclick};
				html = fwHTML.elem({tag:'img', attr:attr});
				let icon = fwHTML.icon({name:'circle-info', text:(evtIS(v).orgpay ? 'light' : 'success')}); 
				let btn = fwHTML.elem({tag:'button', attr:{type:'button', class:'btn btn-sm btn-dark position-absolute top-0 start-0', onclick:onclick}}, icon);
				html += fwHTML.elem({tag:'div', attr:{'class':'p-0'}}, btn);
			}
			
			html += fwHTML.elem({tag:'div', attr:{class:'text-start fw-bold'}}, evtdt.s.str);
			html += fwHTML.elem({tag:'div', attr:{'class':'fw-bold text-capitalize text-start'}}, fmtStr.truncate(v.name, 50));
			let bodyhtml = fwHTML.elem({tag:'div', attr:{'class':'fst-italic'}}, fwHTML.icon('check') + fmtStr.truncate(v.description, 300));
fwLogger.error('DBG:evtsle', opt, advfree, v);
			if (advfree){
				attr = {'class':'card border-0 p-0'};
				attr.style = 'width:'+ opt.width +'px;';
				html = fwHTML.elem({tag:'div', attr:attr}, html);
				html += fwHTML.elem({tag:'div', attr:{'class':'card d-none d-lg-block text-start border-0 p-0', style:'width:'+ opt.width +'px;'}}, bodyhtml);
			}

			bodyhtml = cartlinks(v);
			bodyhtml += fwHTML.elem({tag:'small', attr:{'class':'fw-medium'}}, gFn.place(v.place));
			if (advfree){
				img = imgMGRapi.matchimg(v.img, [{w:75,h:300}, {w:250,h:250}]);
				if (!isEmpty(img)){
					bodyhtml += fwHTML.elem({tag:'img', attr:{class:'card-img-top mt-2', src:img.src, style:'width:'+ img.width/2 +'px;', onclick:onclick}});
				}
			}

			attr = {'class':'card d-flex align-items-'+ (advfree?'end':'start') +' border-0 p-0'};
			if (advfree) attr.style = 'width:'+ opt.width +'px;';
			html += fwHTML.elem({tag:'div', attr:attr}, bodyhtml);
			if (advfree) html += htmlHR();
			else html = fwHTML.elem({tag:'div', attr:{'class':'border-dark-gz border-1 border-bottom h-100'}}, html);
			return html;
		},
		
		advlst:function(type, v){
			
			let opt = section.type2opt(type);
			let fname = (type == 'advlst') ? 'noimg' : imgMGRapi.useimg(v.img, opt, 'noimg');
			let onclick = "fwAPP.api.openURL('"+ v.url +"',{'advid':"+ v.id +"});";
			
			let icon = fwHTML.icon({name:'up-right-from-square', text:'light'}); 
			let mylink = fwHTML.elem({tag:'div', attr:{class:'badge text-wrap', style:'word-break:break-all;'}}, icon + v.url);
			let imgoverlay = fwHTML.elem({tag:'button', attr:{type:'button', class:'btn btn-sm btn-text-bg-dark-gz text-start p-0 position-absolute bottom-0 start-0 w-100', onclick:onclick}}, mylink);
			let topoverlay = fwHTML.elem({tag:'button', attr:{type:'button', class:'btn btn-sm btn-text-bg-dark-gz text-start fst-italic p-0 position-absolute top-0 start-0 w-100', style:'font-size:8px;', onclick:onclick}}, 'Advertisement');
			
			let txt = fwHTML.elem({tag:'p', attr:{class:'card-body fst-italic'}}, v.text);
			
			let html = '';
			if (fname != 'noimg'){
				html = fwHTML.elem({tag:'img', attr:{class:'card-img-top', style:'width:'+ opt.width +'px;', src:fname, onclick:onclick}});
				switch(type){
					// horizontal stacking
					case 'advlst_w250_h250':
						html = fwHTML.elem({tag:'img', attr:{class:'card-img-top', style:'width:'+ opt.width/2 +'px;', src:fname, onclick:onclick}});
						/* falls through */
					case 'advlst_w75_h300':
						html = vstack(opt.width, [html,txt]);
						break;
						
					default:
						html += txt;
						break;
				}
			} else {
				html = txt;
			}
			
			html = fwHTML.elem({tag:'div', attr:{class:'row g-0'}}, html);
			html += topoverlay;
			html += imgoverlay;
			return html;
		},
		
		evttmr:function(type, v, sopt){
			let opt = section.type2opt(type);
			let evtdt = fwAPP.api.dtDUR2sdtANDedt(v.start_dt);
			
			let fname = (type == 'evttmr') ? 'noimg' : imgMGRapi.useimg(v.img, opt, 'noimg');
			let title = fwHTML.elem({tag:'div', attr:{class:'text-start fw-bold w-100'}}, evtdt.s.str_date +'<br/>'+ v.name);
			let attr = {class:'card-img-top', src:fname};
			if (isValid(sopt,'w')) attr.style = 'width:'+ sopt.w +'px;';
			let html = (fname != 'noimg') ? fwHTML.elem({tag:'img', attr:attr}) : '';
			
			html = (html == '') ? title : vstack(opt.width, [html,title]);
			
			let onclick = "fwAPP.api." + (v.external ? "openURL('"+ v.url +"',{'tmrid':"+ v.id +"});" : "showEVTTMR("+ v.id +");");

			html = fwHTML.elem({tag:'div', attr:{class:'row row-cols-1 text-start g-0', onclick:onclick}}, html);
			let myclass = 'border-dark-gz border-bottom h-100';
			
			if (v.external){
				myclass += ' border-2';
				
				let address = gFn.place({name:v.place.name, address:v.place.address_full});
				address += fwHTML.elem({tag:'div', attr:{class:'text-start fst-italic fw-bold p-0 w-100', style:'font-size:10px;', onclick:"fwAPP.api.openURL('"+ fwAPP.html.getmapURL(v.place) + "');"}}, v.place.address_full);
				html += fwHTML.elem({tag:'div', attr:{class:'text-start mb-1'}}, address);
			}
			return fwHTML.elem({tag:'div', attr:{class:myclass}}, html); // DBG:type
		}
	};
	
	const section = {
		type2opt:function(type){
			switch(type){
				case 'evttmr3_2': return {type:'tm', ratio:'3_2', width:120, height:80};
				case 'evttmr4_3': return {type:'tm', ratio:'4_3', width:160, height:120};
				case 'evttmr': case 'evttmr16_9': return {type:'tm', ratio:'16_9', width:120, height:68};
				case 'advlst_w75_h300': case 'evtsle_w75_h300': return {width:75, height:300};
				case 'advlst_w250_h250': case 'evtsle_w250_h250': return {width:250, height:250};
				case 'advlst_w300_h200': case 'evtsle_w300_h200': return {width:300, height:200};
				case 'advfree': return {width:300, height:100, advfree:true};
				default: case 'advlst_w300_h100': case 'evtsle_w300_h100': return {width:300, height:100};
			}
			return {};
		},
		
		stack:function(block, callback, opt1, opt2){
			let html = '';
			for (let i=0; i<block.cnt; i++){
				html += (block.stack!='x') ? callback(block.type, opt1, opt2)
				: fwHTML.elem({tag:'div', attr:{'class':'card border-0 m-0 p-0 mb-3', style:'width:'+ block.w +'px'}}, callback(block.type));
			}
			return html;
		},
		
		isitem:function(type, id, curr){
			let rt = feed.get().rt;
			curr = (!isEmpty(curr) && curr);
			if (type == 'evtsle' && !isEmpty(rt.evtid) && rt.evtid == id) return true; // on eventinfo page 
			if (!curr && !isValid(rt,'last.sel')) return false;
			
			let found = false;
			let sel = curr ? rt.sel : rt.last.sel;
			$.each(sel, function(k, v){
				if (type == v.type && id == v.id) return !(found=true);
			});
			return found;
		},
		
		additem:function(v){
			let rt = feed.get().rt;
			if (section.isitem(v.type, v.id, true)) return true;
			if (isEmpty(rt.sel)) rt.sel = [];
			rt.sel.push(v);
		},
		
		stat:function(name){ // update dispstat runtime
			let rt = feed.get().rt;
			rt.last = {name:name, sel:rt.sel};
			rt.scnt = isValid(rt, 'scnt') ? (rt.scnt+1) : 1;
			rt.sel = [];
			fwLogger.log('stat', fwAPP.api.clone(rt));
		},
		
		run:function(blocks, opt){ // default run rule - can be replaced by custom
			let html = '';
			let missing = false;
			$.each(blocks, function(k, v){
				if (v.type == 'advfree'){
					html = feed.next(v.type, true, opt);
					return fwHTML.elem({tag:'div', attr:{'class':'card border-0 m-0 p-0'}}, html);
				}
				
				let shtml = section.stack(v, feed.next, true, opt);
				if (!isEmpty(shtml)){
					html += (v.stack == 'x') ? shtml : fwHTML.elem({tag:'div', attr:{'class':'card border-0 m-0 p-0', style:'width:'+ v.w +'px'}}, shtml);
				} else {
					missing = true;
				}
			});			
			return missing ? '' : html;
		},
		
		quote:function(opt){ // one block
			let rt = feed.get().rt;
			/* jshint -W018 */
			if (!(rt.scnt%3) || opt.quote) return fwHTML.elem({tag:'div', attr:{'class':'row py-3'}}, nextQUOTE());
			/* jshint +W018 */
			return '';
		},
		
		one:function(blocks, opt){ // one block
			let html = '';
			
			let rt = feed.get().rt; // skip first display if eventinfo page
			if ((isEmpty(rt.scnt) || rt.scnt==0) && !isEmpty(rt.evtid) && rt.evtid>0) return html;
			
			$.each(blocks, function(k, v){
				if (v.type == 'advfree'){
					html = feed.next(v.type, false, opt);
					return false;
				}
			});
			
			if (!isEmpty(html)){
				if (!isEmpty(rt.scnt) && rt.scnt>0) html = htmlHR() + html;
				section.stat('one');
			}
			return html;
		},
		
		six:function(blocks, opt){ // six blocks
			function nextTYPE(type, need){
				let prio = { evtsle:['_w300_h200', '_w300_h100', '_w250_h250', '_w75_h300'],
					advlst:['_w300_h200', '_w300_h100', '_w250_h250', '_w75_h300'],
					evttmr:['3_2', '4_3', '16_9']};
					
				let ntype = type;
				$.each(prio[type], function(k, v){
					let data = feed.get().data;
					let avail = countITM(data[type+v]);
					if (avail>0 && need>0){ // still have items
						ntype = type+v;
						return false;
					}
				});
				return ntype;
			}
			
			let html = '';
			const max = {cnt:6, burst:6};
			let rt = {cnt:0, burst:0};
			
			function divHTML(html){
				if (!isEmpty(html)){
					rt.cnt++;
					rt.burst++;
					return fwHTML.elem({tag:'div', attr:{'class':'card border-0 m-0 p-0 mb-2', style:'width:'+ opt.w +'px'}}, html);
				}
				rt.burst = max.burst; // no more items, move on
				return '';
			}
			
			function buildHTML(getall){
				getall = (!isEmpty(getall) && getall);
				
				function evalBlock(k, v){
					if (isEmpty(v.burst) || v.burst>max.burst) v.burst = max.burst;
					rt.burst = 0;
					if (getall) v.burst = max.burst; // grab as many as we can
					do { // iterate until we got max.burst
						let ntype = nextTYPE(v.type, v.burst-rt.burst);
						
						if (ntype == v.type) rt.burst = max.burst; // no more images, move on
						else html += divHTML(feed.next(ntype, false, opt));
						if (rt.cnt >= max.cnt) return false;
						
					} while(rt.burst<v.burst && rt.cnt<max.cnt);
				}
					
				do { // iterate until we got max.cnt
					$.each(blocks, evalBlock);
				} while(rt.burst<max.burst && rt.cnt<max.cnt);
			}
			
			buildHTML(false); // apply every rule
			if (rt.cnt < max.cnt) buildHTML(true); // get what is left with images
			
			if (rt.cnt < max.cnt){ // get the rest
				feed.reorg();
				
				$.each(blocks, function(k, v){
					rt.burst = 0;
					v.burst = max.burst; // grab as many as we can
					do {
						html += divHTML(feed.next(v.type, true, opt));
						if (rt.cnt >= max.cnt) return false;
					} while(rt.burst<v.burst && rt.cnt<max.cnt);
				});
			}
			
			if (!isEmpty(html)) section.stat('six');
			return html;
		}
	};
	
	let feed = {
		get:function(){
			return dispstat;
		},
		
		build:function(type, opt, v){
			let ratio = (opt.type == 'tm') ? opt.ratio : '_w'+ opt.width +'_h'+ opt.height;
			
			let fname = isValid(v,'img') ? imgMGRapi.useimg(v.img, opt, 'noimg') : 'noimg';
			let disptype = (fname != 'noimg') ? type+ratio : type;
			if (isEmpty(dispstat.data[disptype])) dispstat.data[disptype] = [];
			if (!dispstat.isIN(disptype, v.id)) dispstat.data[disptype].push(v.id);
			
			if (disptype != type){ // also build noimg list
				if (isEmpty(dispstat.data[type])) dispstat.data[type] = [];
				if (!dispstat.isIN(type, v.id)) dispstat.data[type].push(v.id);
			}

			if (type.indexOf('evtsle')>=0 && evtIS(v).advfree){ // also build premium list
				type = 'advfree';
				if (isEmpty(dispstat.data[type])) dispstat.data[type] = [];
				if (!dispstat.isIN(type, v.id)) dispstat.data[type].push(v.id);
			}
		},
		
		prep:function(){
			let cache = fwAPP.api.get('cache');
			dispstat.clear();
			
			$.each(['advlst','evtsle','evttmr'], function(i, type){
				if (!isEmpty(cache[type])){
					$.each(cache[type], function(k, v){
						switch(type){
							case 'evttmr':
								$.each(['3_2','16_9','4_3'], function(i, ratio){
									feed.build(type, {type:'tm', ratio:ratio}, v);
								});
								break;

							default:
								$.each([{w:300,h:100},{w:300,h:200},{w:250,h:250},{w:75,h:300}], function(i, ratio){
									feed.build(type, {width:ratio.w, height:ratio.h}, v);
								});
								break;
						}
					});
				}
			});
			fwLogger.warn('prep', fwAPP.api.clone(dispstat));
		},
		
		reorg:function(){
			function mergeArraysWithoutDuplicates(arr1, arr2){
				const mergedSet = new Set([...arr1, ...arr2]);
				return Array.from(mergedSet);
			}
			
			$.each(dispstat.data, function(k, v){
				$.each(['advlst','evtsle','evttmr'], function(i, type){
					if (k != type && k.indexOf(type)==0){
						dispstat.data[type] = mergeArraysWithoutDuplicates(dispstat.data[type], dispstat.data[k]);
						dispstat.data[k] = [];
					}
				});
			});
		},
		
		next:function(type, skipcheck, opt){
			let cache = fwAPP.api.get('cache');
			let html = '';
			let key = type.substring(0, 6);
			switch(key){
				case 'advlst': case 'evttmr': break;
				default: key = 'evtsle'; break; // catchall
			}
			skipcheck = (!isEmpty(skipcheck) && skipcheck);
			
			if (!isEmpty(cache[key])){
				$.each(cache[key], function(k, v){
					if (dispstat.isIN(type, v.id)){
						if (skipcheck || !section.isitem(key, v.id) && !section.isitem(key, v.id, true)){ // if not in previous or current section
							section.additem({type:key, id:v.id});
							html = block[key](type, v, opt);
							dispstat.rmv(type, v.id);
							return false;
						}
						fwLogger.log('next.isIN.isitem', key, v.id, type);
					}
				});
			}
			return html;
		}
	};
	
	this.preview2adv = function(adv){
		$.each([{w:300,h:100},{w:300,h:200},{w:250,h:250},{w:75,h:300}], function(i, ratio){
			feed.build('advlst', {width:ratio.w, height:ratio.h}, adv);
		});

		let html = '';
		let	rules = [
			{name:'run', note:'Text only', rule:[{type:'advlst', cnt:1, w:250}]},
			{name:'run', note:'300 x 100', rule:[{type:'advlst_w300_h100', cnt:1, w:300}]},
			{name:'run', note:'300 x 200', rule:[{type:'advlst_w300_h200', cnt:1, w:300}]},
			{name:'run', note:'250 x 250', rule:[{type:'advlst_w250_h250', cnt:1, w:250}]},
			{name:'run', note:'75 x 350', rule:[{type:'advlst_w75_h300', cnt:1, w:250}]}
		];

		$.each(rules, function(k, v){
			let shtml = section[v.name](v.rule);
			if (!isEmpty(shtml)){
				let rhtml = fwHTML.elem({tag:'div', attr:{class:'position-absolute badge rounded bg-danger mt-2'}}, v.note);
				html += fwHTML.elem({tag:'div', attr:{class:'col-auto d-flex justify-content-center position-relative mb-2'}}, shtml+rhtml);
			}
		});
		return fwHTML.elem({tag:'div', attr:{class:'row row-cols-1 row-cols-sm-2 row-cols-md-3 justify-content-center'}}, html);
	};

	this.getfeed = function(view){
/*
		const sectionrules = [
	//		{name:'run', quote:true, rule:[{type:'advfree', cnt:3, w:300, stack:'x'}]},
			{name:'run', quote:false, rule:[{type:'advfree', cnt:1, w:300}]},
	//		{name:'run', quote:true, rule:[{type:'advfree', cnt:1, w:300}, {type:'evttmr3_2', cnt:1, w:300}, {type:'advlst_w75_h300', cnt:1, w:300}]},
			{name:'run', quote:false, rule:[{type:'advlst', cnt:3, w:300, stack:'x'}]},
			{name:'run', quote:false, rule:[{type:'evttmr4_3', cnt:3, w:300, stack:'x'}]},
			{name:'run', quote:false, rule:[{type:'evtsle_w300_h100', cnt:3, w:300, stack:'x'}]},
			{name:'run', quote:true, rule:[{type:'evttmr4_3', cnt:1, w:300}, {type:'advlst_w250_h250', cnt:1, w:300}, {type:'advfree', cnt:1, w:300}]},
			{name:'run', quote:false, rule:[{type:'evtsle_w300_h200', cnt:3, w:300, stack:'x'}]},
			{name:'run', quote:true, view:true, rule:[{type:'advlst_w300_h200', cnt:1, w:300}, {type:'advlst_w75_h300', cnt:1, w:300}, {type:'advlst_w75_h300', cnt:1, w:300}]},
			{name:'run', quote:false, rule:[{type:'advlst_w300_h200', cnt:3, w:300, stack:'x'}]},
			{name:'run', quote:false, rule:[{type:'evttmr16_9', cnt:6, w:300, stack:'x'}]},
			{name:'run', quote:false, view:true, rule:[{type:'advlst_w300_h100', cnt:3, w:300, stack:'x'}]},
			{name:'run', quote:false, rule:[{type:'evttmr4_3', cnt:6, w:300, stack:'x'}]},
			{name:'run', quote:true, rule:[{type:'evtsle', cnt:3, w:300, stack:'x'}]},
			{name:'run', quote:false, rule:[{type:'evttmr', cnt:3, w:300, stack:'x'}]},
			{name:'run', quote:false, view:true, rule:[{type:'advlst', cnt:3, w:300, stack:'x'}]}
		];
*/		
		const sectionrules = [
			{name:'one', quote:false, w:300, rule:[{type:'advfree'}]},
//			{name:'six', quote:false, w:300, rule:[{type:'evtsle', burst:3}, {type:'evttmr', burst:3}, {type:'advlst'}]}
			{name:'six', quote:false, w:300, rule:[{type:'evtsle', burst:3}, {type:'evttmr', burst:3}, {type:'advlst'}]}
		];
		feed.prep();
//		return fwLogger.error('feed.get', feed.get());

		function evalSectionrule(k, v){
			html += section[v.name](v.rule, {w:v.w});
			if (html.length > htmlLength) html += section.quote({quote:v.quote});
		}

		let html = '', htmlLength = 0;
		do { // iterate until all data is used up
			htmlLength = html.length;
			$.each(sectionrules, evalSectionrule);
		} while(html.length > htmlLength);
		
		if (fwAPP.api.get('state') != 'done'){
			let show = feed.get().show;
			if (isEmpty(show)){
				fwAPP.api.set('state', 'done');
				return;
			}
			
			fwAPP.api.srvREQ({fn:'GIGPASSset', cmd:'DSP_UPD', opt:show, callback:function(data){
				fwAPP.api.set('state', 'done');
			}, err_callback:function(result){
			}});
		}
		
		if (!isEmpty(html)) html = fwHTML.elem({tag:'div', attr:{class:'row justify-content-evenly'}}, html);
		return html;
	};
	
	this.getfeedREMOVE = function(view){
		
		let id = 0; // skip current event
		let encoded = fwAPP.api.get('encoded');
		if (!isEmpty(encoded)){
			let evt = fwAPP.api.get('evtsle',{encoded:encoded});
			if (!isEmpty(evt)) id = evt.id;
		}
		
		function isValidSection(v){
			if (!isEmpty(view) && view == true){
				if (!isValid(v, 'view') || isEmpty(v.view) || v.view != true) return false; 
			}
			let validSection = false;
			
			$.each(v.rule, function(i, rule){
				if (countITM(dispstat.data[rule.type]) < rulesum[rule.type]) return (validSection=false);
				validSection = true;
			});
			return validSection;
		}
		
		function build2show(){
			let html = '';
			let cnt = 0;
			$.each(sectionrules, function(k, v){
				if (isValidSection(v)){
					let shtml = section[v.name](v.rule);
					if (!isEmpty(shtml)){
						html += shtml;
						if (v.quote || ++cnt>2){
							cnt = 0;
							html += fwHTML.elem({tag:'div', attr:{'class':'row py-3'}}, nextQUOTE());
						}
					}
				}
			});
			if (!isEmpty(html)) html = fwHTML.elem({tag:'div', attr:{class:'row justify-content-evenly'}}, html);
			return html;
		}
		
		let html = '', htmlLength = 0;
		do { // iterate until all data is used up
			htmlLength = html.length;
			html += build2show();
		} while(html.length > htmlLength);
		
		feed.reorg();
		
		do { // iterate until all data is used up
			htmlLength = html.length;
			html += build2show();
		} while(html.length > htmlLength);
		
		if (fwAPP.api.get('state') != 'done'){
			let show = feed.get().show;
			if (isEmpty(show)){
				fwAPP.api.set('state', 'done');
				return;
			}
			
			fwAPP.api.srvREQ({fn:'GIGPASSset', cmd:'DSP_UPD', opt:show, callback:function(data){
				fwAPP.api.set('state', 'done');
			}, err_callback:function(result){
			}});
		}
		
		return html;
	};
	
	this.evttmr = function(type, evt){
		let html = block.evttmr(type, evt);
		evt.external = false;
		return html;
	};
	
	return gFn;
}