// extends https://foliotek.github.io/Croppie/

function imgMGR(id, opt){
	let gFn = this;
	
	function init(opt){
		let icon = fwHTML.icon('image') +'Upload';
		let html = fwHTML.elem({tag:'button', attr:{id:'imgMGRget', 'type':'button', 'class':'btn btn-sm btn-success p-0 px-1', 'data-bs-toggle':'tooltip', 'data-bs-title':'Upload Your File'}}, icon);
		
		icon = fwHTML.icon({name:'rotate', pos:'center'});
		html += fwHTML.elem({tag:'button', attr:{id:'imgMGRrotate', 'type':'button', 'class':'btn btn-sm btn-primary p-0 px-1 disabled', 'data-bs-toggle':'tooltip', 'data-bs-title':'Rotate Image'}}, icon);
		
		html += fwHTML.elem({tag:'input', attr:{id:'imgMGRupload', 'type':'file', 'style':'display:none;'}});

		icon = fwHTML.icon({name:'file-arrow-up'}) +'Save';
		html += fwHTML.elem({tag:'button', attr:{id:'imgMGRsave', 'type':'button', 'class':'btn btn-sm btn-success p-0 px-1 disabled', 'data-bs-toggle':'tooltip', 'data-bs-title':'Save Current Image'}}, icon);
		
		html += fwHTML.elem({tag:'div', attr:{id:'imgMGRimg'}});
		return html;
	}
	
	this.enable = function(){
		$('#imgMGRrotate').removeClass('disabled');
		$('#imgMGRsave').removeClass('disabled');
		$('div.cr-slider-wrap').show();
	};
	
	this.disable = function(){
		$('#imgMGRrotate').addClass('disabled');
		$('#imgMGRsave').addClass('disabled');
		$('div.cr-slider-wrap').hide();
	};
	
	this.readFile = function(input){
		if (input.files && input.files[0]) {
			let reader = new FileReader();
			
			reader.onload = function(e) {
				cH.croppie('bind', {
					url: e.target.result,
					zoom: 0
				}).then(function(){
					let image = cH.get();
					console.log('GET', image);
					gFn.enable();
				});
			};
			
			reader.readAsDataURL(input.files[0]);
		}	else {
			console.error("FileReader API");
		}
	};
	
	this.upload = function(fpath, fname, callback){
		cH.croppie('result', 'blob').then(function(data){
			fwAPP.api.upload(fpath, fname, data, function(result){
				if (result.status != 'ok') return console.error(result, data);
				imgMGRapi.nocacheSet();
				callback(result);
			});
		});
	};

	this.rotate = function(n){
		n = isEmpty(n) ? 90 : n;
		return cH.croppie('rotate', 90);
	};
	
	this.croppie = function(method, args){
		if (isEmpty(method) || isEmpty(args)){
			console.error('croppie', method, args);
			return {};
		}
		return cH.croppie(method, args);
	};
	
	$(id).html(init(opt)); // my default html
	
	$('#imgMGRget').off('click').on('click', function(event){
		$('#imgMGRupload').click();
	});
	
	opt.zoom = 0;
	let cH = $('#imgMGRimg').croppie(opt); // init
	gFn.disable();
	
	return this;
}

var imgMGRapi = {
	nocacheVer:'?t=0',
	nocacheInit:function(){
		var ms = new storage('LOCAL').get('nocache_ms');
		let result = isEmpty(ms) ? imgMGRapi.nocacheSet() : imgMGRapi.nocacheVer = '?t='+ ms;
	},
	nocacheSet:function(){
		var ms = (new Date()).getMilliseconds();
		imgMGRapi.nocacheVer = '?t='+ ms;
		new storage('LOCAL').set('nocache_ms', ms);
	},
	
	nocacheName:function(fname){
		return (fname + imgMGRapi.nocacheVer);
	},
	
	sel2size:function(id){
		let size = String($(id).val()).split('.');
		return { width: size[0], height: size[1] };
	},

	canuseimg:function(img, opt){
		let useimg = '';
		if (isEmpty(img) || typeof img === 'string') return useimg;
		let sizeStr = (isValid(opt, 'width') && isValid(opt, 'height')) ? 'w'+ opt.width +'_h'+ opt.height : '';
		$.each(img, function(k, v){
			if (opt.type == 'tm' && isValid(opt, 'ratio') && opt.ratio == v.ratio){ // ticketmaster formating
				useimg = v.url;
				return false;
			} else if (!isEmpty(sizeStr) && typeof v === 'string' && String(v).indexOf(sizeStr)>0){
				useimg = '/'+ v +imgMGRapi.nocacheVer;
				return false;
			}
		});
		return useimg;
	},
	
	setImg:function(dest){
		if (isEmpty(dest)) dest = 'evt';
		let imgid = fwAPP.api.get('param_id');
		
		let sizer_id = (dest == 'evt') ? '#imgsize_'+ imgid : '#imgsize';
		let target_id = (dest == 'evt') ? '#target_image_'+ imgid : '#target_image';
		
		let size = imgMGRapi.sel2size(sizer_id);
		$(target_id).attr('width',(size.width/2)).attr('height',(size.height/2));
		let fname = imgMGRapi.bldpath('/cmn/img/', dest, (imgid>0 ? imgid : 0), '', size);
		imgMGRapi.loadimg(target_id, fname, true);
	},
	
	imagesizerInit:function(dest){
		$('[id^=imgsize]').off('change').on('change', function(event){
			imgMGRapi.setImg(dest);
		});
	},

	useimg:function(img, opt, defimg){
		if (isEmpty(defimg)) defimg = '/cmn/img/noimage.jpg';
		let img2use = imgMGRapi.canuseimg(img, opt);
		if (!isEmpty(img2use)) return img2use;
		return defimg;
	},
	
	matchimg:function(img, param){
		let opt = {};
		if (!Array.isArray(param) && typeof param !== 'object') return opt;
		$.each(param, function(i, ratio){
			let fname = imgMGRapi.useimg(img, {width:ratio.w, height:ratio.h}, 'noimg');
			if (isEmpty(opt) && fname != 'noimg') opt = {width:ratio.w, height:ratio.h, src:fname};
		});
		return opt;
	},
	
	checkimg:function(fname, callback_success, callback_error){
		$.ajax({ type:'HEAD', url:fname, success:function(message, text, response){
			if(response.getResponseHeader('Content-Type').indexOf("image")!=-1){
				if (typeof callback_success === 'function') callback_success();
			}
		}, error:function(request, status, errorThrown){
			if (typeof callback_error === 'function') callback_error();
		}});
	},
	
	loadimg:function(id, fname, nc, callback){
		if (isEmpty(nc) || !nc) nc = false;
		
		imgMGRapi.checkimg(fname, function(){
			let nocacheName = nc ? imgMGRapi.nocacheName(fname) : fname;
			if (!isEmpty(id)) $(id).attr('src', nocacheName);
			if (typeof callback === 'function') callback(nocacheName);
		}, function(){
			if (!isEmpty(id)) $(id).attr('src', '/cmn/img/noimage.jpg');
		});
	},
	
	bldpath:function(root, dest, id, num, size){
		dest = String(dest);
		let prefix = isEmpty(dest) ? '' : (dest.charAt(0) + dest.charAt(1));
		if (isEmpty(size)) size = { width: 100, height: 100 };
		if (isEmpty(size.width) || isNaN(size.width)) size.width = 100;
		if (isEmpty(size.height) || isNaN(size.height)) size.height = 100;
		
		num = isEmpty(num) ? '' :  '_'+ num;
		let name = id;
		if (!isValidID(id)){ // if new record
			let usr = fwAPP.api.get('usr');
			name = '0_u'+ usr.id;
		}

	//		let rootpath = fwAPP.api.get('url').rootpath;
		let fname = prefix + name + num +'_w'+ size.width +'_h'+ size.height +'.png';
	//		return (rootpath + root + dest +'/'+ fname);
		if (isEmpty(root) || isEmpty(dest)) return fname;
		return (root + dest +'/'+ fname);
	},
	
	dlg:function(opt){
		if (!isValid(opt,'id')) console.error('imgMGRdlg:opt.id'); // target id
		if (!isValid(opt,'remote_id')) console.error('imgMGRdlg:opt.remote_id'); // remote target id
		if (!isValid(opt,'root_path')) console.error('imgMGRdlg:opt.root_path'); // root_path
		if (!isValid(opt,'dest')) console.error('imgMGRdlg:opt.dest'); // destination folder
		if (!isValid(opt,'viewport')) console.error('imgMGRdlg:opt.viewport'); // passed to pluging
		if (!isValid(opt,'boundary')) console.error('imgMGRdlg:opt.boundary'); // passed to pluging
		
		if (!isValid(opt,'imgid')) opt.imgid = 0; // id of the destination type
		if (!isValid(opt,'imgnum')) opt.imgnum = ''; // number of the image sequences for imgid
		
		console.error('imgMGRapi.dlg', opt);
	
		let title = 'Upload Image';
		let footer = fwHTML.elem({tag:'button',attr:{'type':'button','class':'btn btn-primary','data-dismiss':'modal'}},'Done');
		footer += fwHTML.elem({tag:'button',attr:{'type':'button','class':'btn btn-secondary','data-dismiss':'modal'}},'X');
	
		let html = fwHTML.elem({tag:'div',attr:{'id':opt.id}});
	
		function myCROPPIE(opt){
			let dlgimg = imgMGR('#'+ opt.id, {
				mouseWheelZoom: false,
				enableOrientation: true,
				viewport: opt.viewport,
				boundary: opt.boundary,
				orientation: 1
			});
			
			function bindImg(opt, callback){
// img id + num
				let fname = imgMGRapi.bldpath(opt.root_path, opt.dest, opt.imgid, opt.imgnum, opt.viewport);
				imgMGRapi.loadimg('#'+ opt.remote_id, fname, true, function(nocacheName){
					dlgimg.croppie('bind', {
						url: nocacheName,
						zoom: 0
					}).then(function(param){
						if (typeof callback === 'function') callback(param);
					});
				});
			}
	
			bindImg(opt);
	
			$('#imgMGRupload').off('change').on('change', function(event){
				dlgimg.readFile(this);
			});
			
			$('#imgMGRrotate').off('click').on('click', function(event){
				dlgimg.rotate();
			});
			
			$('#imgMGRsave').off('click').on('click', function(event){
				let fname = imgMGRapi.bldpath('', opt.dest, opt.imgid, opt.imgnum, opt.viewport );
				dlgimg.upload(opt.dest, fname, function(result){
					bindImg(opt, function(){
						fwHTML.msg('success','Image','Updated image.',15);
						dlgimg.disable();
						$(window).trigger('imgMGRapi', ['update', opt]);
					});
				});
			});
		}
		
		fwAPP.api.myMODAL(fwHTML.title(title), html, footer, function(){
			myCROPPIE(opt);
			fwAPP.api.tooltipENABLE();
		},function(event){
			if (fwAPP.api.eventis(event, 'btn-primary')){
				if (!$('#imgMGRsave').hasClass('disabled')){
					$('#imgMGRsave').click();
					setTimeout(function(){fwAPP.mymodal.hide();}, 1000); // need to wait to image save
					return;
				}
			}
			fwAPP.mymodal.hide();
		});
	}
};