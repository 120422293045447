const fwHTML = {
	fwapi:function(opt){
		if (isEmpty(opt)) opt = {key:'frame'};
		if (isEmpty(opt.key)) opt.key = 'frame';
		return $('#fwapi-'+ opt.key).attr('fwapi');
	},
	
	title:function(name){ 
		return fwHTML.elem({tag:'div', attr:{'class':'fw-bold fst-italic'}}, name + ' ...');
	},
	
	subtitle:function(name, icon){ 
		icon = isEmpty(icon) ? '' : icon;
		return fwHTML.elem({tag:'span', attr:{class:'badge text-bg-dark-gz text-start text-truncate w-100'}}, icon + name.toUpperCase());
	},
	
	plural:function(name, cnt, str){
		if (isEmpty(str)) str = 's';
		cnt = parseInt(String(cnt));
		return (cnt>1) ? (name + str) : name;
	},
	
	elem:function(opt, content){
		let html = '<'+ opt.tag;
		const newline = (isValid(opt, 'newline')) ? "\n" : '';
		
		if (isEmpty(content)) content = '';
		
		$.each(opt.attr, function(i, obj){
			const k = (typeof obj === 'object') ? Object.keys(obj)[0] : i;
			const v = (typeof obj === 'object') ? obj[k] : obj;
			html += ' '+ k +'="'+ v +'"';
		});

		let closingTAG = '</'+ opt.tag +'>';
		switch(String(opt.tag).toLowerCase()){
			case 'area':
			case 'base':
			case 'br':
			case 'col':
			case 'command':
			case 'embed':
			case 'hr':
			case 'input':
			case 'keygen':
			case 'link':
			case 'meta':
			case 'param':
			case 'source':
			case 'track':
			case 'wbr':
				closingTAG = '';
				break;
		}
		return html +'>'+ content + closingTAG + newline;
	},
	
	msg:function(type, title, text, timeout){
		let uuid = type + (new Date()).getTime();
		switch(type){
			case 'primary':
			case 'secondary':
			case 'light':
			case 'dark':
			case 'success': title = fwHTML.icon('square-check') + title;
				break;
				
			default:
			case 'info':
			case 'warning':
			case 'danger': title = fwHTML.icon('circle-exclamation') + title;
				break;
		}
		
		let html = fwHTML.elem({tag:'b',attr:{class:'text-capitalize text-truncate'}}, title);
		html += ' / '+ fwHTML.elem({tag:'i',attr:{class:'text-truncate'}}, text);
		html = fwHTML.elem({tag:'div',attr:{class:'text-start'}}, html);
		
		html = fwHTML.alert(type, html, uuid);	

		$( ($('#fwapi-dlgmsg').is(':visible') ? '#fwapi-dlgmsg' : '#fwapi-sysmsg') ).append(html);
		
		fwHTML.alertTimeout(uuid, timeout);	
	},
	
	alertTimeout:function(uuid, timeout){
		let sec = parseFloat(timeout); // timeout in seconds
		if (parseInt(sec)>0){
			setTimeout(function() {
				$('#'+ uuid).alert('close');
				$("#page_root").click();
			}, sec * 1000);
		}
	},
	
	alert:function(type, html, uuid){
		function stringToHash(string){
			let hash = 0;
			if (string.length == 0) return hash;
			for (i = 0; i < string.length; i++){
				char = string.charCodeAt(i);
				hash = ((hash << 5) - hash) + char;
				hash = hash & hash;
			}
			return Math.abs(hash);
		}
		
		let attr = {'class':'alert alert-'+ type +' alert-dismissible fade show m-0 p-1', 'role':'alert'};
		attr.idhash = stringToHash(type+html);
		let active = false;
		$('.alert').each(function(){
			if (attr.idhash == $(this).attr('idhash')) active = true; 
		});
		if (active){
			console.warn('alert:active', attr.idhash);
			return '';
		}
		
		if (!isEmpty(uuid)) attr.id = uuid;
		html += fwHTML.elem({tag:'a', attr:{'class':'btn btn-sm btn-close m-0 p-2', 'data-bs-dismiss':'alert', 'aria-label':'close'}});
		return fwHTML.elem({tag:'div', attr:attr}, html);
	},

	input:function(id, type, name, value, readonly){
		if (isEmpty(value)) value = '';
		let attr = {id:id, type:type, value:value, class:'form-control'};
		if (!isEmpty(readonly)) attr.readonly = 'readonly';
		
		let html = fwHTML.elem({tag:'span', attr:{id:id +'-label', class:'input-group-text fst-italic w-25'}}, name);

		switch(type){
			case 'select':
				attr = {id:id, name:id, class:'form-control'};
				if (!isEmpty(readonly) && readonly) attr.disabled = 'disabled';
				html += fwHTML.elem({tag:'select', attr:attr}, value);
				break;
				
			case 'phone':
			case 'tel':
				attr.placeholder = '(999)999-9999';
				html += fwHTML.elem({tag:'input', attr:attr});
				break;
				
			default:
			case 'text':
				html += fwHTML.elem({tag:'input', attr:attr});
				break;
		}
		html = fwHTML.elem({tag:'div', attr:{class:'input-group input-group-sm m-0 p-0'}}, html);
		return html;
	},
	
	input_floating:function(opt){
		if (isEmpty(opt.name)) return console.error('input_floating', 'name', opt);
		if (isEmpty(opt.id)) return console.error('input_floating', 'id', opt);
		if (isEmpty(opt.type)) opt.type = 'text';
		let attr = {id:opt.id, type:opt.type, name:opt.name, class:'form-control', placeholder:opt.name};
		if (!isEmpty(opt.autocomplete)) attr.autocomplete = 'new-password';
		if (!isEmpty(opt.validation)) attr.required = 'required';
		if (!isEmpty(opt.disabled)) attr.disabled = 'disabled';
		attr.value = isEmpty(opt.value) ? '' : opt.value;
		
		let html = fwHTML.elem({tag:'input', attr:attr});
		html += fwHTML.elem({tag:'label', attr:{for:opt.id}}, opt.name);
		if (!isEmpty(opt.validation)) html += fwHTML.elem({tag:'div', attr:{class:'invalid-feedback'}}, opt.validation);
		html = fwHTML.elem({tag:'div', attr:{class:'form-floating'}}, html);

		if (!isEmpty(opt.validation)) html = fwHTML.elem({tag:'div', attr:{class:'input-group has-validation'}}, html);
		
		return html;
	},
	
	input_textarea:function(opt){
		let html = fwHTML.elem({tag:'textarea',attr:{id:opt.id,'class':'form-control'}}, opt.value);
		html += fwHTML.elem({tag:'label',attr:{for:opt.id}}, opt.label);
		html = fwHTML.elem({tag:'div',attr:{class:'form-floating'}}, html);
		html = fwHTML.elem({tag:'span',attr:{class:'input-group-text w-25'}}, opt.name) + html;
		return fwHTML.elem({tag:'div',attr:{class:'input-group input-group-sm'}}, html);
	},
	
	row:function(data,callback){
		if (isEmpty(data) || !Array.isArray(data)) return '';
		
		let html = '';
		$.each(data,function(k, v){
			let myclass = (typeof callback === 'function') ? callback(k, v) : 'col fit-content';
			html += fwHTML.elem({tag:'div',attr:{class:myclass}}, v);
		});
		return fwHTML.elem({tag:'div',attr:{class:'row'}}, html);
	},
	
	row_kv:function(opt){
		if (isEmpty(opt)) opt = {};
		let k_attr = {class:'col-sm-2 text-start text-sm-end'};
		let v_attr = {class:'col-sm-10 text-start'};
		let r_attr = {class:'row m-0 p-0'};
		if (isEmpty(opt.key)) opt.key = '';
		if (isEmpty(opt.value)) opt.value = '';
		if (!isEmpty(opt.id)) v_attr.id = opt.id;
		
		if (!isEmpty(opt.hide)){
			k_attr.class += ' d-none d-sm-block';
			v_attr.class += ' d-none d-sm-block';
		} else if (!isEmpty(opt.hide_key)){
			k_attr.class += ' d-none d-sm-block';
		}
		
		if (!isEmpty(opt.beautify)){
			k_attr.class += ' text-truncate fst-italic fw-bold text-bg-light';
			v_attr.class += (isEmpty(opt.pre)) ? ' text-wrap' : ' pre-wrap';
			r_attr.class += ' fs-6 py-1 border-bottom';
		}
		
		let html = fwHTML.elem({tag:'div',attr:k_attr},opt.key);
		html += fwHTML.elem({tag:'div',attr:v_attr}, opt.value);
			
		return fwHTML.elem({tag:'div',attr:r_attr},html);
	},
	
	icon:function(opt){
		if (typeof opt !== 'object') opt = {name:opt,type:'solid'};
		if (isEmpty(opt.type)) opt.type = 'solid'; 
		let myclass = 'fa-'+ opt.type +' fa-'+ opt.name; 
		if (!isEmpty(opt.bg)) myclass += ' bg-'+ opt.bg;
		if (!isEmpty(opt.text)) myclass += ' text-'+ opt.text;
		if (!isEmpty(opt.size)) myclass += ' fa-'+ opt.size;
		if (!isEmpty(opt.myclass)) myclass += ' '+ opt.myclass; // catch all
		
		let pos = ' m-0';
		switch(opt.pos){
			case 'x': pos += ' mx-1'; break;
			case 'y': pos += ' my-1'; break;
			case 'c': case 'center': break;
			case 's': case 'start': pos += ' ms-1'; break;
			case 't': case 'top': pos += ' mt-1'; break;
			case 'b': case 'bottom': pos += ' mb-1'; break;
			default: case 'e': case 'end': pos += ' me-1'; break;
		}
		return fwHTML.elem({tag:'i',attr:{'class':(myclass + pos)}});
	},
	
	button:function(opt){
		if (isEmpty(opt)) return '';
		
		let myclass = 'btn btn-sm p-0 px-1 ' + (isEmpty(opt.text) ? 'btn-secondary' : 'btn-'+ opt.text);
		myclass += isEmpty(opt.myclass) ? ' rounded-top' : ' '+ opt.myclass;
		let icon = fwHTML.icon({name:isEmpty(opt.icon)?'cirle':opt.icon,text:'light'});
		
		return fwHTML.elem({tag:'button',attr:{'type':'button','class':myclass,'onclick':opt.onclick}},icon + opt.name);
	},
	
	checkboxSW:function(opt){
		if (isEmpty(opt)) return '';
		if (isEmpty(opt.ontext)) opt.ontext = opt.text;
		let html = fwHTML.elem({tag:'input', attr:{id:opt.id, 'class':'form-check-input', type:'checkbox'}});
		html += fwHTML.elem({tag:'label', attr:{'class':'form-check-label text-bg-secondary px-2', 'for':opt.id, 'ontext':opt.ontext}}, opt.text);
		html = fwHTML.elem({tag:'div', attr:{'class':'form-check form-switch text-start'}}, html);
		return fwHTML.elem({tag:'div', attr:{id:opt.id +'-wrap', 'class':'text-start'}}, html);
	},
	
	checkboxSWevent:function(id, callback){
		function label(id, html){
			$('#'+ id +'-wrap').find('label').html(html);
		}
		
		let text = $("label[for='"+ id +"']").html();
		let ontext = $("label[for='"+ id +"']").attr('ontext');
		$('#'+ id).off('click').on('click', function(event){
			if ($(this).is(':checked')){
				$("label[for='"+ id +"']").removeClass('text-bg-secondary').addClass('text-bg-primary');
				label(id, ontext);
				if (typeof callback === 'function') callback({id:id, action:'on', text:text, ontext:ontext});
			} else {
				$("label[for='"+ id +"']").removeClass('text-bg-primary').addClass('text-bg-secondary');
				label(id, text);
				if (typeof callback === 'function') callback({id:id, action:'off', text:text, ontext:ontext});
			}
		});
		
		this.on = function(state){
			if ((state && !$('#'+ id).is(':checked')) || (!state && $('#'+ id).is(':checked'))) $('#'+ id).click();
		};
		return this;
	},
	
	flipcard:function(opt){
		if (isEmpty(opt)) return '';
		if (!isValid(opt,'fhtml')) console.error('flipcard:fhtml');
		
		let attr = {class:'flipcard card text-start border-0 m-0 p-0'};
		if (isValid(opt, 'id')) attr.id = opt.id;
		if (isValid(opt, 'width')) attr.style = 'width:'+ opt.width +'px;';
		
		let html = fwHTML.elem({tag:'div', attr:{'class':'front'}}, opt.fhtml);
		if (isValid(opt,'bhtml')) html += fwHTML.elem({tag:'div', attr:{'class':'back'}}, opt.bhtml);
		return fwHTML.cardwrap(fwHTML.elem({tag:'div', attr:attr}, html));
	},
	
	cardwrap:function(html){
		return fwHTML.elem({tag:'div', attr:{class:'card-text border-0 m-0 p-2'}}, html);
	},
	
	card_ul:function(list){
		if (isEmpty(list)) return '';
		let html = '';
		$.each(list, function(k, v){
			html += fwHTML.elem({tag:'li', attr:{'class':'list-group-item text-end pb-0'}}, v.name);
		});
		return fwHTML.elem({tag:'ul', attr:{'class':'list-group list-group-flush'}}, html);
	},

	card:function(opt){
		if (isEmpty(opt)) return '';
		let html = '';
		if (!isEmpty(opt.title)) html += fwHTML.elem({tag:'div', attr:{'class':'card-title fw-bold fst-italic small'}}, opt.title);
		if (!isEmpty(opt.text)) html += fwHTML.elem({tag:'div', attr:{'class':'card-text small pre-wrap'}}, opt.text);
		if (!isEmpty(opt.html)) html += opt.html;
		html = fwHTML.elem({tag:'div', attr:{class:'card-body m-0 p-0', style:'min-height:120px'}}, html);
		if (!isEmpty(opt.cardwrap) && opt.cardwrap) html = fwHTML.cardwrap(html);
		
		if (!isEmpty(opt.src)) html = fwHTML.elem({tag:'img', attr:{class:'card-img-top', height:'100', src:opt.src}}) + html;
		
		let attr = {class:'card-img-overlay h-25'};
		if (!isEmpty(opt.onclick)) attr.onclick = opt.onclick;
		
		if (!isEmpty(opt.imgoverlay)) html += fwHTML.elem({tag:'div', attr:attr}, opt.imgoverlay);

		if (!isEmpty(opt.list)) html += fwHTML.card_ul(opt.list);
		
		let myclass = isEmpty(opt.priv) ? 'card text-start mb-2' : 'card text-start border-0';

		return fwHTML.elem({tag:'div', attr:{class:myclass}}, html);
	},
	
	masonry:function(opt){
		if (typeof opt === 'string') opt = {html:opt};
		return fwHTML.elem({tag:'div', attr:{class:'grid-item'}}, opt.html);
	},
	
	figure:function(opt){
		let html = fwHTML.elem({tag:'blockquote', attr:{class:'blockquote fs-6 fw-medium fst-italic'}}, opt.text);
		html += fwHTML.elem({tag:'figcaption', attr:{class:'blockquote-footer pt-1 mb-2 text-wrap text-capitalize'}}, opt.author);
		return fwHTML.elem({tag:'figure', attr:{class:'border-top border-bottom d-none d-md-block text-end bg-light px-2'}}, html);
	},
	
	dropdown_li:function(icon, html){
		return fwHTML.icon(icon) + fwHTML.elem({tag:'b'}, html);
	},

	dropdown_menu_li:function(data){
		let html = '';
		$.each(data, function(i, o){
			let onclick = isEmpty(o.onclick) ? 'function(){}' : o.onclick;
			let icon_opt = {name:(isEmpty(o.icon)?'cirle':o.icon), text:'secondary'};
			if (!isEmpty(o.bg)) icon_opt.bg = o.bg; 
			if (!isEmpty(o.text)) icon_opt.text = o.text; 
			if (o.type == 'dropdown-submenu'){
				html += fwHTML.dropdown_submenu(o.data, o);
			} else {
				let attr = {'class':'dropdown-item', 'onclick':onclick};
				if (!isEmpty(o.href)) attr.href = o.href;
				if (!isEmpty(o.id)) attr.id = o.id;
				html += (o.type == 'dropdown-divider') ? fwHTML.elem({tag:'li'}, fwHTML.elem({tag:'hr', attr:{'class':'dropdown-divider'}})) 
				: fwHTML.elem({tag:'li'}, fwHTML.elem({tag:'a', attr:attr}, fwHTML.dropdown_li(icon_opt, o.name) ));
			}
		});
		return html;
	},
	
	dropdown_menu:function(data, opt){
		if (isEmpty(opt)) opt = {};
		if (isEmpty(data)) return '';
		
		let html = fwHTML.dropdown_menu_li(data);
		let myclass = 'dropdown-menu rounded-1' + (isEmpty(opt.class) ? '' : ' '+ opt.class);
		return fwHTML.elem({tag:'ul', attr:{'class':myclass}}, html);
	},
/*
        <li>
            <div class="btn-group dropend">
                <a type="button" class="dropdown-item dropdown-toggle p-0" data-bs-toggle="dropdown" aria-expanded="false">
                    CCC
                </a>
                <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="#">DDD</a></li>
                    <li><a class="dropdown-item" href="#">EEE</a></li>
                </ul>
            </div>
        </li>
*/	
	dropdown_submenu:function(data, opt){
		if (isEmpty(opt)) opt = {};
		let html = fwHTML.dropdown_menu(data, opt);

		let myclass = 'dropdown-item dropdown-toggle p-0 px-2 fw-bold ' + (isEmpty(opt.bg) ? 'btn-primary' : 'btn-'+ opt.bg);
		myclass += ' '+ (isEmpty(opt.class) ? '' : opt.class);
		
		html = fwHTML.elem({tag:'a', attr:{'class':myclass, 'type':'button', 'data-bs-toggle':'dropdown', 'aria-expanded':'false', 'data-bs-auto-close':'outside' }}, opt.name) + html;
		html = fwHTML.elem({tag:'div', attr:{class:'btn-group dropstart'}}, html);
		return fwHTML.elem({tag:'li', attr:{class:'dropdown'}}, html);
	},
	
	dropdown:function(data, opt){
		if (isEmpty(opt)) opt = {};
		if (isEmpty(data)) return '';
		
		let o = {}; // there is only one action available
		if (!Array.isArray(data)) o = data;
		else if (data.length == 1) o = data[0];
		
		if (!isEmpty(o)){
			if (opt.prop == 'attached') o.myclass = 'rounded-end';
			return fwHTML.button(o); // there is only one action available
		}
		
		let html = fwHTML.dropdown_menu(data, opt);

		let myclass = 'btn dropdown-toggle p-1 px-2 fw-bold ' + (isEmpty(opt.bg) ? 'btn-primary' : 'btn-'+ opt.bg);
		myclass += ' '+ (isEmpty(opt.myclass) ? '' : opt.myclass);
		
		html = fwHTML.elem({tag:'button',attr:{'class':myclass,'type':'button','data-bs-toggle':'dropdown','aria-expanded':'false'}}, opt.name) + html;
		return fwHTML.elem({tag:'div',attr:{class:'dropdown'}}, html);
	},
	
	spinner:function(){
		return fwHTML.elem({tag:'div',attr:{class:'spinner-border spinner-border-sm rounded-pill', role:'status'}}, fwHTML.elem({tag:'span',attr:{class:'visually-hidden'}}));
	},
	
	mymodal:function(opt){
		let elem = {tag:'button', attr:{'class':'btn-close','data-bs-dismiss':'modal','aria-label':'close'}};
		let html = fwHTML.elem({tag:'div', attr:{'class':'modal-header border-0 py-1'}}, fwHTML.elem({tag:'h6',attr:{'class':'modal-title'}}) + fwHTML.elem(elem));

		html += fwHTML.elem({tag:'div', attr:{'class':'modal-body pt-1'}});
		html += fwHTML.elem({tag:'div', attr:{'class':'modal-footer border-0 py-1'}});		
		html = fwHTML.elem({tag:'div', attr:{'class':'modal-content rounded-1'}}, html);		
		html = fwHTML.elem({tag:'div', attr:{'class':'modal-dialog'}}, html);		
		return fwHTML.elem({tag:'div', attr:{'id':'mymodal', 'class':'modal fade', 'tabindex':'-1'}}, html);		
	},
	
	validate:function(e, callback){
		e.preventDefault();
		const forms = document.querySelectorAll('.needs-validation');
		
		let validate = 0;
		Array.from(forms).forEach(function(form){
			if (!form.checkValidity()) {
				e.stopPropagation();
				validate ++;
			}
	
			form.classList.add('was-validated');
		});
		console.log('validate', validate);
		if (!validate && typeof callback === 'function') callback(); // everything is validated, process
	},
	
	imagesizer:function(type, imgid){
		if (isEmpty(type)) return console.error('imagesizer:type missing');
		
		let imgsize = [
			{id:'250.250', name:'250 X 250'},
			{id:'300.200', name:'300 X 200'},
			{id:'300.100', name:'300 X 100'},
			{id:'75.300', name:'75 X 300'}
		];
		
		let sizer_id = (!isEmpty(imgid)) ? 'imgsize_'+ imgid : 'imgsize';
		let target_id = (!isEmpty(imgid) && imgid>0) ? 'target_image_'+ imgid : 'target_image';

		let html = fwHTML.elem({tag:'select', attr:{id:sizer_id, 'class':'form-select bg-secondary-subtle form-select-sm'}}, setSELECT(imgsize));
		let img = fwHTML.elem({tag:'img', attr:{id:target_id, class:'rounded-1 bg-white border p-1', width:'50', height:'50'}});
		img = fwHTML.elem({tag:'div', attr:{class:'col m-0 p-0'}}, img);
		html += fwHTML.elem({tag:'div', attr:{class:'row align-items-center justify-content-center imagesizer-gz', onclick:"fwAPP.usr.getImage('"+ type +"')"}}, img);
		html = fwHTML.elem({tag:'div', attr:{class:'col text-center bg-light p-2'}}, html);
		html = fwHTML.elem({tag:'div', attr:{class:'input-group bg-white'}}, html);
		return fwHTML.elem({tag:'div', attr:{class:'bg-white border p-3'}}, html);
	},
	
	carousel:function(id, item, opt){
//		if (isEmpty(opt)) opt = {height:250};
		let html = '';
		console.error('carousel', id, item, opt);
		
		function btn(type,text){
			let b_html = fwHTML.elem({tag:'span', attr:{class:'carousel-control-'+ type +'-icon', 'aria-hidden':'true'}});
			b_html += fwHTML.elem({tag:'span', attr:{class:'visually-hidden', 'aria-hidden':'true'}}, text);
			return fwHTML.elem({tag:'button', attr:{class:'carousel-control-'+ type, type:'button', 'data-bs-target':'#'+ id, 'data-bs-slide':type}}, b_html);
		}

		let indicators = '';
		let inner = '';
		$.each(item,function(k, v){
			let attr = {type:'button', 'data-bs-target':'#'+ id, 'data-bs-slide-to':k};
			if (isEmpty(indicators)){
				attr['class'] = 'active';
				attr['aria-current'] = 'true';
			}
			indicators += fwHTML.elem({tag:'button', attr:attr});

			let imgclass = 'mx-auto d-block';
			if (isValid(opt,'fitimg')) imgclass += ' w-100';
			
			let imgattr = {class:imgclass, src:v};
			if (isValid(opt,'width')) imgattr.width = opt.width;
			if (isValid(opt,'height')) imgattr.height = opt.height;
			let img = fwHTML.elem({tag:'img', attr:imgattr});
			
			attr = isEmpty(inner) ? {class:'carousel-item active'} : {class:'carousel-item'};
			let caption = isValid(opt,'caption') ? fwHTML.elem({tag:'div', attr:{class:'carousel-caption'}}, opt.caption) : ''; 
			inner += fwHTML.elem({tag:'div', attr:attr}, img + caption);
		});
		
		html += fwHTML.elem({tag:'div',attr:{class:'carousel-indicators'}}, indicators);
		html += fwHTML.elem({tag:'div',attr:{class:'carousel-inner'}}, inner);
		
		html += btn('prev','Previous');
		html += btn('next','Next');
		return fwHTML.elem({tag:'div',attr:{id:id, class:'carousel carousel-fade text-bg-dark'}}, html);
	},
	
	accordion_ctnr:function(name, subname, lst, callback_hdr){ // container to build accordion in accordion
		return fwHTML.accordion({id:name}, lst, function(k, p, a){ // header
			return callback_hdr(p);
		}, function(k, p, a){ // body
			return fwHTML.elem({tag:'div', attr:{id:subname+p.id, class:'container-fluid m-0 px-3 py-1'}});
		});
	},
	
	accordion_ctnr_write:function(name, lst, callback){ // inject html into container
		if (typeof callback !== 'function') return console.log('missing callback');
		$.each(lst, function(k, v){
			$(name+v.id).html(callback(k, v));
		});
	},
	
	accordion:function(attr, item, callback_hdr, callback_body){
		let html = '';
		if (isEmpty(item)) return html;
		if (isEmpty(attr)) attr = {};
		if (isEmpty(attr.id)) attr.id = 'myaccordion';
		if (isEmpty(attr.class)) attr.class = 'accordion accordion-flush'; // accordion-flush
		else if (String(attr.class).indexOf('accordion')<0) attr.class = 'accordion '+ String(attr.class); // make sure this class is here
		String(attr.class).trim();

		$.each(item, function(k, v){
			let i_html = '', ch_html = '', cb_html = '';
			let uniqid = isEmpty(v.id) ? k : v.id;
			let myid_h = 'heading-'+ attr.id +'-'+ uniqid;
			let myid_c = 'collapse-'+ attr.id +'-'+ uniqid;
//			let myid_h = 'heading'+ k + attr.id;
//			let myid_c = 'collapse'+  k + attr.id;
			
			if (typeof callback_hdr === 'function')	ch_html = callback_hdr(k, v, attr);
			if (typeof callback_body === 'function') cb_html = callback_body(k, v, attr);
			
			if (!isEmpty(ch_html)){
				let myclass = 'accordion-button p-0 px-3 collapsed' + (isEmpty(cb_html) ? ' remove-accordion-button' : '');
				let opt = {class:myclass, type:'button', 'data-bs-toggle':'collapse', 'data-bs-target':'#'+ myid_c, 'aria-expanded':'false', 'aria-controls':myid_c};
				ch_html = fwHTML.elem({tag:'button', attr:opt}, ch_html);
				i_html += fwHTML.elem({tag:'div', attr:{id:myid_h, class:'accordion-header'}}, ch_html);
			}
			
			if (!isEmpty(cb_html)){
				cb_html = fwHTML.elem({tag:'div' ,attr:{class:'accordion-body'}}, cb_html);
				i_html += fwHTML.elem({tag:'div', attr:{id:myid_c, class:'accordion-collapse collapse', 'aria-labelledby':myid_h, 'data-bs-parent':'#'+attr.id}}, cb_html);
			}
				
			if (!isEmpty(i_html)) html += fwHTML.elem({tag:'div', attr:{id:attr.id +'-'+ uniqid, class:'accordion-item'}}, i_html);
		});
		
		return isEmpty(html) ? html : fwHTML.elem({tag:'div', attr:attr}, html);
	}
};