let fwLogger = function(){
	var level = 1;
	const pub = {};
	const luse = {ALL:1, TRACE:1, DEBUG:2, INFO:3, TIME:4, WARN:5, ERROR:8, OFF:99};
	
	pub.setLevel = function(l){
		$.each(luse, function(k, v){
			if (k == l) return !(level = v);
		});
	};
	
	Object.defineProperty(pub, 'log', {get:function(){
			return level<=2 ? console.log.bind(window.console, 'D:') : function(){};
		}
	});
	
	Object.defineProperty(pub, 'info', {get:function(){
			return level<=3 ? console.info.bind(window.console, 'I:') : function(){};
		}
	});
	
	Object.defineProperty(pub, 'warn', {get:function(){
			return level<=5 ? console.warn.bind(window.console, 'W:') : function(){};
		}
	});
	
	Object.defineProperty(pub, 'error', {get:function(){
			return level<=8 ? console.error.bind(window.console, 'E:') : function(){};
		}
	});
	return pub;
}();

const fwAPP = {
	init:function(callback){
		if (typeof fwAPI === 'function') fwAPP.api = new fwAPI();
		if (typeof htmlAPI === 'function') fwAPP.html = new htmlAPI();
		if (typeof authAPI === 'function') fwAPP.auth = new authAPI();
		if (typeof cartAPI === 'function') fwAPP.cart = new cartAPI();
		if (typeof signinAPI === 'function') fwAPP.signin = new signinAPI();
		return fwAPP.api.init(callback);
	}
};

$(document).ready(function(){
	if (isEmpty(window.jQuery)) return console.error("jQuery is not loaded");
	fwLogger.setLevel('INFO');
	fwLogger.info('document.ready:'+ rtdt.sdiff());
});